import React, { useEffect, useState } from 'react';
import API from '../api';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../utils';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const ServiceGroupForm = (props) => {
  //   console.log('authform props', props);
  const { serviceGroupData, onChangeServiceGroup } = props;

  let headerText = '서비스 그룹 설정';

  const handleChange = (event, index) => {
    onChangeServiceGroup(event, index);
  };

  return (
    <article className="service-group-form">
      <header>
        <h1>{headerText}</h1>
      </header>
      <section className="setting__container">
        {/* <header>서비스 그룹 선택</header> */}
        <ul className="service-group-list">
          {serviceGroupData &&
            serviceGroupData.map((item, index) => {
              return (
                <li key={'service-group-list-item-' + index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={item.checked}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                        name={item.name}
                      />
                    }
                    label={item.name}
                  />
                </li>
              );
            })}
        </ul>
      </section>
    </article>
  );
};
