import React, { useEffect, useState } from 'react';
import API from '../../api';
import { Form, Error, Loading } from '../../components';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../../utils';

export const KasDetailPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState(null);
  const accountId = params.accountId;

  useEffect(() => {
    API.KAS.GetDetail(accountId).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        setData(result.value);
      },
      (err) => {
        console.log('err', err);
        // setError(true);
      },
    );
  }, []);

  const onChange = (name, value) => {
    let newData = _.cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const requestSave = () => {
    let body = {
      name: data.name,
    };
    console.log('requestSave body', body);
    API.KAS.Put(accountId, body).then(
      (res) => {
        console.log('res', res);
        // setAlert({ open: true, type: 'success', message: '저장 성공' });
        const result = API.Utils.ResultCheck(res);
        if (result) {
          enqueueSnackbar('저장 성공', { variant: 'success' });
        }
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
        // setAlert({ open: true, type: 'error', message: '저장 실패' });
      },
    );
  };

  const requestDelete = () => {
    // console.log('requestDelete');
    // setDialog({
    //   open: true,
    // });
  };

  const alertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    // setAlert({ ...alert, open: false });
  };

  const dialogClose = (e) => {
    // setDialog({
    //   open: false,
    // });
  };

  const dialogCloseAgree = (e) => {
    // console.log('dialogCloseAgree');
    // API.KAS.Delete(value.valueId).then(
    //   (res) => {
    //     const result = API.Utils.ResultCheck(res);
    //     console.log('result', result);
    //     history.push('/kas/list');
    //   },
    //   (err) => {
    //     console.log('err', err);
    //     setAlert({ open: true, type: 'error', message: '삭제 실패' });
    //     setDialog({
    //       open: false,
    //     });
    //   },
    // );
  };

  if (!data) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }
  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>Klaytn 계정 상세</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            {/* <li onClick={requestDelete}>
              <Button variant="contained" color="secondary">
                삭제
              </Button>
            </li> */}
            <li>
              <Button
                onClick={requestSave}
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                variant="contained"
                color="primary"
              >
                저장
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <Form title="기본 정보" data={data} type="detail" contentType="kas" onChange={onChange}></Form>
    </article>
  );
};
