import React, { useEffect, useState, useContext } from "react";
import queryString from "query-string";
import API from "../../api";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlaytnHash,
  ErrorLog,
  KlipAddress,
} from "../../components";
import { useFilter, usePagination } from "../../hooks";
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from "../../utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { ErrorUtil } from "../../utils";
import { AuthContext, MemberGroupContext } from "../../contexts";
import moment from "moment-timezone";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";

const buildDataToTable = (data) => {
  console.log("buildDataToTable data", data);
  let reunderData = [];
  for (let i in data) {
    let target = data[i];
    let type = target.type;
    let data_object = {
      reg_dt: target.reg_dt,
      svc_num: target.svc_num,
      svc_name: target.name,
      deposit_klay: null,
      deposit_closing_price: null,
      deposit_won_price: null,
      deposit_mbr_id: null,
      deposit_mall_type: null,
      withdrawal_klay: null,
      withdrawal_address: null,
      withdrawal_mbr_id: null,
      withdrawal_balance: null,
      status: Number(target.cancel) === 1 ? "취소" : "승인",
      memo: target.memo,
    };
    if (type === "pay") {
      data_object.deposit_klay = target.amount;
      data_object.deposit_closing_price = target.closing_price;
      data_object.deposit_won_price = target.won_price;
      data_object.deposit_mbr_id = target.mbr_id;
      data_object.deposit_mall_type = target.mall_type;
      reunderData.push(data_object);
    } else if (type === "rwd") {
      data_object.withdrawal_klay = target.amount;
      data_object.withdrawal_address = target.klip_address;
      data_object.withdrawal_mbr_id = target.rq_bulk_seq ? "" : target.mbr_id;
      data_object.withdrawal_balance = target.balance;
      reunderData.push(data_object);
    }
  }
  return reunderData;
};

export const AccountManagementPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { serviceGroupList, serviceList } = useContext(AuthContext);
  const [transferList, setTransferList] = useState(null);
  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(
    1,
    0
  );
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [listLoading, setListLoading] = useState(false);
  const [summary, setSummary] = useState(null);

  const [filter, onChangeFilter, setFilter] = useFilter([]);
  const { memberGroup } = useContext(MemberGroupContext);
  const [lastFilter, setLastFilter] = useState(null);
  const [modal, setModal] = useState({
    open: false,
  });
  const [isRequested, setIsRequested] = useState(false);
  const [initRequest, setInitRequest] = useState(false);

  useEffect(() => {
    let now = moment(new Date()).format("YYYY-MM-DDTHH:mm");
    let firstDay = new Date();
    firstDay.setDate(1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);
    firstDay.setSeconds(0);
    firstDay = moment(firstDay).format("YYYY-MM-DDTHH:mm");
    let date = new Date();
    var lastDay = new Date(date.setDate(date.getDate())).setHours(0, 0, 0, 0);
    lastDay = moment(lastDay).format("YYYY-MM-DDTHH:mm");
    let userFilter = [
      {
        required: true,
        id: "service-group-id",
        component: "Select",
        value:
          serviceGroupList && serviceGroupList.length > 0
            ? serviceGroupList[0].value
            : "",
        menus: [],
        label: "서비스 그룹 아이디",
        key: "serviceGroupId",
      },
      {
        id: "start-date-time",
        component: "TextField",
        type: "datetime-local",
        value: firstDay,
        label: "시작일",
        inputProps: {},
        InputLabelProps: {
          shrink: true,
        },
        key: "startDate",
      },
      {
        id: "end-date-time",
        component: "TextField",
        type: "datetime-local",
        value: lastDay,
        label: "종료일",
        inputProps: { max: lastDay },
        InputLabelProps: {
          shrink: true,
        },
        key: "endDate",
      },
      {
        required: true,
        id: "deal-type",
        component: "Select",
        value: "1,0",
        menus: [
          {
            text: "전체",
            value: "1,0",
          },
          {
            text: "승인",
            value: "0",
          },
          {
            text: "취소",
            value: "1",
          },
        ],
        label: "거래상태",
        key: "cancel",
      },
      {
        id: "option-type-deal",
        component: "Select",
        value: "none",
        menus: [
          {
            text: "선택 안함",
            value: "none",
          },
          {
            text: "개별 몰",
            value: "option-deal-value-mall_type",
          },
          {
            text: "고객 ID",
            value: "option-deal-value-member_id",
          },
          {
            text: "고객명",
            value: "option-deal-value-member_name",
          },
          {
            text: "거래번호",
            value: "option-deal-value-mall_order_number",
          },
          {
            text: "클립지갑주소",
            value: "option-deal-value-klip_address",
          },
        ],
        label: "옵션",
        key: "deal_optionType",
      },
      {
        disabled: true,
        id: "option-deal-value-mall_type",
        component: "TextField",
        value: "",
        label: "개별몰",
        key: "optionValue",
        inputProps: {
          maxLength: 15,
        },
      },
      {
        disabled: true,
        id: "option-deal-value-member_id",
        component: "TextField",
        value: "",
        label: "멤버 아이디",
        key: "optionValue",
        inputProps: {
          maxLength: 128,
        },
      },
      {
        disabled: true,
        id: "option-deal-value-member_name",
        component: "TextField",
        value: "",
        label: "고객명",
        key: "optionValue",
        inputProps: {
          maxLength: 20,
        },
      },
      {
        disabled: true,
        id: "option-deal-value-mall_order_number",
        component: "TextField",
        value: "",
        label: "거래번호",
        key: "optionValue",
        inputProps: {
          maxLength: 30,
        },
      },
      {
        disabled: true,
        id: "option-deal-value-klip_address",
        component: "TextField",
        value: "",
        label: "클립지갑주소",
        key: "optionValue",
        inputProps: {
          maxLength: 45,
        },
      },
    ];
    setFilter(userFilter);
    setTransferList(null);
    setInitRequest(true);
  }, []);

  useEffect(() => {
    console.log("useEffect initRequest", initRequest);
    if (initRequest) {
      onSearch();
    }
  }, [initRequest]);

  useEffect(() => {
    if (transferList) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (transferList) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  const requestNewList = (pageOffset) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log("requestNewList filterParams", filterParams);

    let params = {
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };

    console.log("requestNewList params", params);
    setListLoading(true);
    API.Accounting.GetManagementList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Accounting GetManagementList", result);
        if (result) {
          setTransferList(buildDataToTable(result.list));
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
          setSummary(result.summary);
          setLastFilter({
            ...filterParams,
            totalCount: result.totalCount,
          });
        }
        setListLoading(false);
      },
      (err) => {
        setListLoading(false);
        console.log("err", err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
        setLastFilter(null);
      }
    );
  };

  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };
  const rawDataToExcelData = (list) => {
    console.log("rawDataToExcelData list", list);

    const rawToExcelObject = (data) => {
      return {
        거래일자: data.reg_dt,
        서비스번호: data.svc_num,
        서비스이름: data.name,
        "입금(Klay)":
          data.type === "pay" ? KlaytnUtil.pebToKlay(data.amount) : null,
        "입금(원화) 당시 시세": data.type === "pay" ? data.closing_price : null,
        "입금(원화) 당시 원화": data.type === "pay" ? data.won_price : null,
        "입금자 통합회원 ID": data.type === "pay" ? data.mbr_id : null,
        "입금 상점번호": data.type === "pay" ? data.mall_type : null,
        "출금(Klay)":
          data.type === "rwd" ? KlaytnUtil.pebToKlay(data.amount) : null,
        "출금 지갑": data.type === "rwd" ? data.klip_address : null,
        "출금 통합회원 ID": data.type === "rwd" ? data.mbr_id : null,
        "잔액 (회사계정)":
          data.type === "rwd" ? KlaytnUtil.pebToKlay(data.balance) : null,
        상태: data.cancel === "0" ? "승인" : "취소",
        비고: data.memo,
      };
    };
    let excelList = [];
    for (let i in list) {
      let target = list[i];
      excelList.push(rawToExcelObject(target));
    }
    return excelList;
  };

  const requestExcelDownload = () => {
    console.log("lastFitler", lastFilter);
    if (!lastFilter) {
      return;
    }
    API.Accounting.GetManagementList({
      ...lastFilter,
      pageOffset: 0,
      pageSize: lastFilter.totalCount,
    }).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Link All GetList", result);
        if (result) {
          const excelData = rawDataToExcelData(result.list);
          exportDataToXlsx(excelData);
        }
      },
      (err) => {
        console.log("err", err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
      }
    );
  };

  const exportDataToXlsx = (list) => {
    if (!list) {
      enqueueSnackbar("데이터가 없습니다.", { variant: "error" });
      return;
    }
    if (list.length === 0) {
      enqueueSnackbar("데이터가 없습니다.", { variant: "error" });
      return;
    }
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(list),
      "transfer-list"
    );
    XLSX.writeFile(wb, "shift_accounting_management.xlsx");
  };

  // if (typeLoading) {
  //   return (
  //     <article className="content-page">
  //       <Loading></Loading>
  //     </article>
  //   );
  // }
  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>정산 관리</h1>
          </div>
        </section>
        <ul className="option-list">
          <li>
            <Button
              disabled={!lastFilter}
              color="primary"
              variant="contained"
              onClick={requestExcelDownload}
            >
              <GetAppIcon style={{ marginRight: 5, fontSize: 18 }}></GetAppIcon>
              EXPORT EXCEL
            </Button>
          </li>
        </ul>
      </header>
      <section>
        <Filter
          data={filter}
          updateData={onChangeFilter}
          onSearch={onSearch}
          loading={listLoading}
        ></Filter>
      </section>
      {summary && (
        <section className="transfer-summary">
          <header>
            <h1>기간별 매출내역 합계</h1>
          </header>
          <ul>
            <li className="header">
              <div>원화(원)</div>
              <div>클레이(klay)</div>
            </li>
            <li>
              <div>
                {summary.won_amount
                  ? Number(summary.won_amount).toLocaleString("en")
                  : null}
              </div>
              <div>{KlaytnUtil.pebToKlay(summary.klay_amount)}</div>
            </li>
          </ul>
        </section>
      )}
      {transferList && (
        <>
          <TableHeader
            totalCount={totalCount}
            pagePerRow={pagePerRow}
            onChange={setPagePerRow}
          ></TableHeader>
          <TableContainer component={"div"} style={{ background: "#ffffff" }}>
            <Table className="account-management__table">
              <TableHead>
                <TableRow>
                  <TableCell
                    rowSpan={2}
                    style={{ minWidth: 100 }}
                    align="center"
                  >
                    거래일자
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    style={{ minWidth: 100 }}
                    align="center"
                  >
                    서비스번호
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    style={{ minWidth: 200 }}
                    align="center"
                  >
                    서비스이름
                  </TableCell>
                  <TableCell rowSpan={1} colSpan={5} align="center">
                    입금
                  </TableCell>
                  <TableCell rowSpan={1} colSpan={4} align="center">
                    출금
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    style={{ minWidth: 120 }}
                    align="center"
                  >
                    상태
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    style={{ minWidth: 120 }}
                    align="center"
                  >
                    비고
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    입금(Klay)
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    입금(원화) 당시 시세
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    입금(원화) 당시 원화
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    입금자 통합회원 ID
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    입금 상점번호
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    출금(Klay)
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 200 }}>
                    출금지갑
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    출금 통합회원 ID
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    잔액 (회사계정)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transferList &&
                  transferList.map((item, index) => {
                    // console.log('item', item);
                    return (
                      <TableRow
                        key={"service-row-" + index}
                        style={{ height: 60 }}
                      >
                        <TableCell align="center">
                          {DateUtil.dbDateToYYYYMMDD(item.reg_dt)}
                        </TableCell>
                        <TableCell align="center">{item.svc_num}</TableCell>
                        <TableCell align="center">{item.svc_name}</TableCell>
                        <TableCell align="center">
                          {KlaytnUtil.pebToKlay(item.deposit_klay)}
                        </TableCell>
                        <TableCell align="center">
                          {item.deposit_closing_price
                            ? Number(item.deposit_closing_price).toLocaleString(
                                "en"
                              )
                            : null}
                        </TableCell>
                        <TableCell align="center">
                          {item.deposit_won_price
                            ? Number(item.deposit_won_price).toLocaleString(
                                "en"
                              )
                            : null}
                        </TableCell>
                        <TableCell align="center">
                          {item.deposit_mbr_id}
                        </TableCell>
                        <TableCell align="center">
                          {item.deposit_mall_type}
                        </TableCell>
                        <TableCell align="center">
                          {KlaytnUtil.pebToKlay(item.withdrawal_klay)}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <KlipAddress
                              address={item.withdrawal_address}
                            ></KlipAddress>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {item.withdrawal_mbr_id}
                        </TableCell>
                        <TableCell align="center">
                          {KlaytnUtil.pebToKlay(item.withdrawal_balance)}
                        </TableCell>
                        <TableCell align="center">{item.status}</TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <span
                                style={{ fontSize: 13, lineHeight: "20px" }}
                              >
                                {item.memo}
                              </span>
                            }
                            arrow
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  maxWidth: 120,
                                  overflow: "hidden",
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.memo}
                              </span>
                            </div>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {transferList && transferList.length === 0 && (
                  <TableRow key={"service-row-empty"}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            page={page}
            totalPage={totalPage}
            onChange={onPageChange}
          ></Paging>
        </>
      )}
    </article>
  );
};
