import React, { useEffect, useState, useContext } from "react";
import queryString from "query-string";
import API from "../../api";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlaytnHash,
} from "../../components";
import { useFilter, usePagination } from "../../hooks";
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from "../../utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import InfoIcon from "@material-ui/icons/Info";
import { useSnackbar } from "notistack";
import { ErrorUtil } from "../../utils";
import { AuthContext, MemberGroupContext } from "../../contexts";

export const RewardListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  console.log("queryParams", queryParams);
  const type = queryParams.type;
  console.log("type", type);

  const [rewardList, setRewardList] = useState(null);
  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(
    1,
    0
  );
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [typeLoading, setTypeLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  console.log("totalPage", totalPage);
  console.log("totalCount", totalCount);

  const [filter, onChangeFilter, setFilter] = useFilter([]);
  const { memberGroup } = useContext(MemberGroupContext);
  const { serviceGroupList, serviceList } = useContext(AuthContext);

  useEffect(() => {
    console.log("type change", type);
    setTypeLoading(true);
    if (type === "user") {
      let userFilter = [
        {
          required: true,
          id: "member-group-id",
          component: "Select",
          value: memberGroup.selected || "hk",
          menus: [],
          label: "멤버 그룹 아이디",
          key: "memberGroupId",
        },
        {
          required: true,
          id: "member-id",
          component: "TextField",
          value: "",
          label: "멤버 아이디",
          type: "text",
          key: "memberId",
          inputProps: {
            maxLength: 128,
          },
        },
        {
          id: "option-type",
          component: "Select",
          value: "service_group",
          menus: [
            {
              text: "서비스 그룹",
              value: "service_group",
            },
            {
              text: "서비스",
              value: "service",
            },
          ],
          label: "옵션 타입",
          key: "optionType",
        },
        {
          disabled: true,
          id: "option-service-value",
          component: "Select",
          value:
            serviceList && serviceList.length > 0 ? serviceList[0].value : "",
          menus: [],
          label: "서비스 번호",
          key: "optionValue",
        },
        {
          disabled: false,
          id: "option-service_group-value",
          component: "Select",
          value:
            serviceGroupList && serviceGroupList.length > 0
              ? serviceGroupList[0].value
              : "",
          menus: [],
          label: "서비스 그룹 아이디",
          key: "optionValue",
        },
      ];

      setFilter(userFilter);
      setTypeLoading(false);
      setRewardList(null);
    }
  }, [type]);

  useEffect(() => {
    if (rewardList) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (rewardList) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  const requestNewList = (pageOffset) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log("filterParams", filterParams);
    // if (type === 'user' && (!filterParams.memberGroupId || !filterParams.memberId)) {
    //   console.log('user block params');
    //   return;
    // }
    // if (type === 'service' && !filterParams.serviceNumber) {
    //   console.log('service block params');

    //   return;
    // }

    // if (type === 'service_group' && !filterParams.serviceGroupId) {
    //   console.log('service_group block params');
    //   return;
    // }

    let params = {
      type: type,
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };

    console.log("params", params);
    setListLoading(true);

    API.Reward.GetList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Reward GetList", result);
        if (result) {
          setRewardList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
        }
        setListLoading(false);
      },
      (err) => {
        console.log("err", err);
        setListLoading(false);

        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
      }
    );
  };
  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };

  if (typeLoading) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }
  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>예약 목록</h1>
          </div>
        </section>
      </header>
      <section>
        <Filter
          data={filter}
          updateData={onChangeFilter}
          onSearch={onSearch}
          loading={listLoading}
        ></Filter>
      </section>

      {rewardList && (
        <>
          <TableHeader
            totalCount={totalCount}
            pagePerRow={pagePerRow}
            onChange={setPagePerRow}
          ></TableHeader>
          <TableContainer component={"div"} style={{ background: "#ffffff" }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell align="center">리워드 시퀀스</TableCell>
                  <TableCell align="center">서비스 번호</TableCell>
                  <TableCell align="center">서비스 이름</TableCell>
                  <TableCell align="center">수량(Klay)</TableCell>
                  <TableCell align="center">작업 상태</TableCell>
                  <TableCell align="center">등록일</TableCell>
                  <TableCell align="center">예약일</TableCell>
                  <TableCell align="center">만료일</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rewardList &&
                  rewardList.map((item, index) => {
                    console.log("item", item);
                    return (
                      <TableRow
                        key={"service-row-" + index}
                        style={{ height: 60 }}
                      >
                        <TableCell align="center">{item.rwd_q_seq}</TableCell>
                        <TableCell align="center">{item.svc_num}</TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <span
                                style={{ fontSize: 13, lineHeight: "20px" }}
                              >
                                {item.svc_desc}
                              </span>
                            }
                            arrow
                          >
                            <span>{item.svc_name}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {KlaytnUtil.pebToKlay(item.amount)}
                        </TableCell>
                        <TableCell align="center">{item.job_status}</TableCell>
                        <TableCell align="center">
                          {DateUtil.dbDateToYYYYMMDD(item.reg_dt)}
                        </TableCell>
                        <TableCell align="center">
                          {DateUtil.dbDateToYYYYMMDD(item.reserve_dt)}
                        </TableCell>
                        <TableCell align="center">
                          {DateUtil.dbDateToYYYYMMDD(item.expire_dt)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {rewardList && rewardList.length === 0 && (
                  <TableRow key={"service-row-empty"}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            page={page}
            totalPage={totalPage}
            onChange={onPageChange}
          ></Paging>
        </>
      )}
    </article>
  );
};
