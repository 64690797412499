import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { UserContext, AuthContext } from '../contexts';
import { Loading } from '../components';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import { AdminUtil } from '../utils';

export const Header = (props) => {
  const history = useHistory();
  const { userInfo } = useContext(UserContext);
  const {
    auth,
    authArray,
    selectedToolId,
    updateSelectedToolId,
    updateAuth,
    updateAuthArray,
    updateServiceGroupList,
    updateServiceList,
  } = useContext(AuthContext);

  console.log('userInfo', userInfo);
  console.log('auth', auth);
  console.log('selectedToolId', selectedToolId);

  if (!userInfo) {
    return (
      <article className="main-header">
        <Loading></Loading>
      </article>
    );
  }

  return (
    <article className="main-header">
      <ul className="tool-list">
        {auth &&
          auth.children &&
          auth.children.length > 0 &&
          auth.children.map((item, index) => {
            let authType = AdminUtil.checkAuthType(item, authArray);
            console.log('tool item authType', authType);
            if (authType) {
              return (
                <li
                  key={'main-header-tool-item-' + index}
                  onClick={() => {
                    updateSelectedToolId(item.part_seq);
                  }}
                  className={item.part_seq === selectedToolId ? 'selected' : ''}
                >
                  {item.name}
                  {/* <em>[{AdminUtil.authTypeToText(authType)}]</em> */}
                </li>
              );
            } else {
              return;
            }
          })}
      </ul>
      <ul>
        <li>
          <span>{userInfo.idToken.payload.email}</span>
        </li>
        <li>
          <IconButton
            color="inherit"
            component="span"
            onClick={() => {
              Auth.signOut({ global: true }).then(
                (res) => {
                  console.log('res', res);
                  updateSelectedToolId(null);
                  updateAuth(null);
                  updateAuthArray([]);
                  updateServiceGroupList([]);
                  updateServiceList([]);

                  history.push('/login');
                },
                (err) => {
                  console.log('err', err);
                  alert(err.message);
                  // if (err.code === 'PasswordResetRequiredException') {
                  //   console.log('request reset password');
                  //   Auth.forgotPasswordSubmit(userInfo.accessToken.payload.username, '192200', 'admin12!@')
                  //     .then((data) => console.log(data))
                  //     .catch((err) => console.log(err));
                  // }
                },
              );
            }}
          >
            <ExitToAppSharpIcon />
          </IconButton>
        </li>
      </ul>
    </article>
  );
};
