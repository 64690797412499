import React, { useEffect, useState, useContext } from 'react';
import API from '../../api';
import { Form, Loading } from '../../components';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { AdminUtil, ErrorUtil } from '../../utils';
import { DialogContext, AuthContext } from '../../contexts';

export const ServiceGroupCreatePage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({
    svc_grp_id: '',
    name: '',
  });
  const [formProps, setFormProps] = useState(null);
  const { auth, authArray, serviceGroupList, updateServiceGroupList } = useContext(AuthContext);
  useEffect(() => {
    // API.KAS.Get().then(
    //   (res) => {
    //     console.log('res', res);
    //     const result = API.Utils.ResultCheck(res);
    //     console.log('result', result);
    //     let newFormProps = {
    //       accountList: result.list,
    //     };
    //     setFormProps(newFormProps);
    //   },
    //   (err) => {
    //     console.log('err', err);
    //     enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
    //   },
    // );
  }, []);

  const onChange = (name, value) => {
    let newData = _.cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const requestCreate = () => {
    console.log('data', data);

    if (data.svc_grp_id === 'all') {
      enqueueSnackbar('all은 사용할 수 없는 서비스그룹명입니다.', { variant: 'error' });

      return;
    }

    let body = {
      serviceGroupId: data.svc_grp_id,
      // accountId: data.accnt_id.join(),
      name: data.name,
    };
    console.log('body', body);

    API.ServiceGroup.Post(body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result) {
          // auth provider에 serviceGroup을 추가해줘야함.
          let newServiceGroupList = _.cloneDeep(serviceGroupList);
          console.log('newServiceGroupList', newServiceGroupList);
          if (newServiceGroupList[0].text === '전체') {
            newServiceGroupList[0].value += ',' + data.svc_grp_id;
          }
          newServiceGroupList.push({
            value: data.svc_grp_id,
            text: data.name,
          });

          updateServiceGroupList(newServiceGroupList);

          //link router
          enqueueSnackbar('생성 성공', { variant: 'success' });
          let targetItem = AdminUtil.searchByUrl(auth, '/service-group/detail');
          let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);
          console.log('targetItem', targetItem);
          console.log('linkAuthType', linkAuthType);
          if (linkAuthType) {
            history.push({
              pathname: '/service-group/detail/' + data.svc_grp_id,
              state: { pageAuthType: linkAuthType },
            });
          }
        }
      },
      (err) => {
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  // if (!formProps) {
  //   return (
  //     <article className="content-page">
  //       <Loading></Loading>
  //     </article>
  //   );
  // }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>서비스 그룹 생성</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            <li>
              <Button
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                onClick={requestCreate}
                variant="contained"
                color="primary"
              >
                저장
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <article className="account-detail">
        <Form
          title="기본 정보"
          data={data}
          type="create"
          contentType="service-group"
          onChange={onChange}
          formProps={formProps}
        ></Form>
      </article>
    </article>
  );
};
