import React, { useContext, useEffect, useState } from 'react';
import { Chart } from 'react-charts';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { FilterUtil } from '../utils';

const fieldToText = (value) => {
  switch (value) {
    case 'amount_sum': {
      return '총 지급 수량';
    }
    case 'fee_sum': {
      return '총 수수료';
    }
    case 'transfer_count': {
      return '총 건수';
    }
    case 'total_count': {
      return '총 계정 수';
    }
    case 'link_count': {
      return '기존 클립 계정';
    }
    case 'klip_new_count': {
      return '신규 클립 계정';
    }
    default: {
      return '';
    }
  }
};

export const BiChart = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, type, content, filter } = props;
  console.log('data', data);
  const [labels, setLables] = useState([]);
  const [values, setValues] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [axes, setAxes] = useState([
    { primary: true, type: 'ordinal', position: 'bottom' },
    { position: 'left', type: 'linear', hardMin: 0 },
    { position: 'right', type: 'linear', hardMin: 0, id: 'count' },
  ]);
  let klipNew = '';

  // link bi에만 사용됨
  if (filter) {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('BiTable filterParams', filterParams);
    klipNew = filterParams.klipNew; //1,0는 all 1는 신규생성 0은 링크 연결
  }

  useEffect(() => {
    if (data && data.length > 0) {
      let item = data[0];
      let keyName = Object.keys(item)[0];
      setLables([keyName]);
      if (content === 'transfer') {
        setValues(['amount_sum']);
      } else if (content === 'link') {
        if (klipNew === '1,0') {
          setValues(['total_count']);
        } else if (klipNew === '1') {
          setValues(['klip_new_count']);
        } else if (klipNew === '0') {
          setValues(['link_count']);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    let updateChartData = [];
    if (content === 'transfer') {
      for (let i in labels) {
        let targetLabel = labels[i];
        console.log('targetLabel', targetLabel);
        console.log('data', data);

        for (let k in values) {
          let targetValue = values[k];
          console.log('targetValue', targetValue);
          let targetDataset = null;
          for (let i in data) {
            let targetData = data[i];
            console.log('targetData', targetData);
            let targetKey = Object.keys(targetData)[0];
            console.log('targetKey', targetKey);
            if (targetKey === targetLabel) {
              targetDataset = targetData[targetKey];
              break;
            }
          }
          let newDataSet = [];
          for (let i in targetDataset) {
            let newDataItem = [];
            newDataItem.push(targetDataset[i].date);
            newDataItem.push(targetDataset[i][targetValue]);
            newDataSet.push(newDataItem);
          }
          let labelText = fieldToText(targetValue);
          if (targetValue === 'transfer_count') {
            updateChartData.push({
              label: targetLabel + '(' + labelText + ')',
              data: newDataSet,
              secondaryAxisID: 'count',
            });
          } else {
            updateChartData.push({
              label: targetLabel + '(' + labelText + ')',
              data: newDataSet,
              secondaryAxisID: 'klay',
            });
          }

          console.log('newDataSet', newDataSet);
        }
      }
      console.log('updateChartData', updateChartData);
    } else if (content === 'link') {
      for (let i in labels) {
        let targetLabel = labels[i];
        console.log('targetLabel', targetLabel);
        console.log('data', data);

        for (let k in values) {
          let targetValue = values[k];
          console.log('targetValue', targetValue);
          let targetDataset = null;
          for (let i in data) {
            let targetData = data[i];
            console.log('targetData', targetData);
            let targetKey = Object.keys(targetData)[0];
            console.log('targetKey', targetKey);
            if (targetKey === targetLabel) {
              targetDataset = targetData[targetKey];
              break;
            }
          }
          let newDataSet = [];
          for (let i in targetDataset) {
            let newDataItem = [];
            newDataItem.push(targetDataset[i].date);
            newDataItem.push(targetDataset[i][targetValue]);
            newDataSet.push(newDataItem);
          }
          let labelText = fieldToText(targetValue);
          updateChartData.push({
            label: targetLabel + '(' + labelText + ')',
            data: newDataSet,
            secondaryAxisID: 'link',
          });

          console.log('newDataSet', newDataSet);
        }
      }
      console.log('updateChartData', updateChartData);
    }

    setChartData(updateChartData);

    if (content === 'transfer') {
      if (values.includes('transfer_count')) {
        setAxes([
          { primary: true, type: 'ordinal', position: 'bottom' },
          { position: 'left', type: 'linear', hardMin: 0, id: 'klay' },
          { position: 'right', type: 'linear', hardMin: 0, id: 'count' },
        ]);
      } else {
        setAxes([
          { primary: true, type: 'ordinal', position: 'bottom' },
          { position: 'left', type: 'linear', hardMin: 0, id: 'klay' },
        ]);
      }
    } else if (content === 'link') {
      setAxes([
        { primary: true, type: 'ordinal', position: 'bottom' },
        { position: 'left', type: 'linear', hardMin: 0, id: 'link' },
      ]);
    }
  }, [labels, values]);

  if (!data) {
    return null;
  }
  if (data.length === 0) {
    return (
      <article className="bi-chart">
        <section>
          <span>데이터가 없습니다.</span>
        </section>
      </article>
    );
  }

  const seriesSample = {
    type: 'bar',
  };

  const onClickLabel = (value) => {
    let newLables = _.cloneDeep(labels);
    if (_.includes(newLables, value)) {
      _.pull(newLables, value);
    } else {
      newLables.push(value);
    }

    if (newLables.length > 2) {
      enqueueSnackbar('2개 이상 선택할 수 없습니다.', { variant: 'warning' });
      return;
    } else if (newLables.length === 0) {
      enqueueSnackbar('1개 이상은 선택해야 합니다.', { variant: 'warning' });
    } else {
      setLables(newLables);
    }
  };

  const onClickValue = (value) => {
    let newValues = _.cloneDeep(values);
    if (_.includes(newValues, value)) {
      _.pull(newValues, value);
    } else {
      newValues.push(value);
    }
    if (newValues.length === 0) {
      enqueueSnackbar('1개 이상은 선택해야 합니다.', { variant: 'warning' });
      return;
    } else {
      setValues(newValues);
    }
  };
  console.log('[render] labels', labels);
  console.log('[render] values', values);

  let VALUE_LIST = [];

  if (content === 'transfer') {
    VALUE_LIST = ['amount_sum', 'fee_sum', 'transfer_count'];
  } else if (content === 'link') {
    if (klipNew === '1,0') {
      VALUE_LIST = ['total_count', 'link_count', 'klip_new_count'];
    } else if (klipNew === '1') {
      VALUE_LIST = ['klip_new_count'];
    } else if (klipNew === '0') {
      VALUE_LIST = ['link_count'];
    }
  }

  return (
    <article className="bi-chart">
      <header>
        <div className="content-select__box">
          {data.map((item, index) => {
            let keyName = Object.keys(item)[0];
            return (
              <li
                className={labels.includes(keyName) ? 'active' : ''}
                onClick={() => {
                  onClickLabel(keyName);
                }}
              >
                {keyName}
              </li>
            );
          })}
        </div>
        <div className="content-select__box">
          {VALUE_LIST.map((item, index) => {
            return (
              <li className={values.includes(item) ? 'active' : ''} onClick={() => onClickValue(item)}>
                {fieldToText(item)}
              </li>
            );
          })}
        </div>
      </header>
      <section className="chart__box">
        {chartData && <Chart data={chartData} axes={axes} series={seriesSample} tooltip />}
      </section>
    </article>
  );
};
