import axios from 'axios';
import { BASE_URL } from './Config';
import { Auth } from 'aws-amplify';
import { Base64 } from 'js-base64';

const AxiosInstance = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const checkSearchStringExist = (str) => {
  const splitStringList = str.split('?');
  if (splitStringList.length === 1) {
    return false;
  } else {
    return true;
  }
};

// 요청 인터셉터 추가
AxiosInstance.interceptors.request.use(
  async (config) => {
    console.log('interceptors request config', config);
    const currentSession = await Auth.currentSession();
    console.log('currentSession', currentSession);
    config.headers.Authorization = currentSession.getIdToken().getJwtToken();

    //시스템 점검용 config
    // if (checkSearchStringExist(config.url)) {
    //   config.url = config.url + '&pass=joy';
    // } else {
    //   config.url = config.url + '?&pass=joy';
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터 추가
AxiosInstance.interceptors.response.use(
  (response) => {
    console.log('interceptors response', response);
    const maintenanceString = response.headers['maintenance'];
    if (maintenanceString) {
      // const maintenanceObj = JSON.parse(maintenanceString);
      console.log('maintenanceString', maintenanceString);
      let decode = JSON.parse(Base64.decode(maintenanceString));
      console.log('decode', decode);
      window.location.href = `/maintenance?message=${encodeURIComponent(decode.message)}`;
    } else {
      return response;
    }
  },
  (error) => {
    console.log('interceptors error', error);
    if (error === 'No current user') {
      alert('로그인 유효 시간이 만료되었습니다. 다시 로그인해주세요.');
      window.location.href = '/login';
    }

    const maintenanceString = error.response.headers['maintenance'];
    if (maintenanceString) {
      // const maintenanceObj = JSON.parse(maintenanceString);
      console.log('maintenanceString', maintenanceString);
      let decode = JSON.parse(Base64.decode(maintenanceString));
      console.log('decode', decode);
      window.location.href = `/maintenance?message=${encodeURIComponent(decode.message)}`;
    } else {
      return Promise.reject(error.response);
    }
  },
);

export default AxiosInstance;
