import React, { useEffect, useState, useContext } from "react";
import queryString from "query-string";
import API from "../../api";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlipAddress,
} from "../../components";
import { useFilter, usePagination } from "../../hooks";
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from "../../utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import InfoIcon from "@material-ui/icons/Info";
import { useSnackbar } from "notistack";
import { ErrorUtil } from "../../utils";
import { MemberGroupContext, AuthContext, DialogContext } from "../../contexts";
import moment from "moment-timezone";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";

export const LinkListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const type = queryParams.type;
  console.log("type", type);
  const [list, setList] = useState(null);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(
    1,
    0
  );
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [typeLoading, setTypeLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const { memberGroup } = useContext(MemberGroupContext);
  const { serviceGroupList, serviceList } = useContext(AuthContext);
  const [lastFilter, setLastFilter] = useState(null);
  const { updateDialog } = useContext(DialogContext);
  const [excelLoading, setExcelLoading] = useState(false);

  console.log("totalPage", totalPage);
  console.log("totalCount", totalCount);
  console.log("[link-page]memberGroup", memberGroup);
  const [filter, onChangeFilter, setFilter] = useFilter([]);

  useEffect(() => {
    console.log("type change", type);
    let now = moment(new Date()).format("YYYY-MM-DDTHH:mm");
    let firstDay = new Date();
    firstDay.setDate(1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);
    firstDay.setSeconds(0);
    firstDay = moment(firstDay).format("YYYY-MM-DDTHH:mm");

    setTypeLoading(true);
    if (type === "date_range") {
      let linkFilter = [
        // {
        //   required: true,
        //   id: 'member-group-id',
        //   component: 'Select',
        //   value: memberGroup.selected || 'hk',
        //   menus: [],
        //   label: '멤버 그룹 아이디',
        //   key: 'memberGroupId',
        // },
        {
          required: true,
          id: "service-group-id",
          component: "Select",
          value:
            serviceGroupList && serviceGroupList.length > 0
              ? serviceGroupList[0].value
              : "",
          menus: [],
          label: "서비스 그룹 아이디",
          key: "serviceGroupId",
        },
        {
          id: "none-serviceNumber",
          component: "Select",
          value: "none",
          menus: [],
          label: "서비스 번호",
          key: "serviceNumber",
        },
        {
          required: true,
          id: "klip-new",
          component: "Select",
          value: "1,0",
          menus: [
            {
              value: "1,0",
              text: "전체",
            },
            {
              value: "1",
              text: "신규 클립 계정",
            },
            {
              value: "0",
              text: "기존 클립 계정",
            },
          ],
          label: "신규 생성 구분",
          key: "klipNew",
        },
        {
          id: "start-date-time",
          component: "TextField",
          type: "datetime-local",
          value: firstDay,
          label: "시작일",
          inputProps: {},
          InputLabelProps: {
            shrink: true,
          },
          key: "startDate",
        },
        {
          id: "end-date-time",
          component: "TextField",
          type: "datetime-local",
          value: now,
          label: "종료일",
          inputProps: {},
          InputLabelProps: {
            shrink: true,
          },
          key: "endDate",
        },
      ];
      setFilter(linkFilter);
      setTypeLoading(false);
      setList(null);
      setInfo(null);
      setPage(1);
      requestNewListWithFilfer(linkFilter);
    } else if (type === "user") {
      let linkFilter = [
        {
          required: true,
          id: "member-group-id",
          component: "Select",
          value: memberGroup.selected || "hk",
          menus: [],
          label: "멤버 그룹 아이디",
          key: "memberGroupId",
        },
        {
          required: true,
          id: "service-group-id",
          component: "Select",
          value:
            serviceGroupList && serviceGroupList.length > 0
              ? serviceGroupList[0].value
              : "",
          menus: [],
          label: "서비스 그룹 아이디",
          key: "serviceGroupId",
        },
        {
          id: "none-serviceNumber",
          component: "Select",
          value: "none",
          menus: [],
          label: "서비스 번호",
          key: "serviceNumber",
        },
        {
          required: true,
          id: "klip-new",
          component: "Select",
          value: "1,0",
          menus: [
            {
              value: "1,0",
              text: "전체",
            },
            {
              value: "1",
              text: "신규 클립 계정",
            },
            {
              value: "0",
              text: "기존 클립 계정",
            },
          ],
          label: "신규 생성 구분",
          key: "klipNew",
        },
        {
          required: true,
          id: "member-id",
          component: "TextField",
          value: "",
          label: "멤버 아이디",
          type: "text",
          key: "memberId",
          inputProps: {
            maxLength: 128,
          },
        },
      ];
      setFilter(linkFilter);
      setTypeLoading(false);
      setList(null);
    }
  }, [type]);

  useEffect(() => {
    console.log("requestNewList - page");
    if (list) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    console.log("pagePerRow", pagePerRow);
    setPage(1);
    if (list) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  const requestNewListWithFilfer = (newFilter) => {
    let filterParams = FilterUtil.dataToParams(newFilter);
    console.log("filterParams", filterParams);
    if (type === "user") {
      if (!filterParams.memberGroupId || !filterParams.memberId) {
        console.log("missing parameter");
      }
    }

    let params = {
      type: type,
      ...filterParams,
      pageOffset: 0,
      pageSize: pagePerRow,
    };

    console.log("params", params);
    setListLoading(true);
    API.Link.GetList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Reward GetList", result);
        setListLoading(false);

        if (result) {
          setList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
          setInfo(result.info);
          setLastFilter({
            type: type,
            ...filterParams,
            totalCount: result.totalCount,
          });
        }
      },
      (err) => {
        console.log("err", err);
        setListLoading(false);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
        setLastFilter(null);
      }
    );
  };

  const requestNewList = (pageOffset) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log("filterParams", filterParams);
    if (type === "user") {
      if (!filterParams.memberGroupId || !filterParams.memberId) {
        console.log("missing parameter");
      }
    }

    let params = {
      type: type,
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };

    console.log("params", params);
    setListLoading(true);
    API.Link.GetList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Reward GetList", result);
        setListLoading(false);

        if (result) {
          setList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
          setInfo(result.info);
          setLastFilter({
            type: type,
            ...filterParams,
            totalCount: result.totalCount,
          });
        }
      },
      (err) => {
        console.log("err", err);
        setListLoading(false);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
        setLastFilter(null);
      }
    );
  };

  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };

  const requestExcelDownload = async () => {
    console.log("lastFitler", lastFilter);
    if (!lastFilter) {
      return;
    }
    //lambda 5MB response 이상일 경우, response max size 정책에 의해 fail을 return함.
    const BASE_SIZE = 5000;
    setExcelLoading(true);
    if (lastFilter.totalCount > BASE_SIZE) {
      console.log("lastFitler", lastFilter);

      let loop_count = Math.ceil(lastFilter.totalCount / BASE_SIZE);
      let total_result = [];
      for (let i = 1; i <= loop_count; i++) {
        console.log("i", i);

        let result = await API.Link.GetList({
          ...lastFilter,
          pageOffset: BASE_SIZE * (i - 1),
          pageSize: BASE_SIZE,
        });
        total_result.push(...result.data.list);
      }
      console.log("total_result", total_result);
      exportDataToXlsx(total_result);
      setExcelLoading(false);
    } else {
      API.Link.GetList({
        ...lastFilter,
        pageOffset: 0,
        pageSize: lastFilter.totalCount,
      }).then(
        (res) => {
          const result = API.Utils.ResultCheck(res);
          console.log("Link All GetList", result);
          if (result) {
            exportDataToXlsx(result.list);
          }
          setExcelLoading(false);
        },
        (err) => {
          console.log("err", err);
          enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
          setExcelLoading(false);
        }
      );
    }
  };

  const exportDataToXlsx = (list) => {
    if (!list) {
      enqueueSnackbar("데이터가 없습니다.", { variant: "error" });
      return;
    }
    if (list.length === 0) {
      enqueueSnackbar("데이터가 없습니다.", { variant: "error" });
      return;
    }
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(list),
      "link-list"
    );
    XLSX.writeFile(wb, "shift_link_list.xlsx");
  };

  const requestLinkDelete = () => {
    updateDialog({
      type: "caution",
      open: true,
      title: "링크 연결 삭제",
      subTitle: "삭제 후에는 되돌릴 수 없습니다. 그래도 진행하시겠습니까?",
      onConfirm: () => {
        console.log("delete", info);
        let params = {
          memberId: info.mbr_id,
          memberGroupId: info.mbr_grp_id,
        };
        API.Link.Delete(params).then(
          (res) => {
            console.log("res", res);
            const result = API.Utils.ResultCheck(res);
            console.log("result", result);
            updateDialog({
              type: "info",
              open: false,
              title: "",
              subTitle: "",
            });
            setList(null);
            setTotalCount(0);
            setTotalPage(0);
            setInfo(null);

            enqueueSnackbar("삭제 성공", { variant: "success" });
          },
          (err) => {
            console.log("err", err);
            updateDialog({
              type: "info",
              open: false,
              title: "",
              subTitle: "",
            });
            enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
          }
        );
      },
    });
  };

  if (typeLoading) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }
  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>{type === "user" ? "링크 임시 목록" : "링크 목록"}</h1>
          </div>
        </section>
        {type === "date_range" && (
          <ul className="option-list">
            <li>
              <Button
                color="primary"
                variant="contained"
                onClick={requestExcelDownload}
                disabled={excelLoading}
              >
                <GetAppIcon
                  style={{ marginRight: 5, fontSize: 18 }}
                ></GetAppIcon>
                {excelLoading ? "Loading..." : "EXPORT EXCEL"}
              </Button>
            </li>
          </ul>
        )}
      </header>
      <section>
        <Filter
          data={filter}
          updateData={onChangeFilter}
          onSearch={onSearch}
          loading={listLoading}
        ></Filter>
      </section>

      {info && (
        <section className="link-detail__box">
          <header>
            <h1>링크 정보</h1>
          </header>
          <TableContainer component={"div"} style={{ background: "#ffffff" }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell align="center">링크 번호</TableCell>
                  <TableCell align="center">멤버 그룹</TableCell>
                  <TableCell align="center">멤버 아이디</TableCell>
                  <TableCell align="center">클립 주소</TableCell>
                  <TableCell align="center">서비스 그룹 이름</TableCell>
                  <TableCell align="center">서비스 이름</TableCell>
                  <TableCell align="center">등록일</TableCell>
                  <TableCell align="center">신규생성</TableCell>
                  <TableCell align="center">삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={"service-row-info"} style={{ height: 60 }}>
                  <TableCell align="center">{info.link_num}</TableCell>
                  <TableCell align="center">{info.mbr_grp_id}</TableCell>
                  <TableCell align="center">{info.mbr_id}</TableCell>
                  <TableCell align="center">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <KlipAddress address={info.klip_address}></KlipAddress>
                    </div>
                  </TableCell>
                  <TableCell align="center">{info.sg_name}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        <span style={{ fontSize: 13, lineHeight: "20px" }}>
                          {info.svc_desc}
                        </span>
                      }
                      arrow
                    >
                      <span>{info.svc_name}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    {DateUtil.dbDateToYYYYMMDD(info.reg_dt)}
                  </TableCell>
                  <TableCell align="center">
                    {info.klip_new ? "Y" : "N"}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      disabled={
                        location.state.pageAuthType === "rw" ? false : true
                      }
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        requestLinkDelete();
                      }}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </section>
      )}

      {list && (
        <>
          {type === "user" && (
            <header style={{ marginBottom: 0, background: "#fafafd" }}>
              <h1>링크 연결 시도 정보</h1>
            </header>
          )}

          <TableHeader
            totalCount={totalCount}
            pagePerRow={pagePerRow}
            onChange={setPagePerRow}
          ></TableHeader>
          <TableContainer component={"div"} style={{ background: "#ffffff" }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell align="center">
                    {type === "user" ? "링크 임시 번호" : "링크 번호"}
                  </TableCell>
                  <TableCell align="center">멤버 그룹</TableCell>
                  <TableCell align="center">멤버 아이디</TableCell>
                  {type === "user" && (
                    <TableCell align="center">상태</TableCell>
                  )}
                  <TableCell align="center">클립 주소</TableCell>
                  <TableCell align="center">서비스 그룹 이름</TableCell>
                  <TableCell align="center">서비스 이름</TableCell>
                  <TableCell align="center">등록일</TableCell>
                  {type === "user" && (
                    <TableCell align="center">요청 만료일</TableCell>
                  )}
                  {type === "date_range" && (
                    <TableCell align="center">신규생성</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((item, index) => {
                    console.log("item", item);
                    return (
                      <TableRow
                        key={"service-row-" + index}
                        style={{ height: 60 }}
                      >
                        <TableCell align="center">
                          {type === "user" ? item.link_temp_seq : item.link_num}
                        </TableCell>
                        <TableCell align="center">{item.mbr_grp_id}</TableCell>
                        <TableCell align="center">{item.mbr_id}</TableCell>
                        {type === "user" && (
                          <TableCell align="center">
                            {item.link_status}
                          </TableCell>
                        )}
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <KlipAddress
                              address={item.klip_address}
                            ></KlipAddress>
                          </div>
                        </TableCell>
                        <TableCell align="center">{item.sg_name}</TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <span
                                style={{ fontSize: 13, lineHeight: "20px" }}
                              >
                                {item.svc_desc}
                              </span>
                            }
                            arrow
                          >
                            <span>{item.svc_name}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {DateUtil.dbDateToYYYYMMDD(item.reg_dt)}
                        </TableCell>
                        {type === "user" && (
                          <TableCell align="center">
                            {DateUtil.dbDateToYYYYMMDD(item.klip_req_expire_dt)}
                          </TableCell>
                        )}
                        {type === "date_range" && (
                          <TableCell align="center">
                            {item.klip_new ? "Y" : "N"}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {list && list.length === 0 && (
                  <TableRow key={"service-row-empty"}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            page={page}
            totalPage={totalPage}
            onChange={onPageChange}
          ></Paging>
        </>
      )}
    </article>
  );
};
