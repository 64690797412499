import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';
import queryString from 'query-string';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash, KlipAddress } from '../../components';
import { useFilter, usePagination } from '../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from '../../utils';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { AdminUtil, ErrorUtil } from '../../utils';
import { DialogContext, AuthContext, UserContext } from '../../contexts';
import XLSX from 'xlsx';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

export const AccountingHandwritingPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState(null);
  const [adminInfo, setAdminInfo] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const { updateDialog } = useContext(DialogContext);
  const { serviceGroupList, serviceList } = useContext(AuthContext);
  const { userInfo } = useContext(UserContext);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(1, 0);
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [modal, setModal] = useState({
    open: false,
  });
  const [info, setInfo] = useState({
    serviceNumber: '',
    productName: '',
    closingPrice: '',
    mallType: '',
    wonPrice: '',
  });
  const [infoServiceList, setInfoServiceList] = useState([]);

  let firstDay = new Date();
  firstDay.setDate(1);
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay = moment(firstDay).format('YYYY-MM-DDTHH:mm');
  let date = new Date();
  var lastDay = new Date(date.setDate(date.getDate())).setHours(0, 0, 0, 0);
  lastDay = moment(lastDay).format('YYYY-MM-DDTHH:mm');
  const [filter, onChangeFilter, setFilter] = useFilter([
    {
      required: true,
      id: 'accnt_id',
      component: 'Select',
      value: '',
      menus: [],
      label: '계좌 선택',
      key: 'accntId',
    },
    {
      id: 'start-date-time',
      component: 'TextField',
      type: 'datetime-local',
      value: firstDay,
      label: '시작일',
      inputProps: {},
      InputLabelProps: {
        shrink: true,
      },
      key: 'startDate',
    },
    {
      id: 'end-date-time',
      component: 'TextField',
      type: 'datetime-local',
      value: lastDay,
      label: '종료일',
      inputProps: { max: lastDay },
      InputLabelProps: {
        shrink: true,
      },
      key: 'endDate',
    },
  ]);

  console.log('serviceGroupList', serviceGroupList);
  console.log('serviceList', serviceList);
  console.log('userInfo', userInfo.getIdToken());
  useEffect(() => {
    console.log('filter accntid', filter[0].value);
    if (filter[0].value) {
      API.Service.GetByAccntId({ accntId: filter[0].value }).then(
        (res) => {
          console.log('GetByAccntId res', res);
          const result = API.Utils.ResultCheck(res);
          console.log('result', result);
          const updateServiceList = result.list;
          if (updateServiceList.length > 0) {
            let filterServiceMenus = [];
            for (let i in updateServiceList) {
              let menuItem = {};
              menuItem.value = updateServiceList[i].svc_num;
              menuItem.text = updateServiceList[i].name + '(' + updateServiceList[i].svc_num + ')';
              filterServiceMenus.push(menuItem);
            }
            const initMenuValue = filterServiceMenus[0].value;
            setInfo({ ...info, serviceNumber: initMenuValue });
            setInfoServiceList(filterServiceMenus);
          } else {
            setInfo({ ...info, serviceNumber: '' });
            setInfoServiceList([]);
          }
        },
        (err) => {
          console.log('GetByAccntId err', err);
        },
      );
    }
  }, [filter[0].value]);

  useEffect(() => {
    try {
      API.KAS.Get({
        svc_grp_id: serviceGroupList[0].value,
      }).then(
        (res) => {
          console.log('res', res);
          const result = API.Utils.ResultCheck(res);
          console.log('result', result);
          const accountList = result.list;

          let accountFilterMenus = [];
          for (let i in accountList) {
            let menuItem = {};
            menuItem.value = accountList[i].accnt_id;
            menuItem.text = accountList[i].name + '(' + accountList[i].accnt_id + ')';
            accountFilterMenus.push(menuItem);
          }
          const initMenuValue = accountFilterMenus[0].value;

          let newFilter = _.cloneDeep(filter);
          newFilter[0].menus = accountFilterMenus;
          newFilter[0].value = initMenuValue;
          setFilter(newFilter);
          setLoading(false);
          requestNewList(0, initMenuValue);
        },
        (err) => {
          console.log('err', err);
          setError(true);
        },
      );

      API.AdminUser.GetList({}).then(
        (res) => {
          const result = API.Utils.ResultCheck(res);
          console.log('AdminUser GetList result', result);
          if (result) {
            let adminId = userInfo.getAccessToken().payload.username;
            console.log('adminId', adminId);

            const adminList = result.list;
            for (let i in adminList) {
              const target = adminList[i];
              console.log('target', target);
              if (target.admin_id === adminId) {
                setAdminInfo(target);
                break;
              }
            }
          }
        },
        (err) => {
          console.log('err', err);
          enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
        },
      );
    } catch (err) {
      enqueueSnackbar('페이지 전환 후, 다시 시도해주십시오.', { variant: 'error' });
      setLoading(false);
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (list) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (list) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  useEffect(() => {
    console.log('useEffect serviceList', serviceList);
    if (serviceList && serviceList.length > 0) {
      let newInfo = _.clone(info);
      newInfo.serviceNumber = serviceList[0].value;
      setInfo(newInfo);
    }
  }, [serviceList]);

  const requestNewList = (pageOffset, initMenuValue) => {
    let filterParams = FilterUtil.dataToParams(filter);
    if (initMenuValue) {
      filterParams.accntId = initMenuValue;
    }
    let params = {
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };
    API.Deal.GetCheckList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        setLoading(false);
        console.log('Accounting GetManagementList', result);
        setList(result.list);
        setTotalCount(result.totalCount);
        setTotalPage(Math.ceil(result.totalCount / pagePerRow));
      },
      (err) => {
        console.log('err', err);
        setLoading(false);
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const onSearch = () => {
    console.log('onSearch');
    setPage(1);
    requestNewList(0);
  };

  const onCloseModal = () => {
    setModal({
      ...modal,
      open: false,
    });
    let newInfo = _.clone(info);
    newInfo.productName = '';
    newInfo.closingPrice = '';
    newInfo.mallType = '';
    newInfo.wonPrice = '';
    setInfo(newInfo);
  };

  if (loading) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  console.log('userInfo.getIdToken()', userInfo);
  console.log('adminInfo', adminInfo);
  const requestSave = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    onCloseModal();
    console.log('requestSave');

    updateDialog({
      type: 'caution',
      open: true,
      title: '수기 입력',
      subTitle: '수기 입력 후에는 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
      onConfirm: () => {
        console.log('requestDelete');

        let body = {
          mallType: info.mallType,
          productName: info.productName,
          serviceNumber: info.serviceNumber,
          orderDate: selectedTransfer.transfer_reg_dt,
          closingPrice: Number(info.closingPrice),
          wonPrice: Number(info.wonPrice),
          memberId: userInfo.getIdToken().payload.email,
          memberName: adminInfo.name,
          kasTransferSeq: selectedTransfer.kas_transfer_seq,
        };

        console.log('body', body);
        API.Deal.ForceInsert(body).then(
          (res) => {
            console.log('ForceInsert res', res);
            setPage(1);
            requestNewList(0);
            enqueueSnackbar('수기 입력 성공', { variant: 'success' });
          },
          (err) => {
            console.log('ForceInsert err', err);
            enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
          },
        );

        console.log('body', body);
        updateDialog({
          type: 'info',
          open: false,
          title: '',
          subTitle: '',
        });
      },
    });
  };
  console.log('list', list);
  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>수기 입력</h1>
          </div>
          <div className="sub-title">
            <span>외부 입금 목록 조회 -> 수기 입력</span>
          </div>
        </section>
      </header>
      <section>
        <Filter data={filter} updateData={onChangeFilter} onSearch={() => onSearch()} loading={dataLoading}></Filter>
      </section>
      {list && (
        <>
          <TableHeader totalCount={totalCount} pagePerRow={pagePerRow} onChange={setPagePerRow}></TableHeader>
          <TableContainer component={'div'} style={{ background: '#ffffff' }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    거래 일시
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    상태
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }} align="center">
                    수량(Klay)
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    발신 주소
                  </TableCell>

                  <TableCell style={{ minWidth: 80 }} align="center">
                    수기 입력
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((item, index) => {
                    // console.log('item', item);
                    return (
                      <TableRow key={'service-row-' + index} style={{ height: 60 }}>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.transfer_reg_dt)}</TableCell>
                        <TableCell align="center">{KlaytnUtil.TxStatusTextByStatusInt(item.status_int)}</TableCell>
                        <TableCell align="center">{KlaytnUtil.pebToKlay(item.amount)}</TableCell>
                        <TableCell align="center">
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <KlipAddress address={item.from_address}></KlipAddress>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            disabled={location.state.pageAuthType === 'rw' ? false : true}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setSelectedTransfer(item);
                              setModal({
                                ...modal,
                                open: true,
                              });
                            }}
                          >
                            수기 입력
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {list && list.length === 0 && (
                  <TableRow key={'service-row-empty'}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </>
      )}

      <Modal open={modal.open} onClose={onCloseModal}>
        {selectedTransfer && serviceList && (
          <div className="modal-container">
            <header>
              <h1>수기 정보 입력</h1>
            </header>
            <article className="modal-body">
              <section className="content__box">
                <ul>
                  <li>
                    <header>거래 일시</header>
                    <span className="data">{DateUtil.dbDateToYYYYMMDD(selectedTransfer.transfer_reg_dt)}</span>
                  </li>
                  <li>
                    <header>관리자 아이디</header>
                    <span className="data">{userInfo.getIdToken().payload.email}</span>
                  </li>
                  <li>
                    <header>관리자 이름</header>
                    <span className="data">{adminInfo.name}</span>
                  </li>
                  <li>
                    <header>수량(Klay)</header>
                    <span className="data">{KlaytnUtil.pebToKlay(selectedTransfer.amount)}</span>
                  </li>
                  <li>
                    <header>발신 주소</header>
                    <span className="data">{selectedTransfer.from_address}</span>
                  </li>
                  <li>
                    <header>서비스 번호</header>
                    <FormControl variant="outlined">
                      <Select
                        value={info.serviceNumber}
                        onChange={(e) => {
                          console.log('e', e);
                          let newInfo = _.clone(info);
                          newInfo.serviceNumber = e.target.value;
                          setInfo(newInfo);
                        }}
                      >
                        {infoServiceList.map((menu, menu_index) => {
                          // console.log('menu', menu);
                          return (
                            <MenuItem key={'modal-select-menu-' + menu_index} value={menu.value}>
                              {menu.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </li>
                  <li>
                    <header>상점명</header>
                    <TextField
                      type={'text'}
                      variant="outlined"
                      value={info.mallType}
                      onChange={(e) => {
                        let newInfo = _.clone(info);
                        newInfo.mallType = e.target.value;
                        setInfo(newInfo);
                      }}
                      inputProps={{ maxLength: 15 }}
                    />
                  </li>
                  <li>
                    <header>상품명</header>
                    <TextField
                      fullWidth
                      type={'text'}
                      variant="outlined"
                      value={info.productName}
                      onChange={(e) => {
                        let newInfo = _.clone(info);
                        newInfo.productName = e.target.value;
                        setInfo(newInfo);
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                  </li>
                  <li>
                    <header>거래시 시세(1Klay)</header>
                    <TextField
                      type={'number'}
                      variant="outlined"
                      value={info.closingPrice}
                      onChange={(e) => {
                        let newInfo = _.clone(info);
                        newInfo.closingPrice = e.target.value;
                        setInfo(newInfo);
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                  </li>
                  <li>
                    <header>원화</header>
                    <TextField
                      type={'number'}
                      variant="outlined"
                      value={info.wonPrice}
                      onChange={(e) => {
                        let newInfo = _.clone(info);
                        newInfo.wonPrice = e.target.value;
                        setInfo(newInfo);
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                  </li>
                </ul>
              </section>
              <section className="button__box">
                <Button color="default" variant="contained" onClick={onCloseModal} style={{ marginRight: 10 }}>
                  취소
                </Button>
                <Button color="primary" variant="contained" onClick={requestSave}>
                  저장
                </Button>
              </section>
            </article>
          </div>
        )}
      </Modal>
    </article>
  );
};
