const SnackBar = (err) => {
  if (err && err.data && err.data.code && err.data.message) {
    if (err.data.info) {
      return '[' + err.data.code + '] ' + err.data.message + ' info : ' + JSON.stringify(err.data.info);
    } else {
      return '[' + err.data.code + '] ' + err.data.message;
    }
  } else {
    return '알수없는 에러입니다.';
  }
};

export const ErrorUtil = {
  SnackBar: SnackBar,
};
