import { useState, useCallback } from 'react';
import _ from 'lodash';

export const usePagination = (initialPage, initialTotalPage) => {
  const [page, setPage] = useState(initialPage);
  const [totalPage, setTotalPage] = useState(initialTotalPage);

  // change
  const onChange = (e, newPage) => {
    console.log('e', e);
    console.log('newPage', newPage);
    setPage(newPage);
  };

  return [page, totalPage, onChange, setPage, setTotalPage];
};
