import React, { useEffect, useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Header, Navigation } from '../components';
import { Auth } from 'aws-amplify';
import { UserContext, AuthContext, MemberGroupContext } from '../contexts';
import API from '../api';
import { AdminUtil } from '../utils';
import axios from 'axios';

export const MainLayout = (props) => {
  const history = useHistory();
  const { updateUserInfo } = useContext(UserContext);
  const { updateAuth, updateAuthArray, updateServiceGroupList, updateServiceList, auth, authArray } = useContext(
    AuthContext,
  );
  const { memberGroup, updateMemberGroup } = useContext(MemberGroupContext);

  let { Component, ...rest } = props;

  useEffect(() => {
    console.log('request current Session');
    (async function () {
      try {
        const currentSession = await Auth.currentSession();
        console.log('currentSession', currentSession);
        updateUserInfo(currentSession);
        let adminUserId = currentSession.getAccessToken().payload.username;
        console.log('adminUserId', adminUserId);
        const AdminUserDetail = await API.AdminUser.GetDetail(adminUserId).then(
          (res) => {
            const result = API.Utils.ResultCheck(res);
            console.log('AdminUserDetail result', result);
            return result;
          },
          (err) => {
            console.log('AdminUserDetail err', err);
            return err;
          },
        );

        const memberGroupList = await API.MemberGroup.Get().then(
          (res) => {
            console.log('MemberGroup res', res);
            const result = API.Utils.ResultCheck(res);

            return result.list;
          },
          (err) => {
            console.log('MemberGroup err', err);
          },
        );

        const ServiceGroupList = await API.ServiceGroup.Get().then(
          (res) => {
            console.log('res', res);
            const result = API.Utils.ResultCheck(res);
            return result;
          },
          (err) => {
            console.log('err', err);
            return err;
          },
        );

        console.log('AdminUserDetail', AdminUserDetail);
        console.log('ServiceGroupList', ServiceGroupList);

        //setup initial setup

        let memberGroupMenus = [];
        console.log('memberGroupList', memberGroupList);
        for (let i in memberGroupList) {
          memberGroupMenus.push({
            value: memberGroupList[i].mbr_grp_id,
            text: memberGroupList[i].mbr_grp_id + '(' + memberGroupList[i].name + ')',
          });
        }
        console.log('memberGroupMenus', memberGroupMenus);
        updateMemberGroup({
          selected: memberGroupMenus[0].value,
          menus: memberGroupMenus,
        });

        if (AdminUserDetail.backofficePartAuth) {
          const authValue = AdminUtil.buildNavagation(AdminUserDetail.backofficePartAuth);
          console.log('authValue', authValue);
          updateAuth(authValue);
          updateAuthArray(AdminUserDetail.backofficePartAuth);
        }

        if (AdminUserDetail.serviceGroupAuth) {
          const serviceGroupValue = AdminUtil.buildServiceGroupList(
            AdminUserDetail.serviceGroupAuth.auth_list,
            ServiceGroupList.list,
          );
          console.log('serviceGroupValue', serviceGroupValue);
          updateServiceGroupList(serviceGroupValue);
        }

        if (AdminUserDetail.serviceListAuth) {
          const serviceListValue = AdminUtil.buildServiceList(AdminUserDetail.serviceListAuth);
          updateServiceList(serviceListValue);
        }
      } catch (e) {
        console.log('e', e);
        alert('로그인 정보가 없습니다. 다시 로그인을 시도해주세요.');
        history.push('/login');
      }
    })();
  }, []);

  useEffect(() => {
    let currentLocation = history.location.pathname + history.location.search;
    console.log('currentLocation', currentLocation);
    let validationLocationResult = AdminUtil.validationLocation(currentLocation, authArray, auth);
    console.log('validationLocationResult', validationLocationResult);

    if (currentLocation !== '/not_auth' && !validationLocationResult) {
      console.log('request not auth page');
      history.push('/not_auth');
    }
  });

  useEffect(() => {
    console.log('check session');
    Auth.currentSession().then(
      (res) => {
        console.log('currentSession res', res);
        const accessToken = res.accessToken.payload;
        console.log('accessToken', accessToken);
        var expUtc = accessToken.exp;
        var expDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
        expDate.setUTCSeconds(expUtc);
        console.log('expDate', expDate);

        var authUtc = accessToken.auth_time;
        var authDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
        authDate.setUTCSeconds(authUtc);
        console.log('authDate', authDate);
      },
      (err) => {
        console.log('currentSession err', err);
      },
    );
  });

  return (
    <div className="main-layout" id="main-layout">
      <article className="header__container">
        <Header></Header>
      </article>
      <article className="navigation__container">
        <Navigation></Navigation>
      </article>
      <article className="content__container" id="content__container">
        <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
      </article>
    </div>
  );
};
