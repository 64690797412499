import React, { useContext } from 'react';
import { AuthContext } from '../contexts';
import { Link, useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AdminUtil } from '../utils';

const NavigationItem = (props) => {
  const { part_type, name, url, children } = props;
  const history = useHistory();
  const { auth, authArray, selectedToolId, updateSelectedToolId } = useContext(AuthContext);
  let groupAuthType = AdminUtil.checkAuthType(props, authArray);

  if (groupAuthType) {
    return (
      <Accordion square={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <li>
            <span>
              {name}
              {/* <em>[{AdminUtil.authTypeToText(groupAuthType)}]</em> */}
            </span>
          </li>
        </AccordionSummary>
        {children.map((child_link, index) => {
          let menuAuthType = AdminUtil.checkAuthType(child_link, authArray);
          let re = new RegExp('/detail');
          let regexpResult = re.test(child_link.url);
          if (regexpResult) {
            //detail page는 네비게이션에 보여주지 않아야 함.
            return null;
          }
          if (menuAuthType) {
            let urlArray = child_link.url.split('?');
            let targetPathnam = urlArray[0];
            let targetSearch = null;
            if (urlArray[1]) {
              targetSearch = '?' + urlArray[1];
            }
            return (
              <Link
                to={{
                  pathname: targetPathnam,
                  search: targetSearch,
                  state: { pageAuthType: menuAuthType },
                }}
                // to={child_link.url}
                key={'child-link-' + index}
              >
                <AccordionDetails>
                  <li
                    className={history.location.pathname + history.location.search === child_link.url ? 'active' : ''}
                  >
                    <span>
                      {child_link.name}
                      <em>[{AdminUtil.authTypeToText(menuAuthType)}]</em>
                    </span>
                  </li>
                </AccordionDetails>
              </Link>
            );
          } else {
            return null;
          }
        })}
      </Accordion>
    );
  } else {
    return null;
  }

  // if (type === 'link') {
  //   return (
  //     <Link to={path}>
  //       <AccordionSummary>
  //         <li className={history.location.pathname === path ? 'active' : ''}>
  //           <span>{text}</span>
  //         </li>
  //       </AccordionSummary>
  //     </Link>
  //   );
  // }
  // return (
  //   <Accordion square={true}>
  //     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
  //       <li className={history.location.pathname === path ? 'active' : ''}>
  //         <span>{text}</span>
  //       </li>
  //     </AccordionSummary>
  //     {child.map((child_link, index) => {
  //       return (
  //         <Link to={child_link.path} key={'child-link-' + index}>
  //           <AccordionDetails>
  //             <li className={history.location.pathname + history.location.search === child_link.path ? 'active' : ''}>
  //               <span>{child_link.text}</span>
  //             </li>
  //           </AccordionDetails>
  //         </Link>
  //       );
  //     })}
  //   </Accordion>
  // );
};

export const Navigation = (props) => {
  const history = useHistory();
  const { navigationList } = useContext(AuthContext);
  console.log('navigationList', navigationList);

  return (
    <article className="main-navigation">
      <header>
        <div className="logo">
          <Link to="/">Shift Admin</Link>
        </div>
        <div className="version">
          <span>1.0.0</span>
        </div>
      </header>
      <PerfectScrollbar style={{ paddingTop: 20 }}>
        {navigationList &&
          navigationList.map((item, index) => {
            return <NavigationItem {...item} key={'nav-list-' + index}></NavigationItem>;
          })}
      </PerfectScrollbar>
    </article>
  );
};
