import React, { useEffect, useState, useContext } from 'react';
import queryString from 'query-string';
import API from '../../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash, BiChart, BiTable } from '../../../components';
import { useFilter, usePagination } from '../../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils, ErrorUtil } from '../../../utils';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import XLSX from 'xlsx';
import { AuthContext } from '../../../contexts';
import BigNumber from 'bignumber.js';

function getWeekNumber(d) {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  var yearStart = new Date(d.getFullYear(), 0, 1);
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return [d.getFullYear(), weekNo];
}

function weeksInYear(year) {
  var d = new Date(year, 11, 31);
  var week = getWeekNumber(d)[1];
  return (week = 1 ? 52 : week);
}

const buildtableData = (data, filter) => {
  console.log('buildtableData data', data);
  console.log('buildtableData filter', filter);
  const year = filter.dateValue ? filter.dateValue.getFullYear() : null;
  const month = filter.dateValue ? filter.dateValue.getMonth() + 1 : null;
  const klipNew = filter.klipNew; //1,0는 all 1는 신규생성 0은 링크 연결
  const filterDate = filter.dateValue;

  const lastDay = new Date(year, month, 0).getDate();
  console.log('lastDay', lastDay);
  const maxWeek = weeksInYear(year);
  console.log('maxWeek', maxWeek);
  const maxMonth = 12;
  console.log('maxMonth', maxMonth);

  const dateType = filter.dateType;

  let dateRange = [];
  if (dateType === 'day') {
    for (let i = 1; i <= lastDay; i++) {
      dateRange.push(i);
    }
  } else if (dateType === 'week') {
    for (let i = 1; i <= maxWeek; i++) {
      dateRange.push(i);
    }
  } else if (dateType === 'month') {
    for (let i = 1; i <= maxMonth; i++) {
      dateRange.push(i);
    }
  } else if (dateType === 'year') {
    let allYear = [];
    for (let i in data) {
      let targetYear = data[i].year;
      allYear.push(targetYear);
    }
    let uniqAllYear = _.uniq(allYear);
    console.log('uniqAllYear', uniqAllYear);
    dateRange = uniqAllYear;
  }
  console.log('dateRange', dateRange);

  const contentType = 'service_group';

  let contentList = [];
  if (contentType === 'service_group') {
    let uniqServiceGroupIdList = [];
    for (let i in data) {
      let targetData = data[i];
      let exsit = false;
      for (let i in uniqServiceGroupIdList) {
        if (uniqServiceGroupIdList[i].id === targetData.svc_grp_id) {
          exsit = true;
          break;
        }
      }
      if (!exsit) {
        uniqServiceGroupIdList.push({
          id: data[i].svc_grp_id,
        });
      }
    }
    console.log('uniqServiceGroupIdList', uniqServiceGroupIdList);
    contentList = uniqServiceGroupIdList;
  }
  console.log('contentList', contentList);

  //create table format by dateRange, contentList
  let returnArray = [];
  for (let i in contentList) {
    let targetContent = contentList[i];
    console.log('targetContent', targetContent);
    let tableArray = [];
    console.log('dateRange', dateRange);
    for (let j in dateRange) {
      let targetDate = dateRange[j];

      //setup default zero array
      if (dateType === 'day') {
        if (klipNew === '1,0') {
          tableArray.push({
            date: year + '-' + month + '-' + targetDate,
            total_count: 0,
            link_count: 0,
            klip_new_count: 0,
            svc_grp_id: targetContent.id,
          });
        } else if (klipNew === '1') {
          tableArray.push({
            date: year + '-' + month + '-' + targetDate,
            klip_new_count: 0,
            svc_grp_id: targetContent.id,
          });
        } else if (klipNew === '0') {
          tableArray.push({
            date: year + '-' + month + '-' + targetDate,
            link_count: 0,
            svc_grp_id: targetContent.id,
          });
        }
      } else if (dateType === 'week') {
        if (klipNew === '1,0') {
          tableArray.push({
            date: targetDate,
            total_count: 0,
            link_count: 0,
            klip_new_count: 0,
            svc_grp_id: targetContent.id,
          });
        } else if (klipNew === '1') {
          tableArray.push({
            date: targetDate,
            klip_new_count: 0,
            svc_grp_id: targetContent.id,
          });
        } else if (klipNew === '0') {
          tableArray.push({
            date: targetDate,
            link_count: 0,
            svc_grp_id: targetContent.id,
          });
        }
      } else if (dateType === 'month') {
        if (klipNew === '1,0') {
          tableArray.push({
            date: year + '-' + targetDate,
            total_count: 0,
            link_count: 0,
            klip_new_count: 0,
            svc_grp_id: targetContent.id,
          });
        } else if (klipNew === '1') {
          tableArray.push({
            date: year + '-' + targetDate,
            klip_new_count: 0,
            svc_grp_id: targetContent.id,
          });
        } else if (klipNew === '0') {
          tableArray.push({
            date: year + '-' + targetDate,
            link_count: 0,
            svc_grp_id: targetContent.id,
          });
        }
      } else if (dateType === 'year') {
        if (klipNew === '1,0') {
          tableArray.push({
            date: targetDate,
            total_count: 0,
            link_count: 0,
            klip_new_count: 0,
            svc_grp_id: targetContent.id,
          });
        } else if (klipNew === '1') {
          tableArray.push({
            date: targetDate,
            klip_new_count: 0,
            svc_grp_id: targetContent.id,
          });
        } else if (klipNew === '0') {
          tableArray.push({
            date: targetDate,
            link_count: 0,
            svc_grp_id: targetContent.id,
          });
        }
      }
      console.log('defulat tableArray', tableArray);
      if (contentType === 'service_group') {
        if (dateType === 'day') {
          console.log('data', data);
          for (let k in data) {
            //get data value by current dataRange
            let targetData = data[k];
            console.log('targetDate', targetDate);

            if (
              targetData['year'] === year &&
              targetData['month'] === month &&
              targetData['day'] === targetDate &&
              targetData['svc_grp_id'] === targetContent.id
            ) {
              let matchedData = data[k];
              console.log('matchedData', matchedData);
              if (matchedData) {
                if (matchedData['klip_new']) {
                  if (klipNew === '1,0' || klipNew === '1') {
                    tableArray[targetDate - 1].klip_new_count = matchedData['link_count'];
                  }
                } else {
                  tableArray[targetDate - 1].link_count = matchedData['link_count'];
                }
              }
            }
          }
        } else if (dateType === 'week') {
          for (let k in data) {
            let targetData = data[k];
            if (
              targetData['year'] === year &&
              targetData['week'] === targetDate &&
              targetData['svc_grp_id'] === targetContent.id
            ) {
              let matchedData = data[k];
              console.log('matchedData', matchedData);
              if (matchedData) {
                if (matchedData['klip_new']) {
                  tableArray[targetDate - 1].klip_new_count = matchedData['link_count'];
                } else {
                  tableArray[targetDate - 1].link_count = matchedData['link_count'];
                }
              }
            }
          }
        } else if (dateType === 'month') {
          for (let k in data) {
            let targetData = data[k];
            if (
              targetData['year'] === year &&
              targetData['month'] === targetDate &&
              targetData['svc_grp_id'] === targetContent.id
            ) {
              let matchedData = data[k];
              console.log('matchedData', matchedData);
              if (matchedData) {
                if (matchedData['klip_new']) {
                  tableArray[targetDate - 1].klip_new_count = matchedData['link_count'];
                } else {
                  tableArray[targetDate - 1].link_count = matchedData['link_count'];
                }
              }
            }
          }
        } else if (dateType === 'year') {
          for (let k in data) {
            let targetData = data[k];
            if (targetData['year'] === targetDate && targetData['svc_grp_id'] === targetContent.id) {
              let matchedData = data[k];
              console.log('matchedData', matchedData);

              if (matchedData) {
                for (let table_index in tableArray) {
                  let targetTableRow = tableArray[table_index];
                  console.log('targetTableRow', targetTableRow);
                  if (targetTableRow.date === matchedData.year) {
                    if (matchedData['klip_new']) {
                      tableArray[table_index].klip_new_count = matchedData['link_count'];
                    } else {
                      tableArray[table_index].link_count = matchedData['link_count'];
                    }
                  }
                }

                // if (matchedData['klip_new']) {
                //   tableArray[targetDate - 1].klip_new_count = matchedData['link_count'];
                // } else {
                //   tableArray[targetDate - 1].link_count = matchedData['link_count'];
                // }
              }
            }
          }
        }
      }
    }

    //calculate total
    for (let i in tableArray) {
      let targetRow = tableArray[i];
      targetRow.total_count = Number(targetRow.link_count) + Number(targetRow.klip_new_count);
    }
    if (contentType === 'service_group') {
      returnArray.push({
        [targetContent.id]: tableArray,
      });
    }
  }
  console.log('returnArray', returnArray);

  // calculate total
  if (returnArray.length > 1) {
    let totalArray = [];
    for (let i in dateRange) {
      let targetDateRange = dateRange[i];
      let total_total_count = 0;
      let total_klip_new_count = 0;
      let total_link_count = 0;
      console.log('targetDateRange', targetDateRange);

      for (let k in returnArray) {
        console.log('returnArray[k]', returnArray[k]);
        let contentKey = Object.keys(returnArray[k])[0];
        console.log('contentKey', contentKey);

        total_total_count = new BigNumber(total_total_count)
          .plus(new BigNumber(returnArray[k][contentKey][i].total_count))
          .toString(10);
        total_klip_new_count = new BigNumber(total_klip_new_count)
          .plus(new BigNumber(returnArray[k][contentKey][i].klip_new_count))
          .toString(10);
        total_link_count = new BigNumber(total_link_count)
          .plus(new BigNumber(returnArray[k][contentKey][i].link_count))
          .toString(10);
      }

      if (dateType === 'day') {
        totalArray.push({
          date: year + '-' + month + '-' + targetDateRange,
          total_count: total_total_count,
          link_count: total_link_count,
          klip_new_count: total_klip_new_count,
          svc_grp_id: 'all',
        });
      } else if (dateType === 'week') {
        totalArray.push({
          date: targetDateRange,
          total_count: total_total_count,
          link_count: total_link_count,
          klip_new_count: total_klip_new_count,
          svc_grp_id: 'all',
        });
      } else if (dateType === 'month') {
        totalArray.push({
          date: year + '-' + targetDateRange,
          total_count: total_total_count,
          link_count: total_link_count,
          klip_new_count: total_klip_new_count,
          svc_grp_id: 'all',
        });
      } else if (dateType === 'year') {
        totalArray.push({
          date: targetDateRange,
          total_count: total_total_count,
          link_count: total_link_count,
          klip_new_count: total_klip_new_count,
          svc_grp_id: 'all',
        });
      }
    }

    returnArray.unshift({
      total: totalArray,
    });
  }

  // check futer date set null

  if (year === new Date().getFullYear()) {
    for (let i in returnArray) {
      let targetArray = returnArray[i];
      console.log('targetArray', targetArray);
      let targetKey = Object.keys(returnArray[i])[0];
      console.log('targetKey', targetKey);
      let targetData = returnArray[i][targetKey];
      console.log('targetData', targetData);
      if (dateType === 'day') {
        if (month === new Date().getMonth() + 1) {
          let currentDay = new Date().getDate();
          console.log('currentDay', currentDay);
          console.log('lastDay', lastDay);

          for (let day = currentDay; day < lastDay; day++) {
            targetData[day]['total_count'] = null;
            targetData[day]['link_count'] = null;
            targetData[day]['klip_new_count'] = null;
          }
        }
      } else if (dateType === 'week') {
        var d = new Date();
        let currentWeek = getWeekNumber(d)[1];
        for (let week = currentWeek; week < maxWeek; week++) {
          targetData[week]['total_count'] = null;
          targetData[week]['link_count'] = null;
          targetData[week]['klip_new_count'] = null;
        }
      } else if (dateType === 'month') {
        let currentMonth = new Date().getMonth() + 1;
        console.log('currentMonth', currentMonth);
        for (let month = currentMonth; month < maxMonth; month++) {
          targetData[month]['total_count'] = null;
          targetData[month]['link_count'] = null;
          targetData[month]['klip_new_count'] = null;
        }
      }
    }
  }

  return returnArray;
};

export const BiStatisticsLinkPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tableType, setTableType] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const now = new Date();
  const { serviceGroupList } = useContext(AuthContext);
  const [lastFilter, setLastFilter] = useState(null);
  const [filter, onChangeFilter, setFilter] = useFilter([
    {
      required: true,
      id: 'date_type',
      component: 'Select',
      value: 'day',
      menus: [
        {
          text: '일',
          value: 'day',
        },
        {
          text: '주',
          value: 'week',
        },
        {
          text: '월',
          value: 'month',
        },
        {
          text: '연도',
          value: 'year',
        },
      ],
      label: '날짜 구분',
      key: 'dateType',
    },
    {
      required: true,
      id: 'date_picker',
      component: 'Date-Picker',
      value: new Date(),
      key: 'dateValue',
      dateFormat: 'yyyy-MM',
      label: '기간 선택(월)',
      showMonthYearPicker: true,
      showYearPicker: false,
    },
    {
      required: true,
      id: 'service-group-id',
      component: 'Select',
      value: serviceGroupList && serviceGroupList.length > 0 ? serviceGroupList[0].value : '',
      menus: [],
      label: '서비스 그룹 아이디',
      key: 'serviceGroupId',
    },
    {
      required: true,
      id: 'klip-new',
      component: 'Select',
      value: '1,0',
      menus: [
        {
          value: '1,0',
          text: '전체',
        },
        {
          value: '1',
          text: '신규 클립 계정',
        },
        {
          value: '0',
          text: '기존 클립 계정',
        },
      ],
      label: '신규 생성 구분',
      key: 'klipNew',
    },
  ]);

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    console.log('data effect', data);
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('filterParams', filterParams);
    if (data) {
      // setChartData(buildChartData(data, filterParams.dateType, filterParams.contentType));
      setTableData(buildtableData(data, filterParams));
      setTableType(filterParams.contentType);
    }
  }, [data]);

  const onSearch = () => {
    console.log('onSearch');
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('filterParams', filterParams);
    if (serviceGroupList.length === 0) {
      return;
    }
    setLoading(true);
    setLastFilter(filter);
    let body = {
      ...filterParams,
    };
    if (filterParams.dateType === 'year') {
    } else {
      body.year = filterParams.dateValue.getFullYear();
      body.month = filterParams.dateValue.getMonth() + 1;
    }
    API.BI.GetStatisticsLink(body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('GetStatisticsLink', result);
        setLoading(false);
        setData(result.list);
      },
      (err) => {
        setLoading(false);
        console.log('err', err);
        setData(null);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const exportDataToXlsx = () => {
    if (!tableData) {
      return;
    }
    if (tableData.length === 0) {
      return;
    }
    var wb = XLSX.utils.book_new();
    for (let i in tableData) {
      let targetData = tableData[i];
      console.log('targetData', targetData);
      let keyName = Object.keys(targetData)[0];
      let keyValue = targetData[keyName];
      console.log('keyName', keyName);
      console.log('keyValue', keyValue);

      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(keyValue), keyName);
    }
    XLSX.writeFile(wb, 'shift_link_statistics.xlsx');
  };

  console.log('[RENDER] tableData', tableData);
  console.log('[RENDER] tableData', tableData);

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>링크 기록 통계</h1>
          </div>
        </section>
      </header>
      <section>
        <Filter data={filter} updateData={onChangeFilter} onSearch={onSearch} loading={loading}></Filter>
      </section>
      <section className="tab__container">
        <Tabs
          value={tabIndex}
          onChange={(e, tabIndex) => {
            setTabIndex(tabIndex);
          }}
          indicatorColor="primary"
        >
          <Tab color="primary" label="테이블" index={0} />
          <Tab label="차트" index={1} />
          <div className="option__tab">
            <div className="option__item">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  exportDataToXlsx();
                }}
                disabled={!tableData || tableData.length === 0}
              >
                export excel
              </Button>
            </div>
          </div>
        </Tabs>
        <section className="tab__content">
          {tabIndex === 0 && <BiTable data={tableData} type={tableType} content={'link'} filter={lastFilter}></BiTable>}
          {tabIndex === 1 && <BiChart data={tableData} type={tableType} content={'link'} filter={lastFilter}></BiChart>}
        </section>
      </section>
    </article>
  );
};
