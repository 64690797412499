import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

export const Paging = (props) => {
  const { page, totalPage, onChange } = props;

  return (
    <article className="paging">
      <div className="paging__box">
        <Pagination count={totalPage} color="primary" page={page} onChange={onChange} />
      </div>
    </article>
  );
};
