import React, { useEffect, useState, useContext } from 'react';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging } from '../../components';
import { useFilter, usePagination } from '../../hooks';
import { DateUtil, FilterUtil } from '../../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { ErrorUtil, AdminUtil } from '../../utils';
import { AuthContext } from '../../contexts';
const PAGE_PER_ROW = 10;

export const ServiceListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState(null);
  const { auth, authArray, serviceGroupList } = useContext(AuthContext);

  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(1, 0);
  const [totalCount, setTotalCount] = useState(null);
  console.log('totalPage', totalPage);
  console.log('totalCount', totalCount);
  console.log('list', list);
  console.log('serviceGroupList', serviceGroupList);
  const [filter, onChangeFilter, setFilter] = useFilter([
    {
      required: true,
      id: 'service-group-id',
      component: 'Select',
      value: serviceGroupList && serviceGroupList.length > 0 ? serviceGroupList[0].value : '',
      menus: [],
      label: '서비스 그룹 아이디',
      key: 'serviceGroupId',
    },
  ]);

  useEffect(() => {
    if (filter[0].value) {
      onSearch();
    }
  }, []);

  useEffect(() => {
    if (list) {
      requestNewList((page - 1) * PAGE_PER_ROW);
    }
  }, [page]);

  const requestNewList = (pageOffset) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('filterParams', filterParams);
    if (!filterParams.serviceGroupId) {
      enqueueSnackbar('서비스 그룹 아이디를 선택해주세요.', { variant: 'warning' });
      return;
    }
    API.Service.Get({
      pageOffset: pageOffset,
      pageSize: PAGE_PER_ROW,
      ...filterParams,
    }).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        setList(result.list);
        setTotalPage(Math.ceil(result.totalCount / PAGE_PER_ROW));
        setTotalCount(result.totalCount);
      },
      (err) => {
        console.log('err', err);
        setError(true);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }
  // if (!list) {
  //   return (
  //     <article className="content-page">
  //       <Loading></Loading>
  //     </article>
  //   );
  // }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>서비스 목록</h1>
            {totalCount && <em>({totalCount})</em>}
          </div>
        </section>
        <section>
          {/* <ul className="option-list">
            <li>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push('/service/create');
                }}
              >
                서비스 생성
              </Button>
            </li>
          </ul> */}
        </section>
      </header>
      <section>
        <Filter data={filter} updateData={onChangeFilter} onSearch={onSearch}></Filter>
      </section>
      {list && (
        <>
          <TableContainer component={'div'} style={{ background: '#ffffff' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 100 }} align="center">
                    서비스 번호
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }} align="center">
                    멤버 그룹 아이디
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    서비스 그룹 아이디
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Klaytn 계정 아이디
                  </TableCell>
                  <TableCell style={{ minWidth: 200 }} align="center">
                    이름
                  </TableCell>
                  <TableCell align="center">설명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((item, index) => {
                    console.log('item', item);
                    return (
                      <TableRow
                        className="data-link-row"
                        key={'service-row-' + index}
                        onClick={() => {
                          let targetItem = AdminUtil.searchByUrl(auth, '/service/detail');
                          let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);

                          console.log('targetItem', targetItem);
                          console.log('linkAuthType', linkAuthType);
                          if (linkAuthType) {
                            history.push({
                              pathname: '/service/detail/' + item.svc_num,
                              state: { pageAuthType: linkAuthType },
                            });
                          } else {
                            enqueueSnackbar('디테일 권한이 없습니다.', { variant: 'warning' });
                          }
                        }}
                      >
                        <TableCell style={{ width: 120 }} align="center">
                          {item.svc_num}
                        </TableCell>
                        <TableCell style={{ width: 120 }} align="center">
                          {item.mbr_grp_id}
                        </TableCell>
                        <TableCell style={{ width: 120 }} align="center">
                          {item.svc_grp_id}
                        </TableCell>
                        <TableCell style={{ width: 120 }} align="center">
                          {item.accnt_id}
                        </TableCell>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <span
                              style={{
                                maxWidth: 600,
                                overflow: 'hidden',
                                display: 'block',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {item.description}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {list && list.length === 0 && (
                  <TableRow key={'service-row-empty'}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </>
      )}
    </article>
  );
};
