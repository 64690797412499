import React, { useEffect, useState } from "react";
import queryString from "query-string";
import API from "../../api";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlaytnHash,
} from "../../components";
import { useSnackbar } from "notistack";
import { ErrorUtil } from "../../utils";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import _ from "lodash";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";

var AWS = require("aws-sdk");
let creds = new AWS.Credentials({
  accessKeyId: "",
  secretAccessKey: "",
  region: "ap-northeast-2",
});
AWS.config.credentials = creds;

const ssmClient = new AWS.SSM({
  region: "ap-northeast-2",
  apiVersion: "2014-11-06",
});

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const MaintenancePage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  console.log("queryParams", queryParams);
  const [actionLogList, setActionLogList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [modal, setModal] = useState({
    open: false,
  });
  const [isRequested, setIsRequested] = useState(false);

  useEffect(() => {
    var params = {
      Name:
        process.env.REACT_APP_ENV === "production"
          ? "release_maintenance"
          : "dev_maintenance",
    };
    ssmClient.getParameter(params, function (err, data) {
      if (err) {
        console.log("err", err.stack);
        enqueueSnackbar("AWS SDK ERROR", { variant: "error" });
        setError(true);
      } else {
        console.log("data", data);
        if (IsJsonString(data.Parameter.Value)) {
          let value = JSON.parse(data.Parameter.Value);
          console.log("[AWS SDK - parameter store] value", value);
          setData(value);
        } else {
          console.log(
            "data.Parameter.Value is Not Json String",
            data.Parameter.Value
          );
          enqueueSnackbar("data.Parameter.Value is Not Json String", {
            variant: "error",
          });
          setError(true);
        }
      }
    });
  }, []);

  const handleChange = (e, type) => {
    let newData = _.cloneDeep(data);
    console.log("e.target.checked", e.target.checked);
    console.log("e.target.name", e.target.name);
    console.log("type", type);
    let newValue = 0;
    if (e.target.checked) {
      newValue = 1;
    }

    newData[type][e.target.name] = newValue;
    console.log("newData", newData);
    setData(newData);
  };

  const requestSave = () => {
    console.log("requestSave");

    var params = {
      Name:
        process.env.REACT_APP_ENV === "production"
          ? "release_maintenance"
          : "dev_maintenance",
      Value: JSON.stringify(data),
      Overwrite: true,
    };
    console.log("requestSave", params);
    // return;

    setIsRequested(true);

    ssmClient.putParameter(params, function (err, res) {
      if (err) {
        setIsRequested(false);
        setModal({
          ...modal,
          open: false,
        });
        enqueueSnackbar("점검 요청 실패", { variant: "error" });
      }
      // an error occurred
      else {
        setIsRequested(false);
        setModal({
          ...modal,
          open: false,
        });
        enqueueSnackbar("점검 요청 성공", { variant: "success" });
      }
    });
  };

  const onCloseModal = () => {
    setModal({
      ...modal,
      open: false,
    });
  };

  if (!data) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }
  console.log("data", data);
  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>시스템 점검</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            <li>
              <Button
                disabled={location.state.pageAuthType === "rw" ? false : true}
                onClick={() => {
                  setModal({
                    ...modal,
                    open: true,
                  });
                }}
                variant="contained"
                color="primary"
              >
                저장
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <article className="maintenance__container">
        <section className="guide__box">
          <header>
            <h1>점검 가이드</h1>
          </header>
          <ul>
            <li>
              <span>
                1. 점검 할 서비스의 <b>준비</b> 상태를 ON으로 저장
              </span>
            </li>
            <li>
              <span>
                2. 점검 할 서비스를 <b>재배포(cloud9)</b>한다.
              </span>
            </li>
            <li>
              <span>
                3. 점검 할 서비스의 <b>점검</b> 상태를 ON으로 저장
              </span>
            </li>
            <li>
              <span>
                4. 점검 종료 후, 점검 할 서비스의 <b>점검</b>과 <b>준비</b>{" "}
                상태를 OFF로 저장
              </span>
            </li>
            <li>
              <span>
                * Batch Job을 점검 할 경우, <b>메세지 Queue</b>가 비어있는지
                확인 후 점검
              </span>
            </li>
          </ul>
        </section>
        <ul className="service-list">
          <header>
            <h1>점검 항목 선택</h1>
          </header>
          <li>
            <header>백오피스 API</header>
            <FormControlLabel
              control={
                <Switch
                  checked={data.backoffice.ready}
                  onChange={(e) => handleChange(e, "backoffice")}
                  name="ready"
                  color="primary"
                />
              }
              label="준비"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.backoffice.stop}
                  onChange={(e) => handleChange(e, "backoffice")}
                  name="stop"
                  color="primary"
                />
              }
              label="점검"
            />
          </li>
          <li>
            <header>백엔드 API</header>
            <FormControlLabel
              control={
                <Switch
                  checked={data.backend.ready}
                  onChange={(e) => handleChange(e, "backend")}
                  name="ready"
                  color="primary"
                />
              }
              label="준비"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.backend.stop}
                  onChange={(e) => handleChange(e, "backend")}
                  name="stop"
                  color="primary"
                />
              }
              label="점검"
            />
          </li>
          <li>
            <header>유저 API</header>
            <FormControlLabel
              control={
                <Switch
                  checked={data.user.ready}
                  onChange={(e) => handleChange(e, "user")}
                  name="ready"
                  color="primary"
                />
              }
              label="준비"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.user.stop}
                  onChange={(e) => handleChange(e, "user")}
                  name="stop"
                  color="primary"
                />
              }
              label="점검"
            />
          </li>
          <li>
            <header>Batch Job</header>
            <FormControlLabel
              control={
                <Switch
                  checked={data.batch.ready}
                  onChange={(e) => handleChange(e, "batch")}
                  name="ready"
                  color="primary"
                />
              }
              label="준비"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.batch.stop}
                  onChange={(e) => handleChange(e, "batch")}
                  name="stop"
                  color="primary"
                />
              }
              label="점검"
            />
          </li>
        </ul>
        <ul className="option-list">
          <header>
            <h1>메세지 및 파라미터 설정</h1>
          </header>
          <li>
            <header>점검 메세지</header>
            <TextareaAutosize
              maxLength={200}
              style={{
                width: 420,
                padding: 10,
                marginTop: 10,
                borderColor: "rgba(0,0,0,0.23)",
              }}
              value={data.message}
              onChange={(e) => {
                console.log("e", e);
                let newData = _.cloneDeep(data);
                newData["message"] = e.target.value;
                console.log("newData", newData);
                setData(newData);
              }}
              placeholder={"점검 메세지를 입력해주세요."}
            />
          </li>
          <li>
            <header>PASS PARAMETER</header>
            <TextField
              style={{ width: 420 }}
              inputProps={{ maxLength: 20 }}
              value={data.pass}
              onChange={(e) => {
                console.log("e", e);
                let newData = _.cloneDeep(data);
                newData["pass"] = e.target.value;
                console.log("newData", newData);
                setData(newData);
              }}
              placeholder={"최대 20 글자"}
            />
          </li>
        </ul>
      </article>
      <Modal open={modal.open} onClose={onCloseModal}>
        <div className="modal-container">
          <header>
            <h1>시스템 점검 요청 확인</h1>
          </header>
          <article className="modal-body">
            <section className="content__box">
              <ul>
                <li>
                  <header>백오피스 API</header>
                  <span className="data" style={{ marginRight: 10 }}>
                    {data.backoffice.ready ? "준비 : O" : "준비 : X"}
                  </span>
                  <span className="data">
                    {data.backoffice.stop ? "점검 : O" : "점검 : X"}
                  </span>
                </li>
                <li>
                  <header>백엔드 API</header>
                  <span className="data" style={{ marginRight: 10 }}>
                    {data.backend.ready ? "준비 : O" : "준비 : X"}
                  </span>
                  <span className="data">
                    {data.backend.stop ? "점검 : O" : "점검 : X"}
                  </span>{" "}
                </li>
                <li>
                  <header>유저 API</header>
                  <span className="data" style={{ marginRight: 10 }}>
                    {data.user.ready ? "준비 : O" : "준비 : X"}
                  </span>
                  <span className="data">
                    {data.user.stop ? "점검 : O" : "점검 : X"}
                  </span>{" "}
                </li>
                <li>
                  <header>Batch Job</header>
                  <span className="data" style={{ marginRight: 10 }}>
                    {data.batch.ready ? "준비 : O" : "준비 : X"}
                  </span>
                  <span className="data">
                    {data.batch.stop ? "점검 : O" : "점검 : X"}
                  </span>{" "}
                </li>
                <li>
                  <header>점검 메세지</header>
                  <span
                    className="data"
                    style={{ whiteSpace: "pre-line", lineHeight: "26px" }}
                  >
                    {data.message}
                  </span>
                </li>
                <li>
                  <header>PASS PARAMETER</header>
                  <span className="data">{data.pass}</span>
                </li>
              </ul>
            </section>
            <section className="button__box">
              <Button
                color="default"
                variant="contained"
                onClick={onCloseModal}
                style={{ marginRight: 10 }}
              >
                취소
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={requestSave}
                disabled={isRequested}
              >
                전송
              </Button>
            </section>
          </article>
        </div>
      </Modal>
    </article>
  );
};
