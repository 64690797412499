import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'bi/';

const GetStatisticsUser = (object) => {
  const url = BASE_URL + PATH + 'statistics/user';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const GetStatisticsTransfer = (object) => {
  const url = BASE_URL + PATH + 'statistics/transfer';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const GetStatisticsLink = (object) => {
  const url = BASE_URL + PATH + 'statistics/link';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const BI = {
  GetStatisticsUser: GetStatisticsUser,
  GetStatisticsTransfer: GetStatisticsTransfer,
  GetStatisticsLink: GetStatisticsLink,
};

export default BI;
