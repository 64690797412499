import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'member-group/';

const Get = () => {
  const url = BASE_URL + PATH;
  return AxiosInstance.get(url);
};

const MemberGroup = {
  Get: Get,
};

export default MemberGroup;
