import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash, Form, ErrorLog } from '../../components';
import { useFilter, usePagination } from '../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils, ErrorUtil } from '../../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import CachedIcon from '@material-ui/icons/Cached';
import { useSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export const BulkDetailPage = (props) => {
  const params = useParams();
  const bulkSequence = params.bulkSequence;
  const [listLoading, setListLoading] = useState(false);
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(1, 0);
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [statusInfo, setStatusInfo] = useState({
    ready: 0,
    fetched: 0,
    done: 0,
    expired: 0,
    invalid: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState('all');

  useEffect(() => {
    requestNewList(0);
  }, [bulkSequence]);

  useEffect(() => {
    if (list) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (list) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  useEffect(() => {
    console.log('type', type);
    setPage(1);
    if (list) {
      requestNewList(0);
    }
  }, [type]);

  const requestNewList = (pageOffset) => {
    let params = {
      type: type,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };
    setListLoading(true);
    API.Bulk.GetDetail(bulkSequence, params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log(' GetDetail result', result);
        if (result) {
          setListLoading(false);
          setData(result.value);
          setList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
          setStatusInfo(result.statusInfo);
        }
      },
      (err) => {
        console.log('err', err);
        setListLoading(false);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  if (!data) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>벌크 상세</h1>
          </div>
        </section>
      </header>
      <Form title="기본 정보" data={data} type="detail" contentType="bulk"></Form>

      <div style={{ height: 20 }}></div>
      {list && (
        <>
          <TableHeader
            totalCount={totalCount}
            pagePerRow={pagePerRow}
            onChange={setPagePerRow}
            customInfo={
              <ul className="sub-info__list">
                <li>
                  <em style={{ marginRight: 3 }}>[</em>
                  <header>완료 :&nbsp;</header>
                  <span>{statusInfo.done}</span>
                </li>
                <li>
                  <header>실패 :&nbsp;</header>
                  <span>{statusInfo.invalid}</span>
                </li>
                <li>
                  <header>준비 :&nbsp;</header>
                  <span>{statusInfo.ready}</span>
                </li>
                <li>
                  <header>만료 :&nbsp;</header>
                  <span>{statusInfo.expired}</span>
                </li>
                <li>
                  <header>수행중 :&nbsp;</header>
                  <span>{statusInfo.fetched}</span>
                  <em style={{ marginLeft: 3 }}>]</em>
                </li>
              </ul>
            }
            customButton={
              <div style={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 10, width: 40 }}
                  onClick={() => {
                    requestNewList((page - 1) * pagePerRow);
                  }}
                >
                  <CachedIcon></CachedIcon>
                </Button>
                <FormControl style={{ width: 90, marginRight: 10 }} variant="outlined">
                  <InputLabel id={'reward-status-select-label'}>예약 상태</InputLabel>
                  <Select
                    labelId={'reward-status-select-label'}
                    label={'예약 상태'}
                    value={type}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      setType(newValue);
                    }}
                  >
                    <MenuItem value={'all'}>전체</MenuItem>
                    <MenuItem value={'done'}>완료</MenuItem>
                    <MenuItem value={'invalid'}>실패</MenuItem>
                    <MenuItem value={'ready'}>준비</MenuItem>
                    <MenuItem value={'expired'}>만료</MenuItem>
                    <MenuItem value={'fetched'}>수행중</MenuItem>
                  </Select>
                </FormControl>
              </div>
            }
          ></TableHeader>

          <TableContainer component={'div'} style={{ background: '#ffffff' }}>
            <Table className="default-table">
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell align="center">멤버 아이디</TableCell>
                  <TableCell align="center">예약 시퀀스</TableCell>
                  <TableCell align="center">예약 수량(Klay)</TableCell>
                  <TableCell align="center">예약 상태</TableCell>
                  <TableCell align="center">예약 수행일</TableCell>
                  <TableCell align="center">예약 에러 로그</TableCell>
                  <TableCell align="center">전송 시퀀스</TableCell>
                  <TableCell align="center">전송 수량(Klay)</TableCell>
                  <TableCell align="center">수수료(Klay)</TableCell>
                  <TableCell align="center">트랜잭션 상태</TableCell>
                  <TableCell align="center">작업 상태</TableCell>
                  <TableCell align="center">전송 시작</TableCell>
                  <TableCell align="center">전송 완료</TableCell>
                  <TableCell align="center">트랜잭션 해쉬</TableCell>
                  <TableCell align="center">전송 에러 로그</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((item, index) => {
                    console.log('item', item);
                    if (listLoading) {
                      return (
                        <TableRow style={{ height: 60 }}>
                          <TableCell colSpan={15}>
                            <Loading></Loading>
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return (
                      <TableRow key={'service-row-' + index} style={{ height: 60 }}>
                        <TableCell align="center">{item.mbr_id}</TableCell>
                        <TableCell align="center">{item.rwd_q_seq}</TableCell>
                        <TableCell align="center">{KlaytnUtil.pebToKlay(item.amount)}</TableCell>
                        <TableCell align="center">{item.job_status}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.job_fetched_dt)}</TableCell>
                        <TableCell align="center">
                          <ErrorLog
                            message={item.r_log_message}
                            type={item.r_log_type}
                            code={item.r_log_code}
                          ></ErrorLog>
                        </TableCell>
                        <TableCell align="center">{item.transfer_seq}</TableCell>
                        <TableCell align="center">{KlaytnUtil.pebToKlay(item.transfer_amount)}</TableCell>
                        <TableCell align="center">{KlaytnUtil.pebToKlay(item.fee)}</TableCell>
                        <TableCell align="center">{item.tx_status}</TableCell>
                        <TableCell align="center">{item.tx_job_status}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.transfer_reg_dt)}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.transfer_end_dt)}</TableCell>
                        <TableCell align="center">
                          <KlaytnHash address={item.tx_hash}></KlaytnHash>
                        </TableCell>

                        <TableCell align="center">
                          <ErrorLog
                            message={item.t_log_message}
                            type={item.t_log_type}
                            code={item.t_log_code}
                          ></ErrorLog>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {list && list.length === 0 && (
                  <TableRow key={'service-row-empty'}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </>
      )}
    </article>
  );
};
