import React, { useState, useEffect } from 'react';
import { MemberGroupContext } from '../contexts';

export const MemberGroupProvider = (props) => {
  const { children } = props;
  const [memberGroup, setMemberGorup] = useState({
    menus: [],
    selected: null,
  });

  const updateMemberGroup = (value) => {
    setMemberGorup(value);
  };
  console.log('memberGroup', memberGroup);

  return (
    <MemberGroupContext.Provider
      value={{
        memberGroup: memberGroup,
        updateMemberGroup: updateMemberGroup,
      }}
    >
      {children}
    </MemberGroupContext.Provider>
  );
};
