import React, { useEffect } from 'react';
import queryString from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Base64 } from 'js-base64';

export const FixPage = (props) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  console.log('queryParams', queryParams);
  console.log();

  const message = decodeURIComponent(queryParams.message);
  console.log('message', message);
  useEffect(() => {
    Auth.signOut({ global: true }).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err);
        alert(err.message);
      },
    );
  }, []);
  return (
    <article className="login-page">
      <div style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center', marginBottom: 20 }}>
        <h1 style={{ fontSize: 28 }}>시스템 점검</h1>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ lineHeight: '26px' }}>{message}</span>
      </div>
    </article>
  );
};
