import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export const TableHeader = (props) => {
  const { totalCount, pagePerRow, onChange, customButton, customInfo } = props;
  return (
    <article className="table-header">
      <section>
        <h1>총합 : {totalCount}</h1>
        {customInfo && <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>{customInfo}</div>}
      </section>

      <section>
        {customButton && customButton}
        <FormControl fullWidth variant="outlined">
          <InputLabel id={'table-header-label'}>줄 수</InputLabel>
          <Select
            labelId={'table-header-label'}
            value={pagePerRow}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            label="줄 수"
          >
            <MenuItem value={10}>10개</MenuItem>
            <MenuItem value={20}>20개</MenuItem>
            <MenuItem value={50}>50개</MenuItem>
          </Select>
        </FormControl>
      </section>
    </article>
  );
};
