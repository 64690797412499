const dataToParams = (data) => {
  console.log('dataToParams data', data);

  let returnValue = {};
  if (data.length > 0) {
    for (let i in data) {
      const target = data[i];
      if (target.require) {
        returnValue[target.key] = target.value;
      } else if (target.value) {
        if (!target.disabled) {
          returnValue[target.key] = target.value;
        }
      }
    }

    return returnValue;
  } else {
    return {};
  }
};

export const FilterUtil = {
  dataToParams: dataToParams,
};
