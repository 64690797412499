import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContext } from '../contexts';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogLayer = (props) => {
  const { dialog, updateDialog } = useContext(DialogContext);
  const { type, open, onConfirm, title, subTitle } = dialog;

  const closeDialog = () => {
    updateDialog({
      ...dialog,
      open: false,
    });
  };

  let rootClassName = 'dialog-layer';
  if (!open) {
    rootClassName += ' hide';
  }

  return (
    <article className={rootClassName}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <div style={{ display: 'flex', alignItems: 'cneter' }}>
            {type === 'caution' && (
              <ErrorRoundedIcon style={{ fontSize: 30, marginRight: 10 }} color="secondary"></ErrorRoundedIcon>
            )}

            {title}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{subTitle}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="default">
            취소
          </Button>
          {type === 'caution' ? (
            <Button onClick={onConfirm} color="secondary">
              진행
            </Button>
          ) : (
            <Button onClick={onConfirm} color="primary">
              진행
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </article>
  );
};
