import React, { useEffect, useState, useContext } from 'react';
import queryString from 'query-string';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash, ErrorLog } from '../../components';
import { useFilter, usePagination } from '../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from '../../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../../utils';
import moment from 'moment-timezone';
import { AuthContext } from '../../contexts';

export const KasTransferListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  console.log('queryParams', queryParams);
  const type = queryParams.type;
  console.log('type', type);

  const [transferList, setTransferList] = useState(null);
  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(1, 0);
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [typeLoading, setTypeLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  console.log('totalPage', totalPage);
  console.log('totalCount', totalCount);

  const [filter, onChangeFilter, setFilter] = useFilter([]);
  const { serviceGroupList } = useContext(AuthContext);

  useEffect(() => {
    let now = moment(new Date()).format('YYYY-MM-DDTHH:mm');
    let firstDay = new Date();
    firstDay.setDate(1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);
    firstDay.setSeconds(0);

    console.log('now', now);
    console.log('firstDay', firstDay);
    firstDay = moment(firstDay).format('YYYY-MM-DDTHH:mm');
    let filterValue = [
      {
        id: 'start-date-time',
        component: 'TextField',
        type: 'datetime-local',
        value: firstDay,
        label: '시작일',
        inputProps: {},
        InputLabelProps: {
          shrink: true,
        },
        key: 'startDate',
      },
      {
        id: 'end-date-time',
        component: 'TextField',
        type: 'datetime-local',
        value: now,
        label: '종료일',
        inputProps: {},
        InputLabelProps: {
          shrink: true,
        },
        key: 'endDate',
      },
    ];
    (async function () {
      try {
        const kasList = await API.KAS.Get({
          svc_grp_id: serviceGroupList[0].value,
        }).then(
          (res) => {
            console.log('res', res);
            const result = API.Utils.ResultCheck(res);
            if (result && result.list) {
              return result.list;
            } else {
              return null;
            }
          },
          (err) => {
            console.log('err', err);
          },
        );
        console.log('kasList', kasList);
        let kasMenus = [];
        let allItemValue = [];

        filterValue.unshift({
          required: true,
          id: 'tx-status',
          component: 'Select',
          value: 'success,submit,pending,fail,unknown',
          menus: [
            {
              value: 'success,submit,pending,fail,unknown',
              text: '전체',
            },
            {
              value: 'success',
              text: '성공',
            },
            {
              value: 'submit',
              text: '제출',
            },
            {
              value: 'pending',
              text: '보류',
            },
            {
              value: 'fail',
              text: '실패',
            },
            {
              value: 'unknown',
              text: '알수없음',
            },
          ],
          label: '트랜잭션 상태',
          key: 'txStatus',
        });

        for (let i in kasList) {
          let menuItem = {
            value: kasList[i].accnt_id,
            text: kasList[i].name + '(' + kasList[i].accnt_id + ')',
          };
          kasMenus.push(menuItem);
          allItemValue.push(kasList[i].accnt_id);
        }

        kasMenus.unshift({
          value: allItemValue.join(),
          text: '전체',
        });

        filterValue.unshift({
          required: true,
          id: 'from-accnt-id',
          component: 'Select',
          value: kasMenus[0].value,
          menus: kasMenus,
          label: '발신계정',
          key: 'fromAccountId',
        });
        setFilter(filterValue);
        console.log('kasMenus', kasMenus);
        setPage(1);
        requestNewListWithFilter(filterValue);
      } catch (err) {
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      }
    })();
  }, []);

  useEffect(() => {
    if (transferList) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (transferList) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  const requestNewListWithFilter = (newFilter) => {
    let filterParams = FilterUtil.dataToParams(newFilter);
    console.log('filterParams', filterParams);

    let params = {
      ...filterParams,
      pageOffset: 0,
      pageSize: pagePerRow,
    };

    console.log('params', params);

    setListLoading(true);
    API.KAS.TransferList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('KAS TransferList', result);
        if (result) {
          setTransferList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
        }
        setListLoading(false);
      },
      (err) => {
        setListLoading(false);
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const requestNewList = (pageOffset) => {
    console.log('requestNewList pageOffset', pageOffset);
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('filterParams', filterParams);

    let params = {
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };

    console.log('params', params);

    setListLoading(true);
    API.KAS.TransferList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('KAS TransferList', result);
        if (result) {
          setTransferList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
        }
        setListLoading(false);
      },
      (err) => {
        setListLoading(false);
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };

  if (typeLoading) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }
  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>전송 목록</h1>
          </div>
        </section>
      </header>
      <section>
        <Filter data={filter} updateData={onChangeFilter} onSearch={onSearch} loading={listLoading}></Filter>
      </section>

      {transferList && (
        <>
          <TableHeader totalCount={totalCount} pagePerRow={pagePerRow} onChange={setPagePerRow}></TableHeader>
          <TableContainer component={'div'} style={{ background: '#ffffff' }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    전송 시퀀스
                  </TableCell>
                  <TableCell align="center">발신 계정</TableCell>
                  <TableCell align="center">수신 계정</TableCell>
                  <TableCell align="center">수량(Klay)</TableCell>
                  <TableCell align="center">수수료(Klay)</TableCell>
                  <TableCell align="center">트랜잭션 상태</TableCell>
                  <TableCell align="center">전송 시작</TableCell>
                  <TableCell align="center">전송 완료</TableCell>
                  <TableCell align="center">트랜잭션 해쉬</TableCell>
                  <TableCell style={{ minWidth: 100 }} align="center">
                    메모
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transferList &&
                  transferList.map((item, index) => {
                    console.log('item', item);
                    return (
                      <TableRow key={'service-row-' + index} style={{ height: 60 }}>
                        <TableCell align="center">{item.transfer_seq}</TableCell>
                        <TableCell align="center">{item.from_accnt_id}</TableCell>
                        <TableCell align="center">
                          {item.to_accnt_id === 'unknown' ? item.to_address : item.to_accnt_id}
                        </TableCell>
                        <TableCell align="center">{KlaytnUtil.pebToKlay(item.amount)}</TableCell>
                        <TableCell align="center">{KlaytnUtil.pebToKlay(item.fee)}</TableCell>
                        <TableCell align="center">{item.tx_status}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.transfer_reg_dt)}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.transfer_end_dt)}</TableCell>
                        <TableCell align="center">
                          <KlaytnHash address={item.tx_hash}></KlaytnHash>
                        </TableCell>
                        <TableCell align="center">
                          <article className="error-log">
                            <Tooltip
                              title={
                                <div className="log-detail">
                                  <li>
                                    <p>{item.transfer_memo}</p>
                                  </li>
                                </div>
                              }
                              arrow
                            >
                              <span className="message">{item.transfer_memo}</span>
                            </Tooltip>
                          </article>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {transferList && transferList.length === 0 && (
                  <TableRow key={'service-row-empty'}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </>
      )}
    </article>
  );
};
