import Utils from './Utils';
import KAS from './kas';
import ServiceGroup from './serviceGroup';
import Service from './service';
import Statistics from './statistics';
import MemberGroup from './memberGroup';
import Transfer from './transfer';
import Reward from './reward';
import Link from './link';
import Bulk from './bulk';
import AdminUser from './adminUser';
import System from './system';
import Cache from './cache';
import BI from './bi';
import Accounting from './accounting';
import Deal from './deal';
import PayInfo from './payInfo';

const API = {
  KAS: KAS,
  ServiceGroup: ServiceGroup,
  Service: Service,
  Statistics: Statistics,
  Utils: Utils,
  MemberGroup: MemberGroup,
  Transfer: Transfer,
  Reward: Reward,
  Link: Link,
  Bulk: Bulk,
  AdminUser: AdminUser,
  System: System,
  Cache: Cache,
  BI: BI,
  Accounting: Accounting,
  Deal: Deal,
  PayInfo: PayInfo,
};

export default API;
