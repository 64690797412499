import React, { useEffect, useState, useContext } from "react";
import queryString from "query-string";
import API from "../../../api";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlaytnHash,
} from "../../../components";
import { useFilter, usePagination } from "../../../hooks";
import {
  DateUtil,
  FilterUtil,
  KlaytnUtil,
  EnumUtils,
  ErrorUtil,
} from "../../../utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import InfoIcon from "@material-ui/icons/Info";
import { useSnackbar } from "notistack";
import { MemberGroupContext, AuthContext } from "../../../contexts";
import moment from "moment-timezone";

export const BiStatisticsUserPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { memberGroup } = useContext(MemberGroupContext);
  const { serviceGroupList, serviceList } = useContext(AuthContext);
  let now = moment(new Date()).format("YYYY-MM-DDTHH:mm");
  let firstDay = new Date();
  firstDay.setDate(1);
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay = moment(firstDay).format("YYYY-MM-DDTHH:mm");
  const [filter, onChangeFilter, setFilter] = useFilter([
    {
      required: true,
      id: "transfer-type",
      component: "Select",
      value: "all",
      menus: [
        {
          text: "전체",
          value: "all",
        },
        {
          text: "리워드",
          value: "rwd",
        },
        {
          text: "지불",
          value: "pay",
        },
      ],
      label: "전송목록 타입",
      key: "transferType",
    },
    {
      required: true,
      id: "member-group-id",
      component: "Select",
      value: memberGroup.selected || "hk",
      menus: [],
      label: "멤버 그룹 아이디",
      key: "memberGroupId",
    },
    {
      required: true,
      id: "member-id",
      component: "TextField",
      value: "",
      label: "멤버 아이디",
      type: "text",
      key: "memberId",
      inputProps: {
        maxLength: 128,
      },
    },
    {
      id: "option-type",
      component: "Select",
      value: "service_group",
      menus: [
        {
          text: "서비스 그룹",
          value: "service_group",
        },
        {
          text: "서비스",
          value: "service",
        },
      ],
      label: "옵션 타입",
      key: "optionType",
    },
    {
      disabled: true,
      id: "option-service-value",
      component: "Select",
      value: serviceList && serviceList.length > 0 ? serviceList[0].value : "",
      label: "서비스 번호",
      menus: [],
      key: "optionValue",
      required: true,
    },
    {
      disabled: false,
      id: "option-service_group-value",
      component: "Select",
      value:
        serviceGroupList && serviceGroupList.length > 0
          ? serviceGroupList[0].value
          : "",
      menus: [],
      label: "서비스 그룹 아이디",
      key: "optionValue",
      required: true,
    },
    {
      id: "start-date-time",
      component: "TextField",
      type: "datetime-local",
      value: firstDay,
      label: "시작일",
      inputProps: {},
      InputLabelProps: {
        shrink: true,
      },
      key: "startDate",
    },
    {
      id: "end-date-time",
      component: "TextField",
      type: "datetime-local",
      value: now,
      label: "종료일",
      inputProps: {},
      InputLabelProps: {
        shrink: true,
      },
      key: "endDate",
    },
  ]);

  useEffect(() => {
    let userFilter = _.cloneDeep(filter);
    API.MemberGroup.Get().then(
      (res) => {
        console.log("res", res);
        const result = API.Utils.ResultCheck(res);
        let newFormProps = {
          memberGroupList: result.list,
        };
        for (let i in newFormProps.memberGroupList) {
          userFilter[1].menus.push({
            value: newFormProps.memberGroupList[i].mbr_grp_id,
            text:
              newFormProps.memberGroupList[i].mbr_grp_id +
              "(" +
              newFormProps.memberGroupList[i].name +
              ")",
          });
        }

        console.log("userFilter", userFilter);
        setFilter(userFilter);
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  const onSearch = () => {
    console.log("onSearch");
    let filterParams = FilterUtil.dataToParams(filter);
    console.log("filterParams", filterParams);
    setLoading(true);
    API.BI.GetStatisticsUser(filterParams).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("GetStatisticsUser", result);
        setLoading(false);
        setData(result.value);
      },
      (err) => {
        setLoading(false);
        console.log("err", err);
        setData(null);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
      }
    );
  };

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>유저 지급 조회</h1>
          </div>
        </section>
      </header>
      <section>
        <Filter
          data={filter}
          updateData={onChangeFilter}
          onSearch={onSearch}
          loading={loading}
        ></Filter>
      </section>
      {data && (
        <section className="statistics-summary">
          <header>
            <h1>결과</h1>
          </header>
          <ul>
            <li>
              <header>총 수량(Klay)</header>
              <div className="value">
                {KlaytnUtil.pebToKlay(
                  data.total_amount === null ? "0" : data.total_amount
                )}
              </div>
            </li>
            <li>
              <header>총 수수료(Klay)</header>
              <div className="value">
                {KlaytnUtil.pebToKlay(
                  data.total_fee === null ? "0" : data.total_fee
                )}
              </div>
            </li>
          </ul>
        </section>
      )}
    </article>
  );
};
