import React, { useEffect, useState, useContext } from 'react';
import API from '../../api';
import { Form, Error, Loading } from '../../components';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { AdminUtil, ErrorUtil } from '../../utils';
import { DialogContext, AuthContext } from '../../contexts';
export const KasCreatePage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [data, setData] = useState({
    accnt_id: '',
    name: '',
    address: '',
    svc_grp_id: '',
  });
  console.log('history', history);
  const location = useLocation();
  const { auth, authArray, serviceGroupList } = useContext(AuthContext);
  const [formProps, setFormProps] = useState(null);

  console.log('location', location);

  useEffect(() => {
    let serviceGroupIds = [];
    for (let item of serviceGroupList) {
      console.log('item', item);
      serviceGroupIds.push(item.value);
    }
    console.log('params', serviceGroupIds);
    let params = {
      serviceGroupIds: serviceGroupIds,
    };
    API.ServiceGroup.Get(params).then(
      (res) => {
        console.log('res', res);
        const result = API.Utils.ResultCheck(res);
        let newFormProps = {
          serviceGroupList: result.list,
        };
        setFormProps(newFormProps);
      },
      (err) => {
        console.log('err', err);
      },
    );
  }, []);

  const onChange = (name, value) => {
    let newData = _.cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const requestCreate = () => {
    console.log('data', data);
    let body = {
      accountId: data.accnt_id,
      name: data.name,
      serviceGroupId: data.svc_grp_id,
    };
    API.KAS.Post(body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        enqueueSnackbar('생성 성공', { variant: 'success' });
        let targetItem = AdminUtil.searchByUrl(auth, '/kas/detail/');
        let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);
        console.log('targetItem', targetItem);
        console.log('linkAuthType', linkAuthType);
        if (linkAuthType) {
          history.push({
            pathname: '/kas/detail/' + data.accnt_id,
            state: { pageAuthType: linkAuthType },
          });
        }
        // history.push('/kas/detail/' + data.accountId);
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  console.log('formProps', formProps);
  if (!formProps) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>Klaytn 계정 생성</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            <li>
              <Button
                onClick={requestCreate}
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                variant="contained"
                color="primary"
              >
                생성
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <Form
        title="기본 정보"
        data={data}
        type="create"
        contentType="kas"
        onChange={onChange}
        formProps={formProps}
      ></Form>
    </article>
  );
};
