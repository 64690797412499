import React, { useEffect, useState, useContext } from 'react';
import queryString from 'query-string';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash } from '../../components';
import { useFilter, usePagination } from '../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from '../../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import { useSnackbar } from 'notistack';
import { AdminUtil, ErrorUtil } from '../../utils';
import { DialogContext, AuthContext } from '../../contexts';

export const AdminUserListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { updateDialog } = useContext(DialogContext);
  const { auth, authArray } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);

    API.AdminUser.GetList({}).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('AdminUser GetList result', result);
        if (result) {
          setList(result.list);
        }
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        setLoading(false);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  }, []);

  const requestDelete = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    console.log('requestDelete');

    updateDialog({
      type: 'caution',
      open: true,
      title: '관리자 삭제',
      subTitle: '삭제 후에는 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
      onConfirm: () => {
        console.log('requestDelete');
        API.AdminUser.Delete(item.admin_id).then(
          (res) => {
            const result = API.Utils.ResultCheck(res);
            console.log('result', result);
            enqueueSnackbar('삭제되었습니다.', { variant: 'success' });
            updateDialog({
              type: 'info',
              open: false,
              title: '',
              subTitle: '',
            });

            API.AdminUser.GetList({}).then(
              (res) => {
                const result = API.Utils.ResultCheck(res);
                console.log('result', result);
                if (result) {
                  setList(result.list);
                }
                setLoading(false);
              },
              (err) => {
                console.log('err', err);
                setLoading(false);
                enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
              },
            );
          },
          (err) => {
            console.log('err', err);
            enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
            updateDialog({
              type: 'info',
              open: false,
              title: '',
              subTitle: '',
            });
          },
        );
      },
    });
  };

  if (loading) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>관리자 목록</h1>
          </div>
        </section>
      </header>
      <TableContainer component={'div'} style={{ background: '#ffffff' }}>
        <Table>
          <TableHead>
            <TableRow style={{ height: 60 }}>
              <TableCell align="center">관리자 아이디</TableCell>
              <TableCell align="center">이메일</TableCell>
              <TableCell align="center">이름</TableCell>
              <TableCell align="center">회사(소속)</TableCell>
              <TableCell align="center">기능</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.map((item, index) => {
                console.log('item', item);
                return (
                  <TableRow
                    className="data-link-row"
                    key={'service-row-' + index}
                    style={{ height: 60 }}
                    onClick={() => {
                      let targetItem = AdminUtil.searchByUrl(auth, '/admin-user/detail');
                      let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);

                      console.log('targetItem', targetItem);
                      console.log('linkAuthType', linkAuthType);
                      if (linkAuthType) {
                        history.push({
                          pathname: '/admin-user/detail/' + item.admin_id,
                          state: { pageAuthType: linkAuthType },
                        });
                      } else {
                        enqueueSnackbar('디테일 권한이 없습니다.', { variant: 'warning' });
                      }
                    }}
                  >
                    <TableCell align="center">{item.admin_id}</TableCell>
                    <TableCell align="center">{item.email}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.company}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) => {
                          requestDelete(e, item);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {list && list.length === 0 && (
              <TableRow key={'list-row-empty'}>
                <TableCell>데이터가 없습니다.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};
