import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Form,
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlaytnHash,
  AuthForm,
  ServiceGroupForm,
} from '../../components';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils, ErrorUtil, AdminUtil } from '../../utils';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

export const AdminUserDetailPage = (props) => {
  const params = useParams();
  console.log('params', params);
  const adminUserId = params.adminUserId;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    email: '',
    name: '',
    company: '',
  });
  const [authData, setAuthData] = useState(null);
  const [serviceGroupData, setServiceGroupData] = useState(null);

  useEffect(() => {
    API.AdminUser.GetDetail(adminUserId).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        setData(result.userInfo);
        setAuthData(AdminUtil.buildBackofficePartData(result.backofficePartAuth));
        const serviceGroupArray = result.serviceGroupAuth.auth_list.split(',');
        console.log('serviceGroupArray', serviceGroupArray);
        API.ServiceGroup.Get().then(
          (res) => {
            const s_result = API.Utils.ResultCheck(res);
            let isAllChecked = false;
            for (const initItem of serviceGroupArray) {
              if (initItem === 'all') {
                isAllChecked = true;
                break;
              }
            }

            if (isAllChecked) {
              for (let i in s_result.list) {
                s_result.list[i].checked = true;
              }
            } else {
              for (let i in s_result.list) {
                let isChecked = false;
                for (const initItem of serviceGroupArray) {
                  if (initItem === s_result.list[i].svc_grp_id) {
                    isChecked = true;
                    break;
                  }
                }
                s_result.list[i].checked = isChecked;
              }
            }

            setServiceGroupData([
              {
                accountId: null,
                apiKeyId: null,
                apiKeyValue: null,
                name: '전체',
                pendingApiKeyId: null,
                pendingApiKeyValue: null,
                serviceGroupId: 'all',
                checked: isAllChecked,
              },
              ...s_result.list,
            ]);
          },
          (err) => {
            console.log('err', err);
            enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
          },
        );
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  }, []);

  const onChangeInfo = (name, value) => {
    // let newData = _.cloneDeep(data);

    setData({ ...data, [name]: value });
  };

  const onChangeAuth = (value, target) => {
    let newAuthData = _.cloneDeep(authData);
    let parentList = AdminUtil.findParents(newAuthData, target.part_seq);

    //상위 트리값에 null을 셋팅한다.
    if (parentList.length === 0) {
      // root case
      if (value) {
        newAuthData.auth_type = value;
      } else {
        newAuthData.auth_type = null;
      }
    } else {
      // tool, group, menu
      newAuthData.auth_type = null;
      for (let i in parentList) {
        //upper tree setup null
        parentList[i].auth_type = null;
        if (Number(i) + 1 === parentList.length) {
          if (value) {
            parentList[i].auth_type = value;
          } else {
            parentList[i].auth_type = null;
          }
        }
      }
    }

    //하위 트리값에 null을 셋팅한다.
    let childItem = AdminUtil.search(newAuthData, target.part_seq);
    AdminUtil.setupChildList(childItem.children, null);
    setAuthData(newAuthData);
  };

  const onChangeServiceGroup = (event, index) => {
    let value = event.target.checked;
    // console.log('value', value);
    let newServiceGroupData = _.cloneDeep(serviceGroupData);
    if (index === 0) {
      //setup all
      for (let i in newServiceGroupData) {
        newServiceGroupData[i].checked = value;
      }
    } else {
      // false -> check list -> setup also false
      // true -> check all -> setup also true
      newServiceGroupData[index].checked = value;

      if (value) {
        let isAllChecked = true;
        for (let i = 1; i < newServiceGroupData.length; i++) {
          if (!newServiceGroupData[i].checked) {
            isAllChecked = false;
          }
        }
        if (isAllChecked) {
          newServiceGroupData[0].checked = true;
        }
      } else {
        if (newServiceGroupData[0].checked) {
          newServiceGroupData[0].checked = false;
        }
      }
    }
    setServiceGroupData(newServiceGroupData);
  };

  const requestModify = () => {
    console.log('requestModify');
    if (!data.email || !data.name || !data.company) {
      enqueueSnackbar('유저 정보를 확인해주세요.', { variant: 'error' });
      return;
    }

    const authList = AdminUtil.getAuthList(authData);
    if (authList.length === 0) {
      enqueueSnackbar('권한을 선택해주세요..', { variant: 'error' });
      return;
    }
    if (!serviceGroupData) {
      enqueueSnackbar('서비스 그룹을 선택해주세요..', { variant: 'error' });
      return;
    }

    const serviceGroupValue = AdminUtil.getServiceGroupValue(serviceGroupData);
    const body = {
      attributes: data,
      auth: authList,
      serviceGroup: serviceGroupValue,
    };
    console.log('body', body);
    API.AdminUser.Put(adminUserId, body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result) {
          enqueueSnackbar('저장 성공', { variant: 'success' });
        }
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>관리자 생성</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            <li>
              <Button
                onClick={requestModify}
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                variant="contained"
                color="primary"
              >
                수정
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <Form title="유저 정보" data={data} type="detail" contentType="admin-user" onChange={onChangeInfo}></Form>
      <div style={{ height: 20 }}></div>
      {authData && <AuthForm authData={authData} onChangeAuth={onChangeAuth}></AuthForm>}
      <div style={{ height: 20 }}></div>
      <ServiceGroupForm
        serviceGroupData={serviceGroupData}
        onChangeServiceGroup={onChangeServiceGroup}
      ></ServiceGroupForm>
    </article>
  );
};
