import Utils from '../Utils';
import { BACKEND_BASE_URL, BASE_URL, USER_BASE_URL, BACKOFFICE_BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'service/';

const clearBackoffice = () => {
  const url = BACKOFFICE_BASE_URL + 'clearCache';
  return AxiosInstance.get(url);
};

const clearUser = () => {
  const url = USER_BASE_URL + 'clearCache';
  return AxiosInstance.get(url);
};

const clearBackend = (object) => {
  const url = BACKEND_BASE_URL + 'clearCache';
  return AxiosInstance.get(url, {
    headers: {
      'x-api-key':
        process.env.REACT_APP_ENV === 'production'
          ? 'MEjkGV1tMo8nAV7oNszrw8TfVLHqpCf83DUkFMVE'
          : 'Tx1TeV2qqv4xJnrh2DTnk8zjqPZh3urs5R43Fmpt',
    },
  });
};

const Cache = {
  clearBackoffice: clearBackoffice,
  clearUser: clearUser,
  clearBackend: clearBackend,
};

export default Cache;
