import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

const isInheritItem = (authData, item) => {
  //   console.log('isInheritItem', item);
  let parentItems = findParents(authData, item.part_seq);
  //   console.log('parentItem', parentItems);
  let isInherit = false;
  //   console.log('isInheritItem parentItems', parentItems);

  if (parentItems.length === 0) {
    //root case
  } else {
    for (let i in parentItems) {
      if (parentItems[i].auth_type) {
        isInherit = parentItems[i];
      }
    }
  }
  if (isInherit) {
    return 'inherit';
  } else {
    return '';
  }
};

function findParents(node, searchForName) {
  // If current node name matches the search name, return
  // empty array which is the beginning of our parent result
  if (node.part_seq === searchForName) {
    return [];
  }

  // Otherwise, if this node has a tree field/value, recursively
  // process the nodes in this tree array
  if (Array.isArray(node.children)) {
    for (var treeNode of node.children) {
      // Recursively process treeNode. If an array result is
      // returned, then add the treeNode.name to that result
      // and return recursively
      const childResult = findParents(treeNode, searchForName);
      if (Array.isArray(childResult)) {
        return [node].concat(childResult);
      }
    }
  }
}

const isMandateItem = (child) => {
  //자신이 null이지만, child중에 값이 있으면 mandate
  if (Array.isArray(child)) {
    for (const childItem of child) {
      if (childItem.auth_type) {
        return true;
      }
    }
    for (const childItem of child) {
      if (Array.isArray(childItem.children)) {
        return isMandateItem(childItem.children);
      }
    }
  } else {
    return false;
  }
};

const RenderAuthItem = (props, onChangeAuth, authData) => {
  const [open, setOpen] = useState(false);
  const { name, part_type, part_order, children, auth_type } = props;
  let rootClassName = 'tree ' + part_type + ' ' + isInheritItem(authData, props);

  const onChangeAuthType = (e, item) => {
    // console.log('onChangeAuthType', e.target.value);
    // console.log('onChangeAuthType', item);
    onChangeAuth(e.target.value, item);
  };

  const renderName = (name) => {
    switch (name) {
      case 'root': {
        return '전역 툴 설정';
      }
      default: {
        return name;
      }
    }
  };

  const getItemStatus = (authData, props) => {
    let resultText = '';
    let isInherit = isInheritItem(authData, props);
    if (isInherit === 'inherit') {
      resultText += 'inherit';
    }

    let isMandate = false;
    if (props.auth_type === null) {
      //내 자신이 null이지만 child에 auth_type이 있는 경우, 권한을 위임한 케이스.
      let checkMandate = isMandateItem(props.children);
      if (checkMandate) {
        isMandate = true;
      }
    }

    if (isMandate) {
      if (props.part_type === 'root') {
        //root는 mandate되엇지만, UI상에 보여질때는 none으로 보여야 사람이 이해하기 쉬움.
        resultText += 'none';
      } else {
        resultText += 'mandate';
      }
    }

    if (props.auth_type) {
      resultText += 'self';
    }

    if (!resultText) {
      resultText += 'none';
    }

    //result는
    // none : 사용되지 않음 (권한이 없음)
    // mandate : 권한을 위임함
    // inherit : 값을 상속받아 사용함
    // selft : 자기 자신의 값을 사용함
    return resultText;
  };

  const renderStatusValueText = (value) => {
    switch (value) {
      case 'mandate': {
        return '권한 위임';
      }
      case 'inherit': {
        return '권한 상속';
      }
      case 'none': {
        return '권한 없음';
      }
      case 'self': {
        return '';
      }
      default: {
        return '';
      }
    }
  };

  const renderStatusGuideText = (value) => {
    switch (value) {
      case 'mandate': {
        return '하위 컴포넌트에게 권한을 위임하였습니다.';
      }
      case 'inherit': {
        return '상위 컴포넌트의 auth-type값을 상속받아 설정됩니다.';
      }
      case 'none': {
        return '사용되지 않는 컴포넌트입니다.';
      }
      case 'self': {
        return '';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div className={rootClassName}>
      <section className={'info ' + getItemStatus(authData, props)}>
        {part_type === 'tool' || part_type === 'group' ? (
          <section className="name">
            {renderName(name)}
            <div className="option-open">
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {open ? <MenuOpenIcon color="primary"></MenuOpenIcon> : <MenuIcon></MenuIcon>}
              </div>
            </div>
          </section>
        ) : (
          <section className="name">{renderName(name)}</section>
        )}
        <seciton className={'auth-type ' + getItemStatus(authData, props)}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Auth-Type</FormLabel>
            <RadioGroup
              name={'auth-type-' + part_type + '-' + name}
              value={auth_type}
              onChange={(e) => {
                onChangeAuthType(e, props);
              }}
            >
              <FormControlLabel value="rw" control={<Radio color="primary" />} label="RW" />
              <FormControlLabel value="ro" control={<Radio color="primary" />} label="RO" />
              <FormControlLabel value={null} control={<Radio color="primary" />} label={'null'} />
            </RadioGroup>
          </FormControl>
        </seciton>
        <section className="status">
          <span>{renderStatusValueText(getItemStatus(authData, props))}</span>
        </section>
        <section className="guide">
          <span>{renderStatusGuideText(getItemStatus(authData, props))}</span>
        </section>
      </section>
      {part_type === 'root' ? (
        <section className={'children open'}>
          {children &&
            children.map((child, index) => {
              return RenderAuthItem(child, onChangeAuth, authData);
            })}
        </section>
      ) : (
        <section className={open ? 'children open' : 'children close'}>
          {children &&
            children.map((child, index) => {
              return RenderAuthItem(child, onChangeAuth, authData);
            })}
        </section>
      )}
    </div>
  );
};

export const AuthForm = (props) => {
  //   console.log('authform props', props);
  const { authData, onChangeAuth } = props;

  let headerText = '권한 설정';
  //   console.log('authData', authData);

  return (
    <article className="auth-form">
      <header>
        <h1>{headerText}</h1>
      </header>
      <section className="setting__container">
        {/* <header>루트, 툴, 그룹, 메뉴 선택</header> */}
        <article className="setting-tree">{RenderAuthItem(authData, onChangeAuth, authData)}</article>
      </section>
    </article>
  );
};
