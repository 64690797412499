import React, { useEffect, useState, useContext } from "react";
import queryString from "query-string";
import API from "../../api";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlaytnHash,
  ErrorLog,
  KlipAddress,
} from "../../components";
import { useFilter, usePagination } from "../../hooks";
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from "../../utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { ErrorUtil } from "../../utils";
import { AuthContext, MemberGroupContext } from "../../contexts";
import moment from "moment-timezone";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export const DealListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { serviceGroupList, serviceList } = useContext(AuthContext);
  const [transferList, setTransferList] = useState(null);
  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(
    1,
    0
  );
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [listLoading, setListLoading] = useState(false);
  const [summary, setSummary] = useState(null);

  const [filter, onChangeFilter, setFilter] = useFilter([]);
  const { memberGroup } = useContext(MemberGroupContext);
  const [lastFilter, setLastFilter] = useState(null);
  const [modal, setModal] = useState({
    open: false,
  });
  const [isRequested, setIsRequested] = useState(false);
  const [initRequest, setInitRequest] = useState(false);

  useEffect(() => {
    let now = moment(new Date()).format("YYYY-MM-DDTHH:mm");
    let firstDay = new Date();
    firstDay.setDate(1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);
    firstDay.setSeconds(0);
    firstDay = moment(firstDay).format("YYYY-MM-DDTHH:mm");

    let userFilter = [
      {
        required: true,
        id: "service-group-id",
        component: "Select",
        value:
          serviceGroupList && serviceGroupList.length > 0
            ? serviceGroupList[0].value
            : "",
        menus: [],
        label: "서비스 그룹 아이디",
        key: "serviceGroupId",
      },
      {
        id: "start-date-time",
        component: "TextField",
        type: "datetime-local",
        value: firstDay,
        label: "시작일",
        inputProps: {},
        InputLabelProps: {
          shrink: true,
        },
        key: "startDate",
      },
      {
        id: "end-date-time",
        component: "TextField",
        type: "datetime-local",
        value: now,
        label: "종료일",
        inputProps: {},
        InputLabelProps: {
          shrink: true,
        },
        key: "endDate",
      },
      {
        required: true,
        id: "deal-type",
        component: "Select",
        value: "1,0",
        menus: [
          {
            text: "전체",
            value: "1,0",
          },
          {
            text: "승인",
            value: "0",
          },
          {
            text: "취소",
            value: "1",
          },
        ],
        label: "거래상태",
        key: "cancel",
      },
      {
        id: "option-type-deal",
        component: "Select",
        value: "option-deal-value-mall_type",
        menus: [
          {
            text: "개별 몰",
            value: "option-deal-value-mall_type",
          },
          {
            text: "고객 ID",
            value: "option-deal-value-member_id",
          },
          {
            text: "고객명",
            value: "option-deal-value-member_name",
          },
          {
            text: "거래번호",
            value: "option-deal-value-mall_order_number",
          },
          {
            text: "클립지갑주소",
            value: "option-deal-value-klip_address",
          },
        ],
        label: "옵션",
        key: "deal_optionType",
      },
      {
        disabled: false,
        id: "option-deal-value-mall_type",
        component: "TextField",
        value: "",
        label: "개별몰",
        key: "optionValue",
        inputProps: {
          maxLength: 15,
        },
      },
      {
        disabled: true,
        id: "option-deal-value-member_id",
        component: "TextField",
        value: "",
        label: "멤버 아이디",
        key: "optionValue",
        inputProps: {
          maxLength: 128,
        },
      },
      {
        disabled: true,
        id: "option-deal-value-member_name",
        component: "TextField",
        value: "",
        label: "고객명",
        key: "optionValue",
        inputProps: {
          maxLength: 20,
        },
      },
      {
        disabled: true,
        id: "option-deal-value-mall_order_number",
        component: "TextField",
        value: "",
        label: "거래번호",
        key: "optionValue",
        inputProps: {
          maxLength: 30,
        },
      },
      {
        disabled: true,
        id: "option-deal-value-klip_address",
        component: "TextField",
        value: "",
        label: "클립지갑주소",
        key: "optionValue",
        inputProps: {
          maxLength: 45,
        },
      },
    ];
    setFilter(userFilter);
    setInitRequest(true);
  }, []);
  console.log("render initRequest", initRequest);
  console.log("render filter", filter);

  useEffect(() => {
    console.log("useEffect initRequest", initRequest);
    if (initRequest) {
      onSearch();
    }
  }, [initRequest]);

  useEffect(() => {
    if (transferList) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (transferList) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  const requestNewList = (pageOffset) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log("requestNewList filterParams", filterParams);

    let params = {
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };

    console.log("requestNewList params", params);
    setListLoading(true);
    API.Deal.GetList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Deal GetList", result);
        if (result) {
          setTransferList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
          setLastFilter({
            ...filterParams,
            totalCount: result.totalCount,
          });
        }
        setListLoading(false);
      },
      (err) => {
        setListLoading(false);
        console.log("err", err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
        setLastFilter(null);
      }
    );
  };

  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };

  const rawDataToExcelData = (list) => {
    console.log("rawDataToExcelData list", list);

    const rawToExcelObject = (data) => {
      return {
        상점구분: data.mall_type,
        상점거래번호: data.mall_order_num,
        상품명: data.product_name,
        거래일시: data.transfer_reg_dt,
        원화: data.won_price,
        "거래시 시세(1klay)": data.closing_price,
        "입금 Klay": KlaytnUtil.pebToKlay(data.amount),
        거래상태: data.cancel === 0 ? "승인" : "취소",
        고객ID: data.mbr_id,
        고객명: data.mbr_name,
        취소사유: data.cancel_memo,
      };
    };
    let excelList = [];
    for (let i in list) {
      let target = list[i];
      excelList.push(rawToExcelObject(target));
    }
    return excelList;
  };

  const requestExcelDownload = () => {
    console.log("lastFitler", lastFilter);
    if (!lastFilter) {
      return;
    }
    API.Deal.GetList({
      ...lastFilter,
      pageOffset: 0,
      pageSize: lastFilter.totalCount,
    }).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Link All GetList", result);
        if (result) {
          const excelData = rawDataToExcelData(result.list);
          exportDataToXlsx(excelData);
        }
      },
      (err) => {
        console.log("err", err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
      }
    );
  };

  const exportDataToXlsx = (list) => {
    if (!list) {
      enqueueSnackbar("데이터가 없습니다.", { variant: "error" });
      return;
    }
    if (list.length === 0) {
      enqueueSnackbar("데이터가 없습니다.", { variant: "error" });
      return;
    }
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(list),
      "transfer-list"
    );
    XLSX.writeFile(wb, "shift_deal_list.xlsx");
  };

  //Modal
  const requestStatusChangeModalOpen = (item, index) => {
    let cloneItem = _.clone(item);
    setModal({
      ...modal,
      data: cloneItem,
      open: true,
    });
  };

  const requestStatusChange = () => {
    console.log("requestStatusChange", modal.data);
    let pay_info_seq = modal.data.pay_info_seq;
    let body = {
      cancel: modal.data.cancel,
      cancel_memo: modal.data.cancel_memo,
    };
    console.log("pay_info_seq", pay_info_seq);
    console.log("body", body);

    API.PayInfo.Put(pay_info_seq, body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Deal Put", result);
        setModal({
          open: false,
        });
        setTimeout(() => {
          setTransferList(null);
          requestNewList((page - 1) * pagePerRow);
        }, 1000);
      },
      (err) => {
        console.log("err", err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
        setModal({
          open: false,
        });
      }
    );
  };

  const onCloseModal = () => {
    setModal({
      ...modal,
      open: false,
    });
  };

  // if (typeLoading) {
  //   return (
  //     <article className="content-page">
  //       <Loading></Loading>
  //     </article>
  //   );
  // }
  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>거래 목록</h1>
          </div>
        </section>
        <ul className="option-list">
          <li>
            <Button
              disabled={!lastFilter}
              color="primary"
              variant="contained"
              onClick={requestExcelDownload}
            >
              <GetAppIcon style={{ marginRight: 5, fontSize: 18 }}></GetAppIcon>
              EXPORT EXCEL
            </Button>
          </li>
        </ul>
      </header>
      <section>
        <Filter
          data={filter}
          updateData={onChangeFilter}
          onSearch={onSearch}
          loading={listLoading}
        ></Filter>
      </section>

      {transferList && (
        <>
          <TableHeader
            totalCount={totalCount}
            pagePerRow={pagePerRow}
            onChange={setPagePerRow}
          ></TableHeader>
          <TableContainer component={"div"} style={{ background: "#ffffff" }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    상점구분
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    상점거래번호
                  </TableCell>
                  <TableCell style={{ minWidth: 200 }} align="center">
                    상품명
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }} align="center">
                    거래일시
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    원화
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    거래시 시세(1klay)
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    입금 Klay
                  </TableCell>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    거래상태
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    고객ID
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    고객명
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    상태변경
                  </TableCell>
                  <TableCell style={{ minWidth: 200 }} align="center">
                    취소사유
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transferList &&
                  transferList.map((item, index) => {
                    // console.log('item', item);
                    return (
                      <TableRow
                        key={"service-row-" + index}
                        style={{ height: 60 }}
                      >
                        <TableCell align="center">{item.mall_type}</TableCell>
                        <TableCell align="center">
                          {item.mall_order_num}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <span
                                style={{ fontSize: 13, lineHeight: "20px" }}
                              >
                                {item.product_name}
                              </span>
                            }
                            arrow
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  maxWidth: 200,
                                  overflow: "hidden",
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.product_name}
                              </span>
                            </div>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          {DateUtil.dbDateToYYYYMMDD(item.transfer_reg_dt)}
                        </TableCell>
                        <TableCell align="center">
                          {Number(item.won_price).toLocaleString("en")}
                        </TableCell>
                        <TableCell align="center">
                          {Number(item.closing_price).toLocaleString("en")}
                        </TableCell>
                        <TableCell align="center">
                          {KlaytnUtil.pebToKlay(item.amount)}
                        </TableCell>
                        <TableCell align="center">
                          {item.cancel === 0 ? "승인" : "취소"}
                        </TableCell>
                        <TableCell align="center">{item.mbr_id}</TableCell>
                        <TableCell align="center">{item.mbr_name}</TableCell>
                        <TableCell align="center">
                          <Button
                            disabled={
                              location.state.pageAuthType === "rw"
                                ? false
                                : true
                            }
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              requestStatusChangeModalOpen(item, index);
                            }}
                          >
                            상태변경
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <span
                                style={{ fontSize: 13, lineHeight: "20px" }}
                              >
                                {item.cancel_memo}
                              </span>
                            }
                            arrow
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  maxWidth: 200,
                                  overflow: "hidden",
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.cancel_memo}
                              </span>
                            </div>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {transferList && transferList.length === 0 && (
                  <TableRow key={"service-row-empty"}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            page={page}
            totalPage={totalPage}
            onChange={onPageChange}
          ></Paging>
        </>
      )}
      <Modal open={modal.open} onClose={onCloseModal}>
        <div className="modal-container">
          <header>
            <h1>상태 변경</h1>
          </header>
          <article className="modal-body">
            {modal.data && (
              <section className="content__box">
                <ul>
                  <li>
                    <header>상점 구분</header>
                    <span className="data">{modal.data.mall_type}</span>
                  </li>
                  <li>
                    <header>상점 거래번호</header>
                    <span className="data">{modal.data.mall_order_num}</span>
                  </li>
                  <li>
                    <header>상품명</header>
                    <span className="data">{modal.data.product_name}</span>
                  </li>
                  <li>
                    <header>거래일시</header>
                    <span className="data">{modal.data.transfer_reg_dt}</span>
                  </li>
                  <li>
                    <header>원화</header>
                    <span className="data">
                      {Number(modal.data.won_price).toLocaleString("en")}
                    </span>
                  </li>
                  <li>
                    <header>거래시 시세(1klay)</header>
                    <span className="data">
                      {Number(modal.data.closing_price).toLocaleString("en")}
                    </span>
                  </li>
                  <li>
                    <header>고객ID</header>
                    <span className="data">{modal.data.mbr_id}</span>
                  </li>
                  <li>
                    <header>고객명</header>
                    <span className="data">{modal.data.mbr_name}</span>
                  </li>
                  <li>
                    <header>거래 상태</header>
                    <div>
                      <Select
                        value={modal.data.cancel}
                        onChange={(e) => {
                          let newModal = _.clone(modal);
                          newModal.data.cancel = e.target.value;
                          setModal(newModal);
                        }}
                      >
                        <MenuItem key={"modal-menu-select-menu-Y"} value={"1"}>
                          취소
                        </MenuItem>
                        <MenuItem key={"option-check-link-menu-N"} value={"0"}>
                          승인
                        </MenuItem>
                      </Select>
                    </div>
                  </li>
                  <li>
                    <header>취소 사유</header>
                    <FormControl fullWidth variant="outlined">
                      <TextareaAutosize
                        maxLength={200}
                        style={{
                          padding: 10,
                          borderRadius: 5,
                          borderColor: "rgba(0,0,0,0.23)",
                        }}
                        value={modal.data.cancel_memo}
                        onChange={(e) => {
                          let newModal = _.clone(modal);
                          newModal.data.cancel_memo = e.target.value;
                          setModal(newModal);
                        }}
                        placeholder={"메모 - 최대 200 글자"}
                      />
                    </FormControl>
                  </li>
                </ul>
              </section>
            )}

            <section className="button__box">
              <Button
                color="default"
                variant="contained"
                onClick={onCloseModal}
                style={{ marginRight: 10 }}
              >
                취소
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={requestStatusChange}
                disabled={isRequested}
              >
                확인
              </Button>
            </section>
          </article>
        </div>
      </Modal>
    </article>
  );
};
