import React, { useEffect, useState, useContext } from 'react';
import queryString from 'query-string';
import API from '../../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash, BiChart, BiTable } from '../../../components';
import { useFilter, usePagination } from '../../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils, ErrorUtil } from '../../../utils';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import XLSX from 'xlsx';
import { AuthContext } from '../../../contexts';
import BigNumber from 'bignumber.js';

function getWeekNumber(d) {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  var yearStart = new Date(d.getFullYear(), 0, 1);
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return [d.getFullYear(), weekNo];
}

function weeksInYear(year) {
  var d = new Date(year, 11, 31);
  var week = getWeekNumber(d)[1];
  return (week = 1 ? 52 : week);
}

const buildtableData = (data, filter) => {
  console.log('buildtableData data', data);
  console.log('buildtableData filter', filter);

  const year = filter.dateValue ? filter.dateValue.getFullYear() : null;
  const month = filter.dateValue ? filter.dateValue.getMonth() + 1 : null;

  const lastDay = new Date(year, month, 0).getDate();
  console.log('lastDay', lastDay);
  const maxWeek = weeksInYear(year);
  console.log('maxWeek', maxWeek);
  const maxMonth = 12;
  console.log('maxMonth', maxMonth);

  const dateType = filter.dateType;

  let dateRange = [];
  if (dateType === 'day') {
    for (let i = 1; i <= lastDay; i++) {
      dateRange.push(i);
    }
  } else if (dateType === 'week') {
    for (let i = 1; i <= maxWeek; i++) {
      dateRange.push(i);
    }
  } else if (dateType === 'month') {
    for (let i = 1; i <= maxMonth; i++) {
      dateRange.push(i);
    }
  } else if (dateType === 'year') {
    let allYear = [];
    for (let i in data) {
      let targetYear = data[i].year;
      allYear.push(targetYear);
    }
    let uniqAllYear = _.uniq(allYear);
    console.log('uniqAllYear', uniqAllYear);
    dateRange = uniqAllYear;
  }
  console.log('dateRange', dateRange);

  const contentType = filter.contentType;

  let contentList = [];
  if (contentType === 'service') {
    let uniqServiceNameList = [];
    for (let i in data) {
      let targetData = data[i];
      let exsit = false;
      for (let i in uniqServiceNameList) {
        if (uniqServiceNameList[i].number === targetData.svc_num) {
          exsit = true;
          break;
        }
      }
      if (!exsit) {
        uniqServiceNameList.push({
          name: data[i].svc_name,
          number: data[i].svc_num,
        });
      }
    }
    console.log('uniqServiceNameList', uniqServiceNameList);
    uniqServiceNameList.sort((a, b) => {
      if (a.number > b.number) {
        return 1;
      }
      if (a.number < b.number) {
        return -1;
      }
      return 0;
    });
    contentList = uniqServiceNameList;
  } else if (contentType === 'service_group') {
    let uniqServiceGroupIdList = [];
    for (let i in data) {
      let targetData = data[i];
      let exsit = false;
      for (let i in uniqServiceGroupIdList) {
        if (uniqServiceGroupIdList[i].id === targetData.svc_grp_id) {
          exsit = true;
          break;
        }
      }
      if (!exsit) {
        uniqServiceGroupIdList.push({
          id: data[i].svc_grp_id,
        });
      }
    }
    console.log('uniqServiceGroupIdList', uniqServiceGroupIdList);
    contentList = uniqServiceGroupIdList;
  }
  console.log('contentList', contentList);

  //create table format by dateRange, contentList
  let returnArray = [];
  for (let i in contentList) {
    let targetContent = contentList[i];
    console.log('targetContent', targetContent);
    let tableArray = [];
    for (let j in dateRange) {
      let targetDate = dateRange[j];
      if (contentType === 'service') {
        if (dateType === 'day') {
          let existIndex = false;
          for (let k in data) {
            let targetData = data[k];
            if (
              targetData['year'] === year &&
              targetData['month'] === month &&
              targetData['day'] === targetDate &&
              targetData['svc_name'] === targetContent.name
            ) {
              existIndex = k;
              break;
            }
          }
          if (existIndex) {
            tableArray.push({
              date: year + '-' + month + '-' + targetDate,
              amount_sum: KlaytnUtil.pebToKlay(data[existIndex].amount_sum),
              fee_sum: KlaytnUtil.pebToKlay(data[existIndex].fee_sum),
              transfer_count: data[existIndex].transfer_count,
              svc_num: data[existIndex].svc_num,
              svc_name: data[existIndex].svc_name,
            });
          } else {
            tableArray.push({
              date: year + '-' + month + '-' + targetDate,
              amount_sum: 0,
              fee_sum: 0,
              transfer_count: 0,
              svc_num: targetContent.number,
              svc_name: targetContent.name,
            });
          }
        } else if (dateType === 'week') {
          let existIndex = false;
          for (let k in data) {
            let targetData = data[k];
            if (
              targetData['year'] === year &&
              targetData['week'] === targetDate &&
              targetData['svc_name'] === targetContent.name
            ) {
              existIndex = k;
              break;
            }
          }
          if (existIndex) {
            tableArray.push({
              date: targetDate,
              amount_sum: KlaytnUtil.pebToKlay(data[existIndex].amount_sum),
              fee_sum: KlaytnUtil.pebToKlay(data[existIndex].fee_sum),
              transfer_count: data[existIndex].transfer_count,
              svc_num: data[existIndex].svc_num,
              svc_name: data[existIndex].svc_name,
            });
          } else {
            tableArray.push({
              date: targetDate,
              amount_sum: 0,
              fee_sum: 0,
              transfer_count: 0,
              svc_num: targetContent.number,
              svc_name: targetContent.name,
            });
          }
        } else if (dateType === 'month') {
          let existIndex = false;
          for (let k in data) {
            let targetData = data[k];
            if (
              targetData['year'] === year &&
              targetData['month'] === targetDate &&
              targetData['svc_name'] === targetContent.name
            ) {
              existIndex = k;
              break;
            }
          }
          if (existIndex) {
            tableArray.push({
              date: year + '-' + targetDate,
              amount_sum: KlaytnUtil.pebToKlay(data[existIndex].amount_sum),
              fee_sum: KlaytnUtil.pebToKlay(data[existIndex].fee_sum),
              transfer_count: data[existIndex].transfer_count,
              svc_num: data[existIndex].svc_num,
              svc_name: data[existIndex].svc_name,
            });
          } else {
            tableArray.push({
              date: year + '-' + targetDate,
              amount_sum: 0,
              fee_sum: 0,
              transfer_count: 0,
              svc_num: targetContent.number,
              svc_name: targetContent.name,
            });
          }
        } else if (dateType === 'year') {
          let existIndex = false;
          for (let k in data) {
            let targetData = data[k];
            if (targetData['year'] === targetDate && targetData['svc_name'] === targetContent.name) {
              existIndex = k;
              break;
            }
          }
          if (existIndex) {
            tableArray.push({
              date: targetDate,
              amount_sum: KlaytnUtil.pebToKlay(data[existIndex].amount_sum),
              fee_sum: KlaytnUtil.pebToKlay(data[existIndex].fee_sum),
              transfer_count: data[existIndex].transfer_count,
              svc_num: data[existIndex].svc_num,
              svc_name: data[existIndex].svc_name,
            });
          } else {
            tableArray.push({
              date: targetDate,
              amount_sum: 0,
              fee_sum: 0,
              transfer_count: 0,
              svc_num: targetContent.number,
              svc_name: targetContent.name,
            });
          }
        }
      } else if (contentType === 'service_group') {
        if (dateType === 'day') {
          let existIndex = false;
          for (let k in data) {
            let targetData = data[k];
            if (
              targetData['year'] === year &&
              targetData['month'] === month &&
              targetData['day'] === targetDate &&
              targetData['svc_grp_id'] === targetContent.id
            ) {
              existIndex = k;
              break;
            }
          }
          if (existIndex) {
            tableArray.push({
              date: year + '-' + month + '-' + targetDate,
              amount_sum: KlaytnUtil.pebToKlay(data[existIndex].amount_sum),
              fee_sum: KlaytnUtil.pebToKlay(data[existIndex].fee_sum),
              transfer_count: data[existIndex].transfer_count,
              svc_grp_id: data[existIndex].svc_grp_id,
            });
          } else {
            tableArray.push({
              date: year + '-' + month + '-' + targetDate,
              amount_sum: 0,
              fee_sum: 0,
              transfer_count: 0,
              svc_grp_id: targetContent.id,
            });
          }
        } else if (dateType === 'week') {
          let existIndex = false;
          for (let k in data) {
            let targetData = data[k];
            if (
              targetData['year'] === year &&
              targetData['week'] === targetDate &&
              targetData['svc_grp_id'] === targetContent.id
            ) {
              existIndex = k;
              break;
            }
          }
          if (existIndex) {
            tableArray.push({
              date: targetDate,
              amount_sum: KlaytnUtil.pebToKlay(data[existIndex].amount_sum),
              fee_sum: KlaytnUtil.pebToKlay(data[existIndex].fee_sum),
              transfer_count: data[existIndex].transfer_count,
              svc_grp_id: data[existIndex].svc_grp_id,
            });
          } else {
            tableArray.push({
              date: targetDate,
              amount_sum: 0,
              fee_sum: 0,
              transfer_count: 0,
              svc_grp_id: targetContent.id,
            });
          }
        } else if (dateType === 'month') {
          let existIndex = false;
          for (let k in data) {
            let targetData = data[k];
            if (
              targetData['year'] === year &&
              targetData['month'] === targetDate &&
              targetData['svc_grp_id'] === targetContent.id
            ) {
              existIndex = k;
              break;
            }
          }
          if (existIndex) {
            tableArray.push({
              date: year + '-' + targetDate,
              amount_sum: KlaytnUtil.pebToKlay(data[existIndex].amount_sum),
              fee_sum: KlaytnUtil.pebToKlay(data[existIndex].fee_sum),
              transfer_count: data[existIndex].transfer_count,
              svc_grp_id: data[existIndex].svc_grp_id,
            });
          } else {
            tableArray.push({
              date: year + '-' + targetDate,
              amount_sum: 0,
              fee_sum: 0,
              transfer_count: 0,
              svc_grp_id: targetContent.id,
            });
          }
        } else if (dateType === 'year') {
          let existIndex = false;
          for (let k in data) {
            let targetData = data[k];
            if (targetData['year'] === targetDate && targetData['svc_grp_id'] === targetContent.id) {
              existIndex = k;
              break;
            }
          }
          if (existIndex) {
            tableArray.push({
              date: targetDate,
              amount_sum: KlaytnUtil.pebToKlay(data[existIndex].amount_sum),
              fee_sum: KlaytnUtil.pebToKlay(data[existIndex].fee_sum),
              transfer_count: data[existIndex].transfer_count,
              svc_grp_id: data[existIndex].svc_grp_id,
            });
          } else {
            tableArray.push({
              date: targetDate,
              amount_sum: 0,
              fee_sum: 0,
              transfer_count: 0,
              svc_grp_id: targetContent.id,
            });
          }
        }
      }
    }
    if (contentType === 'service') {
      returnArray.push({
        [targetContent.name]: tableArray,
      });
    } else if (contentType === 'service_group') {
      returnArray.push({
        [targetContent.id]: tableArray,
      });
    }
  }
  console.log('returnArray', returnArray);

  // calculate total
  if (returnArray.length > 1) {
    let totalArray = [];
    for (let i in dateRange) {
      let targetDateRange = dateRange[i];
      let total_transfer_count = 0;
      let total_amount_sum = 0;
      let total_fee_sum = 0;
      console.log('targetDateRange', targetDateRange);

      for (let k in returnArray) {
        console.log('returnArray[k]', returnArray[k]);
        let contentKey = Object.keys(returnArray[k])[0];
        console.log('contentKey', contentKey);
        total_transfer_count = new BigNumber(total_transfer_count)
          .plus(new BigNumber(returnArray[k][contentKey][i].transfer_count))
          .toString(10);
        total_amount_sum = new BigNumber(total_amount_sum)
          .plus(new BigNumber(returnArray[k][contentKey][i].amount_sum))
          .toString(10);
        total_fee_sum = new BigNumber(total_fee_sum)
          .plus(new BigNumber(returnArray[k][contentKey][i].fee_sum))
          .toString(10);
      }

      if (dateType === 'day') {
        totalArray.push({
          date: year + '-' + month + '-' + targetDateRange,
          amount_sum: total_amount_sum,
          fee_sum: total_fee_sum,
          transfer_count: total_transfer_count,
          svc_num: 'total_num',
          svc_name: 'total_name',
        });
      } else if (dateType === 'week') {
        totalArray.push({
          date: targetDateRange,
          amount_sum: total_amount_sum,
          fee_sum: total_fee_sum,
          transfer_count: total_transfer_count,
          svc_num: 'total_num',
          svc_name: 'total_name',
        });
      } else if (dateType === 'month') {
        totalArray.push({
          date: year + '-' + targetDateRange,
          amount_sum: total_amount_sum,
          fee_sum: total_fee_sum,
          transfer_count: total_transfer_count,
          svc_num: 'total_num',
          svc_name: 'total_name',
        });
      } else if (dateType === 'year') {
        totalArray.push({
          date: targetDateRange,
          amount_sum: total_amount_sum,
          fee_sum: total_fee_sum,
          transfer_count: total_transfer_count,
          svc_num: 'total_num',
          svc_name: 'total_name',
        });
      }
    }
    returnArray.unshift({
      total: totalArray,
    });
  }

  // check futer date set null
  if (year === new Date().getFullYear()) {
    for (let i in returnArray) {
      let targetArray = returnArray[i];
      console.log('targetArray', targetArray);
      let targetKey = Object.keys(returnArray[i])[0];
      console.log('targetKey', targetKey);
      let targetData = returnArray[i][targetKey];
      console.log('targetData', targetData);
      if (dateType === 'day') {
        if (month === new Date().getMonth() + 1) {
          let currentDay = new Date().getDate();
          console.log('currentDay', currentDay);
          console.log('lastDay', lastDay);

          for (let day = currentDay; day < lastDay; day++) {
            targetData[day]['amount_sum'] = null;
            targetData[day]['fee_sum'] = null;
            targetData[day]['transfer_count'] = null;
          }
        }
      } else if (dateType === 'week') {
        var d = new Date();
        let currentWeek = getWeekNumber(d)[1];
        for (let week = currentWeek; week < maxWeek; week++) {
          targetData[week]['amount_sum'] = null;
          targetData[week]['fee_sum'] = null;
          targetData[week]['transfer_count'] = null;
        }
      } else if (dateType === 'month') {
        let currentMonth = new Date().getMonth() + 1;
        console.log('currentMonth', currentMonth);
        for (let month = currentMonth; month < maxMonth; month++) {
          targetData[month]['amount_sum'] = null;
          targetData[month]['fee_sum'] = null;
          targetData[month]['transfer_count'] = null;
        }
      }
    }
  }

  return returnArray;
};

export const BiStatisticsTransferPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tableType, setTableType] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const now = new Date();
  const { serviceGroupList } = useContext(AuthContext);

  const [filter, onChangeFilter, setFilter] = useFilter([
    {
      required: true,
      id: 'transfer-type',
      component: 'Select',
      value: 'all',
      menus: [
        {
          text: '전체',
          value: 'all',
        },
        {
          text: '리워드',
          value: 'rwd',
        },
        {
          text: '지불',
          value: 'pay',
        },
      ],
      label: '전송목록 타입',
      key: 'transferType',
    },
    {
      required: true,
      id: 'content_type',
      component: 'Select',
      value: 'service_group',
      menus: [
        {
          text: '서비스 그룹',
          value: 'service_group',
        },
        {
          text: '서비스',
          value: 'service',
        },
      ],
      label: '항목 구분',
      key: 'contentType',
    },
    {
      required: true,
      id: 'date_type',
      component: 'Select',
      value: 'day',
      menus: [
        {
          text: '일',
          value: 'day',
        },
        {
          text: '주',
          value: 'week',
        },
        {
          text: '월',
          value: 'month',
        },
        {
          text: '연도',
          value: 'year',
        },
      ],
      label: '날짜 구분',
      key: 'dateType',
    },
    {
      required: true,
      id: 'date_picker',
      component: 'Date-Picker',
      value: new Date(),
      key: 'dateValue',
      dateFormat: 'yyyy-MM',
      label: '기간 선택(월)',
      showMonthYearPicker: true,
      showYearPicker: false,
      disabled: false,
    },
  ]);

  const onSearch = () => {
    console.log('onSearch');
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('filterParams', filterParams);
    if (serviceGroupList.length === 0) {
      return;
    }

    let body = {
      ...filterParams,

      serviceGroupIds: serviceGroupList[0].value,
    };
    if (filterParams.dateType === 'year') {
    } else {
      body.year = filterParams.dateValue.getFullYear();
      body.month = filterParams.dateValue.getMonth() + 1;
    }
    setLoading(true);
    API.BI.GetStatisticsTransfer(body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('GetStatisticsTransfer', result);
        setLoading(false);
        setData(result.list);
      },
      (err) => {
        setLoading(false);
        console.log('err', err);
        setData(null);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    console.log('data effect', data);
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('filterParams', filterParams);
    if (data) {
      // setChartData(buildChartData(data, filterParams.dateType, filterParams.contentType));
      setTableData(buildtableData(data, filterParams));
      setTableType(filterParams.contentType);
    }
  }, [data]);

  const exportDataToXlsx = () => {
    if (!tableData) {
      return;
    }
    if (tableData.length === 0) {
      return;
    }
    var wb = XLSX.utils.book_new();
    for (let i in tableData) {
      let targetData = tableData[i];
      console.log('targetData', targetData);
      let keyName = Object.keys(targetData)[0];
      let keyValue = targetData[keyName];
      console.log('keyName', keyName);
      console.log('keyValue', keyValue);

      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(keyValue), keyName);
    }
    XLSX.writeFile(wb, 'shift_transfer_statistics.xlsx');
  };

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>전송 기록 통계</h1>
          </div>
        </section>
      </header>
      <section>
        <Filter data={filter} updateData={onChangeFilter} onSearch={onSearch} loading={loading}></Filter>
      </section>
      <section className="tab__container">
        <Tabs
          value={tabIndex}
          onChange={(e, tabIndex) => {
            setTabIndex(tabIndex);
          }}
          indicatorColor="primary"
        >
          <Tab color="primary" label="테이블" index={0} />
          <Tab label="차트" index={1} />
          <div className="option__tab">
            <div className="option__item">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  exportDataToXlsx();
                }}
                disabled={!tableData || tableData.length === 0}
              >
                export excel
              </Button>
            </div>
          </div>
        </Tabs>
        <section className="tab__content">
          {tabIndex === 0 && <BiTable data={tableData} type={tableType} content={'transfer'}></BiTable>}
          {tabIndex === 1 && <BiChart data={tableData} type={tableType} content={'transfer'}></BiChart>}
        </section>
      </section>
    </article>
  );
};
