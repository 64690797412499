import BigNumber from 'bignumber.js';

const pebToKlay = (value) => {
  // console.log('pebIntToKlay value', value);
  if (value) {
    let pebAmount = new BigNumber(value);
    let klayUnit = new BigNumber(1e18);
    let klayAmount = pebAmount.dividedBy(klayUnit).toString(10);
    return klayAmount;
  } else {
    return null;
  }
};

const klayToComma = (value) => {
  if (value) {
    var parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  } else {
    return null;
  }
};

const TxStatusTextByStatusInt = (value) => {
  switch (value) {
    case 1:
      return '성공';
    case 2:
      return '보류';
    case 3:
      return '제출';
    case 4:
      return '실행 완료';
    case 5:
      return '실패';
    default:
      return '미확인';
  }
};
export const KlaytnUtil = {
  pebToKlay: pebToKlay,
  klayToComma: klayToComma,
  TxStatusTextByStatusInt: TxStatusTextByStatusInt,
};
