import React from 'react';
import { Route, Link } from 'react-router-dom';

export const LoginLayout = (props) => {
  let { Component, ...rest } = props;

  return (
    <div className="login-layout" id="login-layout">
      <article className="wrapper">
        <header>
          <span>Shift Admin</span>
        </header>
        <article className="content__container" id="content__container">
          <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
        </article>
      </article>
    </div>
  );
};
