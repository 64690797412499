import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FilterUtil } from '../utils';

export const BiTable = (props) => {
  const { data, type, content, filter } = props;
  console.log('BiTable data', data);
  console.log('BiTable type', type);
  console.log('BiTable content', content);
  console.log('BiTable filter', filter);
  let klipNew = '';

  // link bi에만 사용됨
  if (filter) {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('BiTable filterParams', filterParams);
    klipNew = filterParams.klipNew; //1,0는 all 1는 신규생성 0은 링크 연결
  }

  if (!data) {
    return null;
  }
  if (data.length === 0) {
    return (
      <article className="bi-table">
        <section className="table__box">
          <span>데이터가 없습니다.</span>
        </section>
      </article>
    );
  }
  if (content === 'transfer' && type === 'service') {
    return (
      <article className="bi-table">
        <section className="table__box">
          {data.map((item, index) => {
            console.log('item', item);
            console.log('index', index);

            let keyName = Object.keys(item)[0];
            let keyValue = item[keyName];
            return (
              <div className="table__item">
                <div className="table__header">
                  <span>
                    {keyName}({keyValue[0].svc_num})
                  </span>
                </div>
                <TableContainer component={'div'} style={{ background: '#ffffff' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {index === 0 && (
                          <TableCell size="small" style={{ minWidth: 80 }} align="center">
                            날짜
                          </TableCell>
                        )}
                        <TableCell size="small" style={{ minWidth: 100 }} align="center">
                          총 건수
                        </TableCell>
                        <TableCell size="small" style={{ minWidth: 100 }} align="center">
                          총 수량(Klay)
                        </TableCell>
                        <TableCell size="small" style={{ minWidth: 100 }} align="center">
                          총 수수료(Klay)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {keyValue.map((row, row_index) => {
                        return (
                          <TableRow key={'table-' + index + 'item-row-' + row_index}>
                            {index === 0 && (
                              <TableCell size="small" align="center">
                                {row.date}
                              </TableCell>
                            )}

                            <TableCell size="small" align="center">
                              {row.transfer_count === null ? (
                                <div className="empty"></div>
                              ) : (
                                Number(row.transfer_count).toLocaleString('en')
                              )}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.amount_sum === null ? (
                                <div className="empty"></div>
                              ) : Number(row.amount_sum) > 1 ? (
                                Number(row.amount_sum).toLocaleString('en')
                              ) : (
                                Number(row.amount_sum)
                              )}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.fee_sum === null ? <div className="empty"></div> : row.fee_sum}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            );
          })}
        </section>
      </article>
    );
  } else if (content === 'transfer' && type === 'service_group') {
    return (
      <article className="bi-table">
        <section className="table__box">
          {data.map((item, index) => {
            console.log('item', item);
            console.log('index', index);
            let keyName = Object.keys(item)[0];
            let keyValue = item[keyName];
            return (
              <div className="table__item">
                <div className="table__header">
                  <span>{keyName}</span>
                </div>
                <TableContainer component={'div'} style={{ background: '#ffffff' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {index === 0 && (
                          <TableCell size="small" style={{ minWidth: 80 }} align="center">
                            날짜
                          </TableCell>
                        )}
                        <TableCell size="small" style={{ minWidth: 100 }} align="center">
                          총 건수
                        </TableCell>
                        <TableCell size="small" style={{ minWidth: 100 }} align="center">
                          총 수량(Klay)
                        </TableCell>
                        <TableCell size="small" style={{ minWidth: 100 }} align="center">
                          총 수수료(Klay)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {keyValue.map((row, row_index) => {
                        return (
                          <TableRow key={'table-' + index + 'item-row-' + row_index}>
                            {index === 0 && (
                              <TableCell size="small" align="center">
                                {row.date}
                              </TableCell>
                            )}
                            <TableCell size="small" align="center">
                              {row.transfer_count === null ? (
                                <div className="empty"></div>
                              ) : (
                                Number(row.transfer_count).toLocaleString('en')
                              )}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.amount_sum === null ? (
                                <div className="empty"></div>
                              ) : Number(row.amount_sum) > 1 ? (
                                Number(row.amount_sum).toLocaleString('en')
                              ) : (
                                Number(row.amount_sum)
                              )}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.fee_sum === null ? <div className="empty"></div> : row.fee_sum}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            );
          })}
        </section>
      </article>
    );
  } else if (content === 'link') {
    return (
      <article className="bi-table">
        <section className="table__box">
          {data.map((item, index) => {
            console.log('item', item);
            console.log('index', index);
            let keyName = Object.keys(item)[0];
            let keyValue = item[keyName];
            return (
              <div className="table__item">
                <div className="table__header">
                  <span>{keyName}</span>
                </div>
                <TableContainer component={'div'} style={{ background: '#ffffff' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {index === 0 && (
                          <TableCell size="small" style={{ minWidth: 80 }} align="center">
                            날짜
                          </TableCell>
                        )}
                        {klipNew === '1,0' && (
                          <>
                            <TableCell size="small" style={{ minWidth: 100 }} align="center">
                              총 계정
                            </TableCell>
                            <TableCell size="small" style={{ minWidth: 100 }} align="center">
                              기존 클립 계정
                            </TableCell>
                            <TableCell size="small" style={{ minWidth: 100 }} align="center">
                              신규 클립 계정
                            </TableCell>
                          </>
                        )}
                        {klipNew === '1' && (
                          <>
                            <TableCell size="small" style={{ minWidth: 100 }} align="center">
                              신규 클립 계정
                            </TableCell>
                          </>
                        )}
                        {klipNew === '0' && (
                          <>
                            <TableCell size="small" style={{ minWidth: 100 }} align="center">
                              기존 클립 계정
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {keyValue.map((row, row_index) => {
                        return (
                          <TableRow key={'table-' + index + 'item-row-' + row_index}>
                            {index === 0 && (
                              <TableCell size="small" align="center">
                                {row.date}
                              </TableCell>
                            )}
                            {klipNew === '1,0' && (
                              <>
                                <TableCell size="small" align="center">
                                  {row.total_count === null ? (
                                    <div className="empty"></div>
                                  ) : (
                                    Number(row.total_count).toLocaleString('en')
                                  )}
                                </TableCell>
                                <TableCell size="small" align="center">
                                  {row.link_count === null ? (
                                    <div className="empty"></div>
                                  ) : (
                                    Number(row.link_count).toLocaleString('en')
                                  )}
                                </TableCell>
                                <TableCell size="small" align="center">
                                  {row.klip_new_count === null ? (
                                    <div className="empty"></div>
                                  ) : (
                                    Number(row.klip_new_count).toLocaleString('en')
                                  )}
                                </TableCell>
                              </>
                            )}
                            {klipNew === '1' && (
                              <>
                                <TableCell size="small" align="center">
                                  {row.klip_new_count === null ? (
                                    <div className="empty"></div>
                                  ) : (
                                    Number(row.klip_new_count).toLocaleString('en')
                                  )}
                                </TableCell>
                              </>
                            )}
                            {klipNew === '0' && (
                              <>
                                <TableCell size="small" align="center">
                                  {row.link_count === null ? (
                                    <div className="empty"></div>
                                  ) : (
                                    Number(row.link_count).toLocaleString('en')
                                  )}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            );
          })}
        </section>
      </article>
    );
  } else {
    return null;
  }
};
