import React, { useEffect, useState, useContext } from 'react';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { Error, Loading, Card } from '../../components';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../../utils';
import { AuthContext } from '../../contexts';

export const ServiceGroupListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [list, setList] = useState(null);
  const [error, setError] = useState(false);
  const { serviceGroupList } = useContext(AuthContext);

  useEffect(() => {
    console.log('list', list);
    let serviceGroupIds = [];
    for (let item of serviceGroupList) {
      if (item.text === '전체') {
        console.log('item', item);
        serviceGroupIds.push(item.value);
        break;
      }
    }
    console.log('params', serviceGroupIds);
    let params = {
      serviceGroupIds: serviceGroupIds,
    };
    API.ServiceGroup.Get(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('ServiceGroup result', result);
        setList(result.list);
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
        setError(true);
      },
    );
  }, []);
  if (error) {
    return (
      <article className="content-page service-group">
        <Error></Error>
      </article>
    );
  }
  if (!list) {
    return (
      <article className="content-page service-group">
        <Loading></Loading>
      </article>
    );
  }
  return (
    <article className="content-page service-group">
      <header>
        <section>
          <div className="title">
            <h1>서비스 그룹 목록</h1>
            {list && <em>({list.length})</em>}
          </div>
        </section>
        <section>
          {/* <ul className="option-list">
            <li>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push('/service-group/create');
                }}
              >
                서비스 그룹 생성
              </Button>
            </li>
          </ul> */}
        </section>
      </header>
      <ul className="grid__box service-group">
        {list &&
          list.map((serviceGroup, index) => {
            return (
              <Card
                path={'/service-group/detail/' + serviceGroup.svc_grp_id}
                data={serviceGroup}
                type="service-group"
                key={'service-group-list-' + index}
              ></Card>
            );
          })}
      </ul>
    </article>
  );
};
