const amplifyConfig = {
  Auth: {
    identityPoolId: 'ap-northeast-2:b4d5b335-36b5-48d6-970c-cc261184fd08',
    region: 'ap-northeast-2',
    userPoolId: process.env.REACT_APP_ENV === 'production' ? 'ap-northeast-2_6L7cvxGnb' : 'ap-northeast-2_vouGSpYU7',
    userPoolWebClientId:
      process.env.REACT_APP_ENV === 'production' ? '1gsela0ra4dkav1vhgfhnsm9dh' : '610djuhibqp26qqpet8h577odj',
  },
  Storage: {
    AWSS3: {
      bucket: 'release-shift-admin-web', //REQUIRED -  Amazon S3 bucket name
      region: 'ap-northeast-2', //OPTIONAL -  Amazon service region
    },
  },
};

export const Variables = {
  amplifyConfig: amplifyConfig,
};
