import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'reward/';

const GetList = (object) => {
  const url = BASE_URL + PATH + 'list';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const Reward = {
  GetList: GetList,
};

export default Reward;
