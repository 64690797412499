import React, { useState, useEffect } from 'react';
import { UserContext } from '../contexts';

export const UserProvider = (props) => {
  const { children } = props;
  const [userInfo, setUserInfo] = useState(null);

  const updateUserInfo = (value) => {
    setUserInfo(value);
  };

  return (
    <UserContext.Provider
      value={{
        userInfo: userInfo,
        updateUserInfo: updateUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
