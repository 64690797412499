import React, { useEffect, useState, useContext } from "react";
import queryString from "query-string";
import API from "../../api";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlaytnHash,
  ErrorLog,
  KlipAddress,
} from "../../components";
import { useFilter, usePagination } from "../../hooks";
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from "../../utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { ErrorUtil } from "../../utils";
import { AuthContext, MemberGroupContext } from "../../contexts";
import moment from "moment-timezone";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";

export const TransferListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  console.log("queryParams", queryParams);
  const type = queryParams.type;
  console.log("type", type);
  const { serviceGroupList, serviceList } = useContext(AuthContext);

  const [transferList, setTransferList] = useState(null);
  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(
    1,
    0
  );
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [typeLoading, setTypeLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [summary, setSummary] = useState(null);

  const [filter, onChangeFilter, setFilter] = useFilter([]);
  const { memberGroup } = useContext(MemberGroupContext);
  const [lastFilter, setLastFilter] = useState(null);
  const [excelLoading, setExcelLoading] = useState(false);

  useEffect(() => {
    let now = moment(new Date()).format("YYYY-MM-DDTHH:mm");
    let firstDay = new Date();
    firstDay.setDate(1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);
    firstDay.setSeconds(0);

    console.log("now", now);
    console.log("firstDay", firstDay);
    firstDay = moment(firstDay).format("YYYY-MM-DDTHH:mm");
    console.log("type change", type);
    setTypeLoading(true);
    if (type === "user") {
      let userFilter = [
        {
          required: true,
          id: "transfer-type",
          component: "Select",
          value: "all",
          menus: [
            {
              text: "전체",
              value: "all",
            },
            {
              text: "리워드",
              value: "rwd",
            },
            {
              text: "지불",
              value: "pay",
            },
          ],
          label: "전송목록 타입",
          key: "transferType",
        },
        {
          required: true,
          id: "member-group-id",
          component: "Select",
          value: memberGroup.selected || "hk",
          menus: [],
          label: "멤버 그룹 아이디",
          key: "memberGroupId",
        },
        {
          required: true,
          id: "member-id",
          component: "TextField",
          value: "",
          label: "멤버 아이디",
          type: "text",
          key: "memberId",
          inputProps: {
            maxLength: 128,
          },
        },
        {
          required: true,
          id: "klip-new",
          component: "Select",
          value: "1,0",
          menus: [
            {
              value: "1,0",
              text: "전체",
            },
            {
              value: "1",
              text: "신규 클립 계정",
            },
            {
              value: "0",
              text: "기존 클립 계정",
            },
          ],
          label: "신규 생성 구분",
          key: "klipNew",
        },
        {
          id: "start-date-time",
          component: "TextField",
          type: "datetime-local",
          value: firstDay,
          label: "시작일",
          inputProps: {},
          InputLabelProps: {
            shrink: true,
          },
          key: "startDate",
        },
        {
          id: "end-date-time",
          component: "TextField",
          type: "datetime-local",
          value: now,
          label: "종료일",
          inputProps: {},
          InputLabelProps: {
            shrink: true,
          },
          key: "endDate",
        },
        {
          id: "option-type",
          component: "Select",
          value: "service_group",
          menus: [
            {
              text: "서비스 그룹",
              value: "service_group",
            },
            {
              text: "서비스",
              value: "service",
            },
          ],
          label: "옵션 타입",
          key: "optionType",
        },
        {
          disabled: true,
          id: "option-service-value",
          component: "Select",
          value:
            serviceList && serviceList.length > 0 ? serviceList[0].value : "",
          label: "서비스 번호",
          menus: [],
          key: "optionValue",
          required: true,
        },
        {
          disabled: false,
          id: "option-service_group-value",
          component: "Select",
          value:
            serviceGroupList && serviceGroupList.length > 0
              ? serviceGroupList[0].value
              : "",
          menus: [],
          label: "서비스 그룹 아이디",
          key: "optionValue",
          required: true,
        },
      ];
      setFilter(userFilter);
      setTypeLoading(false);
      setTransferList(null);
      setSummary(null);
    }
    if (type === "service") {
      let serviceFilter = [
        {
          required: true,
          id: "transfer-type",
          component: "Select",
          value: "all",
          menus: [
            {
              text: "전체",
              value: "all",
            },
            {
              text: "리워드",
              value: "rwd",
            },
            {
              text: "지불",
              value: "pay",
            },
          ],
          label: "전송목록 타입",
          key: "transferType",
        },
        {
          required: true,
          id: "serviceNumber",
          component: "Select",
          value:
            serviceList && serviceList.length > 0 ? serviceList[0].value : "",
          menus: [],
          label: "서비스 번호",
          key: "serviceNumber",
        },
        {
          required: true,
          id: "klip-new",
          component: "Select",
          value: "1,0",
          menus: [
            {
              value: "1,0",
              text: "전체",
            },
            {
              value: "1",
              text: "신규 클립 계정",
            },
            {
              value: "0",
              text: "기존 클립 계정",
            },
          ],
          label: "신규 생성 구분",
          key: "klipNew",
        },
        {
          id: "start-date-time",
          component: "TextField",
          type: "datetime-local",
          value: firstDay,
          label: "시작일",
          inputProps: {},
          InputLabelProps: {
            shrink: true,
          },
          key: "startDate",
        },
        {
          id: "end-date-time",
          component: "TextField",
          type: "datetime-local",
          value: now,
          label: "종료일",
          inputProps: {},
          InputLabelProps: {
            shrink: true,
          },
          key: "endDate",
        },
      ];
      console.log("serviceList", serviceList);
      setFilter(serviceFilter);
      setTransferList(null);
      setTypeLoading(false);
      setPage(1);
      setSummary(null);
      requestNewListWithFilter(serviceFilter);
    }
    if (type === "service_group") {
      let serviceGroupFilter = [
        {
          required: true,
          id: "transfer-type",
          component: "Select",
          value: "all",
          menus: [
            {
              text: "전체",
              value: "all",
            },
            {
              text: "리워드",
              value: "rwd",
            },
            {
              text: "지불",
              value: "pay",
            },
          ],
          label: "전송목록 타입",
          key: "transferType",
        },
        {
          required: true,
          id: "service-group-id",
          component: "Select",
          value:
            serviceGroupList && serviceGroupList.length > 0
              ? serviceGroupList[0].value
              : "",
          menus: [],
          label: "서비스 그룹 아이디",
          key: "serviceGroupId",
        },
        {
          required: true,
          id: "klip-new",
          component: "Select",
          value: "1,0",
          menus: [
            {
              value: "1,0",
              text: "전체",
            },
            {
              value: "1",
              text: "신규 클립 계정",
            },
            {
              value: "0",
              text: "기존 클립 계정",
            },
          ],
          label: "신규 생성 구분",
          key: "klipNew",
        },
        {
          id: "start-date-time",
          component: "TextField",
          type: "datetime-local",
          value: firstDay,
          label: "시작일",
          inputProps: {},
          InputLabelProps: {
            shrink: true,
          },
          key: "startDate",
        },
        {
          id: "end-date-time",
          component: "TextField",
          type: "datetime-local",
          value: now,
          label: "종료일",
          inputProps: {},
          InputLabelProps: {
            shrink: true,
          },
          key: "endDate",
        },
      ];
      setFilter(serviceGroupFilter);
      setTypeLoading(false);
      setTransferList(null);
      setPage(1);
      requestNewListWithFilter(serviceGroupFilter);
      setSummary(null);
    }
  }, [type]);
  useEffect(() => {
    console.log("useEffect type", type);
  }, []);

  useEffect(() => {
    if (transferList) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (transferList) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  const requestNewListWithFilter = (filter) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log("filterParams", filterParams);
    let params = {
      type: type,
      ...filterParams,
      pageOffset: 0,
      pageSize: pagePerRow,
    };

    console.log("params", params);
    setListLoading(true);
    API.Transfer.GetList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Transfer GetList", result);
        if (result) {
          setTransferList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
          setSummary(result.summary);
          setLastFilter({
            type: type,
            ...filterParams,
            totalCount: result.totalCount,
          });
        }
        setListLoading(false);
      },
      (err) => {
        setListLoading(false);
        console.log("err", err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
        setLastFilter(null);
      }
    );
  };
  const requestNewList = (pageOffset) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log("filterParams", filterParams);

    let params = {
      type: type,
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };

    console.log("params", params);
    setListLoading(true);
    API.Transfer.GetList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log("Transfer GetList", result);
        if (result) {
          setTransferList(result.list);
          setTotalCount(result.totalCount);
          setSummary(result.summary);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
          setLastFilter({
            type: type,
            ...filterParams,
            totalCount: result.totalCount,
          });
        }
        setListLoading(false);
      },
      (err) => {
        setListLoading(false);
        console.log("err", err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
        setLastFilter(null);
      }
    );
  };
  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };

  const requestExcelDownload = async () => {
    console.log("lastFitler", lastFilter);
    if (!lastFilter) {
      return;
    }

    //lambda 5MB response 이상일 경우, response max size 정책에 의해 fail을 return함.
    const BASE_SIZE = 5000;
    setExcelLoading(true);

    if (lastFilter.totalCount > BASE_SIZE) {
      console.log("lastFitler", lastFilter);

      let loop_count = Math.ceil(lastFilter.totalCount / BASE_SIZE);
      let total_result = [];
      for (let i = 1; i <= loop_count; i++) {
        console.log("i", i);

        let result = await API.Transfer.GetList({
          ...lastFilter,
          pageOffset: BASE_SIZE * (i - 1),
          pageSize: BASE_SIZE,
        });
        total_result.push(...result.data.list);
      }
      console.log("total_result", total_result);
      exportDataToXlsx(total_result);
      setExcelLoading(false);
    } else {
      API.Transfer.GetList({
        ...lastFilter,
        pageOffset: 0,
        pageSize: lastFilter.totalCount,
      }).then(
        (res) => {
          const result = API.Utils.ResultCheck(res);
          console.log("Link All GetList", result);
          if (result) {
            exportDataToXlsx(result.list);
          }
          setExcelLoading(false);
        },
        (err) => {
          console.log("err", err);
          enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: "error" });
          setExcelLoading(false);
        }
      );
    }

    // API.Transfer.GetList({
    //   ...lastFilter,
    //   pageOffset: 0,
    //   pageSize: lastFilter.totalCount,
    // }).then(
    //   (res) => {
    //     const result = API.Utils.ResultCheck(res);
    //     console.log('Link All GetList', result);
    //     if (result) {
    //       exportDataToXlsx(result.list);
    //     }
    //   },
    //   (err) => {
    //     console.log('err', err);
    //     enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
    //   },
    // );
  };

  const exportDataToXlsx = (list) => {
    if (!list) {
      enqueueSnackbar("데이터가 없습니다.", { variant: "error" });
      return;
    }
    if (list.length === 0) {
      enqueueSnackbar("데이터가 없습니다.", { variant: "error" });
      return;
    }
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(list),
      "transfer-list"
    );
    XLSX.writeFile(wb, "shift_transfer_list.xlsx");
  };

  if (typeLoading) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }
  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>전송 목록</h1>
          </div>
        </section>
        <ul className="option-list">
          <li>
            <Button
              disabled={!lastFilter}
              color="primary"
              variant="contained"
              onClick={requestExcelDownload}
              disabled={excelLoading}
            >
              <GetAppIcon style={{ marginRight: 5, fontSize: 18 }}></GetAppIcon>{" "}
              {excelLoading ? "Loading..." : "EXPORT EXCEL"}
            </Button>
          </li>
        </ul>
      </header>
      <section>
        <Filter
          data={filter}
          updateData={onChangeFilter}
          onSearch={onSearch}
          loading={listLoading}
        ></Filter>
      </section>
      {summary && (
        <section className="transfer-summary">
          <header>
            <h1>요약</h1>
          </header>
          <ul>
            <li className="header">
              <div>상태</div>
              <div>건수</div>
              <div>수량</div>
            </li>
            {summary.map((item, index) => {
              return (
                <li>
                  <div>{item.tx_status}</div>
                  <div>{Number(item.sum_count).toLocaleString("en")}</div>
                  <div style={{ justifyContent: "flex-end" }}>
                    {KlaytnUtil.klayToComma(
                      KlaytnUtil.pebToKlay(item.sum_amount)
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
      )}

      {transferList && (
        <>
          <TableHeader
            totalCount={totalCount}
            pagePerRow={pagePerRow}
            onChange={setPagePerRow}
          ></TableHeader>
          <TableContainer component={"div"} style={{ background: "#ffffff" }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    전송 시퀀스
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    멤버 아이디
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    클립 주소
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    서비스 그룹 이름
                  </TableCell>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    서비스 번호
                  </TableCell>
                  <TableCell style={{ minWidth: 160 }} align="center">
                    서비스 이름
                  </TableCell>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    타입
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }} align="center">
                    수량(Klay)
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }} align="center">
                    수수료(Klay)
                  </TableCell>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    트랜잭션 상태
                  </TableCell>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    작업 상태
                  </TableCell>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    전송 시작
                  </TableCell>
                  <TableCell style={{ minWidth: 80 }} align="center">
                    전송 완료
                  </TableCell>
                  <TableCell style={{ minWidth: 200 }} align="center">
                    트랜잭션 해쉬
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }} align="center">
                    에러 로그
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transferList &&
                  transferList.map((item, index) => {
                    // console.log('item', item);
                    return (
                      <TableRow
                        key={"service-row-" + index}
                        style={{ height: 60 }}
                      >
                        <TableCell align="center">
                          {item.transfer_seq}
                        </TableCell>
                        <TableCell align="center">{item.mbr_id}</TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <KlipAddress
                              address={item.klip_address}
                            ></KlipAddress>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {item.svc_grp_name}
                        </TableCell>
                        <TableCell align="center">{item.svc_num}</TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={
                              <span
                                style={{ fontSize: 13, lineHeight: "20px" }}
                              >
                                {item.svc_desc}
                              </span>
                            }
                            arrow
                          >
                            <span>{item.svc_name}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {EnumUtils.transferType(item.type)}
                        </TableCell>
                        <TableCell align="center">
                          {KlaytnUtil.pebToKlay(item.amount)}
                        </TableCell>
                        <TableCell align="center">
                          {KlaytnUtil.pebToKlay(item.fee)}
                        </TableCell>
                        <TableCell align="center">{item.tx_status}</TableCell>
                        <TableCell align="center">{item.job_status}</TableCell>
                        <TableCell align="center">
                          {DateUtil.dbDateToYYYYMMDD(item.transfer_reg_dt)}
                        </TableCell>
                        <TableCell align="center">
                          {DateUtil.dbDateToYYYYMMDD(item.transfer_end_dt)}
                        </TableCell>
                        <TableCell align="center">
                          <KlaytnHash address={item.tx_hash}></KlaytnHash>
                        </TableCell>
                        <TableCell align="center">
                          <ErrorLog
                            message={item.log_message}
                            type={item.log_type}
                            code={item.log_code}
                          ></ErrorLog>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {transferList && transferList.length === 0 && (
                  <TableRow key={"service-row-empty"}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            page={page}
            totalPage={totalPage}
            onChange={onPageChange}
          ></Paging>
        </>
      )}
    </article>
  );
};
