import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash } from '../../components';
import { useFilter, usePagination } from '../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from '../../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../../utils';
import ReactJson from 'react-json-view';
import moment from 'moment-timezone';

export const ActionLogPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  console.log('queryParams', queryParams);
  const type = queryParams.type;
  console.log('type', type);

  const [actionLogList, setActionLogList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(1, 0);
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [listLoading, setListLoading] = useState(false);
  console.log('totalPage', totalPage);
  console.log('totalCount', totalCount);
  let now = moment(new Date()).format('YYYY-MM-DDTHH:mm');
  let firstDay = new Date();
  firstDay.setDate(1);
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay = moment(firstDay).format('YYYY-MM-DDTHH:mm');

  const [filter, onChangeFilter, setFilter] = useFilter([
    {
      required: true,
      id: 'admin-id-select',
      component: 'Select',
      value: '',
      label: '어드민 이메일',
      menus: [],
      key: 'adminId',
    },
    {
      id: 'start-date-time',
      component: 'TextField',
      type: 'datetime-local',
      value: firstDay,
      label: '시작일',
      inputProps: {},
      InputLabelProps: {
        shrink: true,
      },
      key: 'startDate',
    },
    {
      id: 'end-date-time',
      component: 'TextField',
      type: 'datetime-local',
      value: now,
      label: '종료일',
      inputProps: {},
      InputLabelProps: {
        shrink: true,
      },
      key: 'endDate',
    },
  ]);

  useEffect(() => {
    setLoading(true);

    API.AdminUser.GetList({}).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);

        if (result) {
          let filterMenus = [];
          let allValue = [];
          const adminList = result.list;

          for (let admin of adminList) {
            let menuItem = {
              value: admin.admin_id,
              text: admin.email,
            };
            filterMenus.push(menuItem);
            allValue.push(admin.admin_id);
          }
          let newFilter = _.cloneDeep(filter);
          filterMenus.unshift({
            value: allValue.join(),
            text: '전체',
          });
          newFilter[0].menus = filterMenus;
          newFilter[0].value = filterMenus[0].value;
          setFilter(newFilter);
          setPage(1);
          requestNewListWithFilter(newFilter);
        }
        setLoading(false);
      },
      (err) => {
        console.log('err', err);
        setLoading(false);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  }, []);

  useEffect(() => {
    if (actionLogList) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (actionLogList) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  const requestNewListWithFilter = (newFilter) => {
    let filterParams = FilterUtil.dataToParams(newFilter);
    console.log('filterParams', filterParams);

    let params = {
      ...filterParams,
      pageOffset: 0,
      pageSize: pagePerRow,
    };

    console.log('params', params);
    setListLoading(true);
    API.System.GetActionLogList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('GetActionLogList', result);
        if (result) {
          setActionLogList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
        }
        setListLoading(false);
      },
      (err) => {
        console.log('err', err);
        setListLoading(false);

        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const requestNewList = (pageOffset) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('filterParams', filterParams);

    let params = {
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };

    console.log('params', params);
    setListLoading(true);
    API.System.GetActionLogList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('GetActionLogList', result);
        if (result) {
          setActionLogList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
        }
        setListLoading(false);
      },
      (err) => {
        console.log('err', err);
        setListLoading(false);

        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  if (loading) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>액션로그 목록</h1>
          </div>
        </section>
      </header>
      <section>
        <Filter data={filter} updateData={onChangeFilter} onSearch={onSearch} loading={listLoading}></Filter>
      </section>

      {actionLogList && (
        <>
          <TableHeader totalCount={totalCount} pagePerRow={pagePerRow} onChange={setPagePerRow}></TableHeader>
          <TableContainer component={'div'} style={{ background: '#ffffff' }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell style={{ minWidth: 50 }} align="center">
                    번호
                  </TableCell>
                  <TableCell align="center">어드민 아이디</TableCell>
                  <TableCell align="center">이메일</TableCell>
                  <TableCell style={{ minWidth: 50 }} align="center">
                    소속
                  </TableCell>
                  <TableCell align="center">등록일</TableCell>
                  <TableCell align="center">메서드</TableCell>
                  <TableCell align="center">경로</TableCell>
                  <TableCell style={{ minWidth: 500 }} align="center">
                    데이터
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actionLogList &&
                  actionLogList.map((item, index) => {
                    return (
                      <TableRow key={'service-row-' + index} style={{ height: 60 }}>
                        <TableCell align="center">{item.log_seq}</TableCell>
                        <TableCell align="center">{item.admin_id}</TableCell>
                        <TableCell align="center">{item.email}</TableCell>
                        <TableCell align="center">{item.company}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.log_dt)}</TableCell>
                        <TableCell align="center">{item.method}</TableCell>
                        <TableCell align="center">{item.path}</TableCell>
                        <TableCell align="left">
                          {IsJsonString(item.data) ? (
                            <ReactJson collapsed={true} src={JSON.parse(item.data)} />
                          ) : (
                            <span>Data Not JsonString Check DB</span>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {actionLogList && actionLogList.length === 0 && (
                  <TableRow key={'service-row-empty'}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </>
      )}
    </article>
  );
};
