import { useState, useCallback, useContext } from 'react';
import { MemberGroupContext } from '../contexts';
import _ from 'lodash';

export const useFilter = (initialData) => {
  const [filter, setFiler] = useState(initialData);
  const { memberGroup, updateMemberGroup } = useContext(MemberGroupContext);
  // change
  const onChange = (e, index) => {
    console.log('onChange', e);
    console.log('e.target.value', e.target.value);
    console.log('index', index);

    let newFilter = _.cloneDeep(filter);
    const target = newFilter[index];
    console.log('target', target);

    target.value = e.target.value;

    if (target.id === 'member-group-id') {
      updateMemberGroup({
        ...memberGroup,
        selected: e.target.value,
      });
      setFiler(newFilter);
      return;
    }

    if (target.key === 'optionType') {
      let optionValueIndex = [];
      for (let i in newFilter) {
        if (newFilter[i].key === 'optionValue') {
          optionValueIndex.push(i);
        }
      }
      console.log('optionValueIndex', optionValueIndex);
      if (e.target.value === 'none') {
        for (let i in optionValueIndex) {
          newFilter[optionValueIndex[i]].disabled = true;
          newFilter[optionValueIndex[i]].required = false;
          newFilter[optionValueIndex[i]].value = '';
        }
      } else {
        if (e.target.value === 'service') {
          for (let i in optionValueIndex) {
            if (newFilter[optionValueIndex[i]].id === 'option-service-value') {
              newFilter[optionValueIndex[i]].disabled = false;
              newFilter[optionValueIndex[i]].required = true;
            } else {
              newFilter[optionValueIndex[i]].disabled = true;
              newFilter[optionValueIndex[i]].required = false;
              newFilter[optionValueIndex[i]].value = '';
            }
          }
        } else if (e.target.value === 'service_group') {
          for (let i in optionValueIndex) {
            if (newFilter[optionValueIndex[i]].id === 'option-service_group-value') {
              newFilter[optionValueIndex[i]].disabled = false;
              newFilter[optionValueIndex[i]].required = true;
            } else {
              newFilter[optionValueIndex[i]].disabled = true;
              newFilter[optionValueIndex[i]].required = false;
              newFilter[optionValueIndex[i]].value = '';
            }
          }
        } else if (e.target.value === 'email') {
          for (let i in optionValueIndex) {
            if (newFilter[optionValueIndex[i]].id === 'option-email-value') {
              newFilter[optionValueIndex[i]].disabled = false;
              newFilter[optionValueIndex[i]].required = true;
            } else {
              newFilter[optionValueIndex[i]].disabled = true;
              newFilter[optionValueIndex[i]].required = false;
              newFilter[optionValueIndex[i]].value = '';
            }
          }
        }
      }
    }

    if (target.key === 'dateType') {
      let dateValueIndex = null;
      for (let i in newFilter) {
        if (newFilter[i].key === 'dateValue') {
          dateValueIndex = i;
        }
      }
      console.log('dateValueIndex', dateValueIndex);

      if (target.value === 'week' || target.value === 'month') {
        newFilter[dateValueIndex].dateFormat = 'yyyy';
        newFilter[dateValueIndex].showMonthYearPicker = false;
        newFilter[dateValueIndex].showYearPicker = true;
        newFilter[dateValueIndex].label = '기간 선택(년)';
        newFilter[dateValueIndex].disabled = false;
      } else if (target.value === 'day') {
        newFilter[dateValueIndex].dateFormat = 'yyyy-MM';
        newFilter[dateValueIndex].showMonthYearPicker = true;
        newFilter[dateValueIndex].showYearPicker = false;
        newFilter[dateValueIndex].label = '기간 선택(월)';
        newFilter[dateValueIndex].disabled = false;
      } else {
        newFilter[dateValueIndex].disabled = true;
      }
    }
    console.log('newFilter', newFilter);

    if (target.key === 'accounting_dateType') {
      let dateValueIndex = null;
      for (let i in newFilter) {
        if (newFilter[i].key === 'dateValue') {
          dateValueIndex = i;
        }
      }
      if (target.value === 'day') {
        newFilter[dateValueIndex].dateFormat = 'yyyy-MM-dd';
        newFilter[dateValueIndex].showMonthYearPicker = false;
        newFilter[dateValueIndex].showYearPicker = false;
        newFilter[dateValueIndex].label = '기간 선택(알)';
        newFilter[dateValueIndex].disabled = false;
      } else if (target.value === 'month') {
        newFilter[dateValueIndex].dateFormat = 'yyyy-MM';
        newFilter[dateValueIndex].showMonthYearPicker = true;
        newFilter[dateValueIndex].showYearPicker = false;
        newFilter[dateValueIndex].label = '기간 선택(월)';
        newFilter[dateValueIndex].disabled = false;
      } else if (target.value === 'year') {
        newFilter[dateValueIndex].dateFormat = 'yyyy';
        newFilter[dateValueIndex].showMonthYearPicker = false;
        newFilter[dateValueIndex].showYearPicker = true;
        newFilter[dateValueIndex].label = '기간 선택(년)';
        newFilter[dateValueIndex].disabled = false;
      } else {
        newFilter[dateValueIndex].disabled = true;
      }

      console.log('dateValueIndex', dateValueIndex);
    }

    if (target.key === 'deal_optionType') {
      console.log('deal_optionType', newFilter);
      for (let i in newFilter) {
        if (Number(i) > 4) {
          let targetFilter = newFilter[i];
          let targetId = targetFilter.id;
          let updateValue = e.target.value;
          if (targetId === updateValue) {
            targetFilter.disabled = false;
          } else {
            targetFilter.disabled = true;
          }
        }
      }
    }
    setFiler(newFilter);
  };

  return [filter, onChange, setFiler];
};
