import _ from 'lodash';

const buildBackofficePartData = (list) => {
  // backoffice_part array to object children

  let rootItem = null;
  for (let item of list) {
    if (item.part_type === 'root') {
      if (!item.auth_type) {
        item.auth_type = null;
      }
      rootItem = item;
      rootItem.children = [];
    }
    if (item.part_type === 'tool') {
      if (!item.auth_type) {
        item.auth_type = null;
      }
      item.children = [];
      rootItem.children.push(item);
    }
    if (item.part_type === 'group') {
      for (let toolItem of rootItem.children) {
        if (toolItem.part_seq === item.parent_part_seq) {
          if (!item.auth_type) {
            item.auth_type = null;
          }
          item.children = [];
          toolItem.children.push(item);
        }
      }
    }
    if (item.part_type === 'menu') {
      for (let toolItem of rootItem.children) {
        for (let groupItem of toolItem.children) {
          if (groupItem.part_seq === item.parent_part_seq) {
            if (!item.auth_type) {
              item.auth_type = null;
            }
            groupItem.children.push(item);
          }
        }
      }
    }
  }

  console.log('rootItem', rootItem);
  return rootItem;
};

const buildNavagation = (list) => {
  // backoffice_part array to object children

  let navigationList = buildBackofficePartData(list);
  return navigationList;
};

const getChildPath = (partSeq, list) => {
  let returnArray = [];

  for (let item of list) {
    if (item.parent_part_seq === partSeq) {
      let newItem = _.cloneDeep(item);
      newItem.children = getChildPath(newItem.part_seq, list);
      returnArray.push(newItem);
    }
  }
  return returnArray;
};

const getParentPath = (parentPartSeq, list) => {
  // return case;
  // [group, tool]
  // [tool]

  let returnValue = null;

  for (let item of list) {
    if (item.part_seq === parentPartSeq) {
      let newItem = _.cloneDeep(item);
      returnValue = newItem;
    }
  }
  if (returnValue && returnValue.parent_part_seq) {
    return [returnValue].concat(getParentPath(returnValue.parent_part_seq, list));
  } else {
    return [returnValue];
  }
};

const checkAuthType = (item, list) => {
  let returnValue = null;

  //내 자신이 auth_type이 있는 경우
  if (item.auth_type) {
    return item.auth_type;
  }

  // parent에 auth_type이 있는 경우
  let parentPath = getParentPath(item.parent_part_seq, list);
  for (let parentItem of parentPath) {
    if (parentItem && parentItem.auth_type) {
      return parentItem.auth_type;
    }
  }

  // child에 auth_type이 있는 경우
  let childPath = getChildPath(item.part_seq, list);
  for (let childItem of childPath) {
    if (childItem && childItem.auth_type) {
      return childItem.auth_type;
    }
    if (childItem.children && childItem.children.length > 0) {
      for (let grandChildItem of childItem.children) {
        //grand는 menu가 될 수 있음
        if (grandChildItem && grandChildItem.auth_type) {
          return grandChildItem.auth_type;
        }
      }
    }
  }

  return returnValue;
};

const setupChildList = (node, value) => {
  if (Array.isArray(node)) {
    for (let nodeItem of node) {
      if (value) {
        nodeItem.auth_type = value;
      } else {
        nodeItem.auth_type = null;
      }
      if (nodeItem.children) {
        setupChildList(nodeItem.children, value);
      }
    }
  }
};

const search = (tree, part_seq) => {
  //find authdata from part_seq
  if (tree.part_seq === part_seq) {
    return tree;
  }
  if (Array.isArray(tree.children)) {
    for (const child of tree.children) {
      const res = search(child, part_seq);

      if (res) {
        return res;
      }
    }
  }
};

const searchByUrl = (tree, url) => {
  //find authdata from url
  let regexpResult = false;

  if (tree.url) {
    let urlStringToRegex = tree.url.replaceAll('/', '\\/');
    urlStringToRegex = urlStringToRegex.replaceAll('?', '\\?');
    let regex = new RegExp(urlStringToRegex + '.*', 'g');
    regexpResult = regex.test(url);
  }

  if (regexpResult) {
    return tree;
  }
  if (Array.isArray(tree.children)) {
    for (const child of tree.children) {
      const res = searchByUrl(child, url);

      if (res) {
        return res;
      }
    }
  }
};

const findParents = (node, part_seq) => {
  // If current node name matches the search name, return
  // empty array which is the beginning of our parent result
  if (node.part_seq === part_seq) {
    return [];
  }

  // Otherwise, if this node has a tree field/value, recursively
  // process the nodes in this tree array
  if (Array.isArray(node.children)) {
    for (var treeNode of node.children) {
      // Recursively process treeNode. If an array result is
      // returned, then add the treeNode.name to that result
      // and return recursively
      const childResult = findParents(treeNode, part_seq);
      if (Array.isArray(childResult)) {
        return [treeNode].concat(childResult);
      }
    }
  }
};

const getAuthList = (data) => {
  console.log('getAuthList', data);
  const resultList = [];
  const trackingData = (data) => {
    //   console.log('trackingData data', data);
    if (data.auth_type) {
      resultList.push({
        auth_type: data.auth_type,
        part_seq: data.part_seq,
      });
    }
    if (Array.isArray(data.children)) {
      for (const children of data.children) {
        trackingData(children);
      }
    }
  };
  trackingData(data);
  return resultList;
};

const getServiceGroupValue = (data) => {
  console.log('getServiceGroupValue', data);
  let returnValue = '';
  // case
  // all case
  // each case loop
  if (data.length > 0) {
    if (data[0].checked) {
      returnValue = 'all';
    } else {
      let values = '';
      for (let i = 1; i < data.length; i++) {
        if (data[i].checked) {
          values += data[i].svc_grp_id + ',';
        }
      }
      returnValue = values.substring(0, values.length - 1);
    }
  }
  return returnValue;
};

const buildServiceGroupList = (data, list) => {
  const serviceGroupArray = data.split(',');
  let returnArray = [];

  if (serviceGroupArray.length === 1 && serviceGroupArray[0] === 'all') {
    let allValue = [];

    for (let i in list) {
      returnArray.push({
        value: list[i].svc_grp_id,
        text: list[i].svc_grp_id + '(' + list[i].name + ')',
      });
      allValue.push(list[i].svc_grp_id);
    }
    returnArray.unshift({
      value: allValue.join(),
      text: '전체',
    });
  } else {
    let allValue = [];

    for (let serviceGroupId of serviceGroupArray) {
      for (let i in list) {
        if (list[i].svc_grp_id === serviceGroupId) {
          returnArray.push({
            value: list[i].svc_grp_id,
            text: list[i].svc_grp_id + '(' + list[i].name + ')',
          });
          allValue.push(list[i].svc_grp_id);
          break;
        }
      }
    }
    returnArray.unshift({
      value: allValue.join(),
      text: '전체',
    });
  }
  console.log('buildServiceGroupList', returnArray);

  return returnArray;
};

const buildServiceList = (list) => {
  let returnArray = [];
  for (let i in list) {
    returnArray.push({
      value: list[i].svc_num,
      text: list[i].name + '(' + list[i].svc_num + ')',
    });
  }
  return returnArray;
};

const validationLocation = (path, authArray, authTree) => {
  if (!path || authArray.length === 0 || !authTree) {
    //초기 init시에는 authRee, authArray가 없기 때문에 일단 true로 셋팅한다.
    return true;
  }
  if (path === '/not_auth' || path === '/' || path === '/login') {
    //not_auth page와 /d와 login는 항상 true로 해야 페이지에 머무를 수 있음.
    return true;
  }
  let isValidation = false;
  let targetItem = searchByUrl(authTree, path);
  if (!targetItem) {
    return false;
  }
  console.log('targetItem', targetItem);
  console.log('authArray', authArray);
  console.log('authTree', authTree);

  for (let authItem of authArray) {
    if (authItem.part_type === 'root') {
      //root case
      if (authItem.auth_type) {
        isValidation = true;
      }
    } else {
      //toll group menu case
      let parentPath = getParentPath(targetItem.part_seq, authArray);
      for (let parentItem of parentPath) {
        if (parentItem.auth_type) {
          isValidation = true;
          break;
        }
      }
    }
  }

  return isValidation;
};

const authTypeToText = (value) => {
  if (value === 'rw') {
    return 'R/W';
  } else if (value === 'ro') {
    return 'RO';
  } else {
    return '';
  }
};
export const AdminUtil = {
  buildBackofficePartData: buildBackofficePartData,
  buildNavagation: buildNavagation,
  setupChildList: setupChildList,
  search: search,
  findParents: findParents,
  getAuthList: getAuthList,
  getServiceGroupValue: getServiceGroupValue,
  checkAuthType: checkAuthType,
  buildServiceGroupList: buildServiceGroupList,
  buildServiceList: buildServiceList,
  validationLocation: validationLocation,
  searchByUrl: searchByUrl,
  authTypeToText: authTypeToText,
};
