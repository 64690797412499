import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'deal/';

const GetList = (object) => {
  const url = BASE_URL + PATH + 'list';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const GetCheckList = (object) => {
  const url = BASE_URL + PATH + 'check_list';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const ForceInsert = (body) => {
  const url = BASE_URL + PATH + 'force';
  return AxiosInstance.post(url, body);
};
const Deal = {
  GetList: GetList,
  GetCheckList: GetCheckList,
  ForceInsert: ForceInsert,
};

export default Deal;
