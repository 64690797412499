import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'pay-info/';

const Put = (payInfoSequence, body) => {
  const url = BASE_URL + PATH + payInfoSequence;
  return AxiosInstance.put(url, body);
};

const PayInfo = {
  Put: Put,
};

export default PayInfo;
