import React, { useEffect, useState, useContext } from 'react';
import queryString from 'query-string';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Form,
  Error,
  Loading,
  Card,
  Filter,
  Paging,
  TableHeader,
  KlaytnHash,
  AuthForm,
  ServiceGroupForm,
} from '../../components';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils, ErrorUtil, AdminUtil } from '../../utils';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../../contexts';

export const AdminUserCreatePage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    email: '',
    name: '',
    company: '',
  });
  const [authData, setAuthData] = useState(null);
  const [serviceGroupData, setServiceGroupData] = useState(null);
  const { auth, authArray } = useContext(AuthContext);

  useEffect(() => {
    API.AdminUser.getDefaultBackofficePart().then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result) {
          setAuthData(AdminUtil.buildBackofficePartData(result.list));
        }
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );

    API.ServiceGroup.Get().then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        for (let i in result.list) {
          result.list[i].checked = false;
        }
        setServiceGroupData([
          {
            accountId: null,
            apiKeyId: null,
            apiKeyValue: null,
            name: '전체',
            pendingApiKeyId: null,
            pendingApiKeyValue: null,
            serviceGroupId: 'all',
            checked: false,
          },
          ...result.list,
        ]);
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  }, []);

  const onChangeInfo = (name, value) => {
    // let newData = _.cloneDeep(data);

    setData({ ...data, [name]: value });
  };

  const onChangeAuth = (value, target) => {
    let newAuthData = _.cloneDeep(authData);
    let parentList = AdminUtil.findParents(newAuthData, target.part_seq);

    //상위 트리값에 null을 셋팅한다.
    if (parentList.length === 0) {
      // root case
      if (value) {
        newAuthData.auth_type = value;
      } else {
        newAuthData.auth_type = null;
      }
    } else {
      // tool, group, menu
      newAuthData.auth_type = null;
      for (let i in parentList) {
        //upper tree setup null
        parentList[i].auth_type = null;
        if (Number(i) + 1 === parentList.length) {
          if (value) {
            parentList[i].auth_type = value;
          } else {
            parentList[i].auth_type = null;
          }
        }
      }
    }

    //하위 트리값에 null을 셋팅한다.
    let childItem = AdminUtil.search(newAuthData, target.part_seq);
    AdminUtil.setupChildList(childItem.children, null);
    setAuthData(newAuthData);
  };

  const onChangeServiceGroup = (event, index) => {
    let value = event.target.checked;
    // console.log('value', value);
    let newServiceGroupData = _.cloneDeep(serviceGroupData);
    if (index === 0) {
      //setup all
      for (let i in newServiceGroupData) {
        newServiceGroupData[i].checked = value;
      }
    } else {
      // false -> check list -> setup also false
      // true -> check all -> setup also true
      newServiceGroupData[index].checked = value;

      if (value) {
        let isAllChecked = true;
        for (let i = 1; i < newServiceGroupData.length; i++) {
          if (!newServiceGroupData[i].checked) {
            isAllChecked = false;
          }
        }
        if (isAllChecked) {
          newServiceGroupData[0].checked = true;
        }
      } else {
        if (newServiceGroupData[0].checked) {
          newServiceGroupData[0].checked = false;
        }
      }
    }
    setServiceGroupData(newServiceGroupData);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const requestCreate = () => {
    console.log('requestCreate');
    if (!data.email || !data.name || !data.company) {
      enqueueSnackbar('유저 정보를 확인해주세요.', { variant: 'error' });
      return;
    } else if (!validateEmail(data.email)) {
      enqueueSnackbar('이메일 형식을 확인해주세요.', { variant: 'error' });
      return;
    }

    const authList = AdminUtil.getAuthList(authData);
    if (authList.length === 0) {
      enqueueSnackbar('권한을 선택해주세요..', { variant: 'error' });
      return;
    }
    if (!serviceGroupData) {
      enqueueSnackbar('서비스 그룹을 선택해주세요..', { variant: 'error' });
      return;
    }

    const serviceGroupValue = AdminUtil.getServiceGroupValue(serviceGroupData);
    const body = {
      attributes: data,
      auth: authList,
      serviceGroup: serviceGroupValue,
    };
    console.log('body', body);
    API.AdminUser.Post(body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);

        let targetItem = AdminUtil.searchByUrl(auth, '/admin-user/detail');
        let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);

        console.log('targetItem', targetItem);
        console.log('linkAuthType', linkAuthType);
        if (linkAuthType) {
          history.push({
            pathname: '/admin-user/detail/' + result.userInfo.Username,
            state: { pageAuthType: linkAuthType },
          });
        } else {
          enqueueSnackbar('디테일 권한이 없습니다.', { variant: 'warning' });
        }
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>관리자 생성</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            <li>
              <Button
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                onClick={requestCreate}
                variant="contained"
                color="primary"
              >
                생성
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <Form title="유저 정보" data={data} type="create" contentType="admin-user" onChange={onChangeInfo}></Form>
      <div style={{ height: 20 }}></div>
      {authData && <AuthForm authData={authData} onChangeAuth={onChangeAuth}></AuthForm>}
      <div style={{ height: 20 }}></div>
      <ServiceGroupForm
        serviceGroupData={serviceGroupData}
        onChangeServiceGroup={onChangeServiceGroup}
      ></ServiceGroupForm>
    </article>
  );
};
