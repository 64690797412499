import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'accounting/';

const Get = (object) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const GetManagementList = (object) => {
  const url = BASE_URL + PATH + 'management';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};
const Accounting = {
  Get: Get,
  GetManagementList: GetManagementList,
};

export default Accounting;
