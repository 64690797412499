import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const buildHexAddress = (value) => {
  if (value) {
    let returnText = '';

    returnText = value.substr(0, 5);
    returnText += '...';
    returnText += value.substr(value.length - 3, value.length);

    return returnText;
  } else {
    return '';
  }
};

export const KlaytnHash = (props) => {
  const { address } = props;
  if (!address) {
    return null;
  }
  return (
    <article
      className="klaytn-hash"
      onClick={() => {
        if (address) {
          window.open('https://scope.klaytn.com/tx/' + address);
        }
      }}
    >
      <div className="content__box">
        <span>{buildHexAddress(address)}</span>
        <OpenInNewIcon style={{ fontSize: 16, marginLeft: 5, color: '#ffffff' }}></OpenInNewIcon>
      </div>
    </article>
  );
};
