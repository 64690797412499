import React, { useEffect, useState } from 'react';
import API from '../../api';
import { Error, Loading } from '../../components';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../../utils';

export const KasListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [accountList, setAccountList] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    API.KAS.Get().then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        setAccountList(result.list);
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
        setError(true);
      },
    );
  }, []);

  if (error) {
    return (
      <article className="content-page klaytn">
        <Error></Error>
      </article>
    );
  }
  if (!accountList) {
    return (
      <article className="content-page klaytn">
        <Loading></Loading>
      </article>
    );
  }
  return (
    <article className="content-page klaytn">
      <header>
        <section>
          <div className="title">
            <h1>Klaytn 계정 목록</h1>
            {accountList && <em>({accountList.length})</em>}
          </div>
        </section>
        {/* <section>
          <ul className="option-list">
            <li>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push('/kas/create');
                }}
              >
                Klaytn 계정 생성
              </Button>
            </li>
          </ul>
        </section> */}
      </header>
      <ul className="grid__box">
        {accountList &&
          accountList.map((account, index) => {
            return (
              <Card
                path={'/kas/detail/' + account.accnt_id}
                data={account}
                type="kas"
                key={'kas-account-list-' + index}
              ></Card>
            );
          })}
      </ul>
    </article>
  );
};
