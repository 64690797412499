import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { DateUtil, KlaytnUtil } from '../utils';
import { KlipAddress } from '../components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';

const renderHeaderText = (value) => {
  switch (value) {
    // kas account
    case 'accountId': {
      return 'Kalytn 계정 아이디';
    }
    case 'name': {
      return '이름';
    }
    case 'address': {
      return 'Klaytn 계정 주소';
    }
    case 'currentBalance': {
      return '현재 Klay';
    }
    //service-group
    case 'accnt_id': {
      return 'Kalytn 계정 아이디';
    }
    case 'svc_grp_id': {
      return '서비스 그룹 아이디';
    }
    case 'api_key_id': {
      return 'API Key 아이디';
    }
    case 'api_key_value': {
      return 'API Key 값';
    }
    case 'pending_api_key_id': {
      return 'API Key 아이디(Deprecated)';
    }
    case 'pending_api_key_value': {
      return 'API Key 값(Deprecated)';
    }
    //service
    case 'serviceNumber': {
      return '서비스 번호';
    }
    case 'mbr_grp_id': {
      return '멤버 그룹 아이디';
    }
    case 'memberGroupId': {
      return '멤버 그룹 아이디';
    }
    case 'description': {
      return '설명';
    }
    case 'openDate': {
      return '시작일';
    }
    case 'closeDate': {
      return '종료일';
    }
    case 'regDate': {
      return '등록일';
    }

    //bulk
    case 'bulk_seq': {
      return '벌크 시퀀스';
    }
    case 'svc_num': {
      return '서비스 번호';
    }
    case 'admin_id': {
      return '어드민 아이디';
    }
    case 'fixed_amount': {
      return '고정 수량(Klay)';
    }
    case 'total_amount': {
      return '총 수량(Klay)';
    }
    case 'total_count': {
      return '총 요청 건수';
    }
    case 'reg_dt': {
      return '등록일';
    }
    case 'reserve_dt': {
      return '예약일';
    }
    case 'expire_dt': {
      return '만료일';
    }
    case 'link_check': {
      return '링크 확인';
    }
    case 'bulk_memo': {
      return '벌크 메모';
    }
    case 'svc_name': {
      return '서비스 이름';
    }
    case 'svc_desc': {
      return '서비스 설명';
    }
    case 'svc_mbr_grp_id': {
      return '서비스 멤버 그룹 아이디';
    }

    //admin-user
    case 'email': {
      return '이메일';
    }
    case 'company': {
      return '회사(소속)';
    }

    default: {
      return 'text';
    }
  }
};

{
  /* <FormControl style={{ width: 420 }}>
  <Select
    multiple
    labelWidth={0}
    value={data[key]}
    input={<Input />}
    renderValue={(selected) => {
      console.log('selected', selected);
      if (selected.length === 0) {
        return <em>Placeholder</em>;
      }

      return selected.join(', ');
    }}
    onChange={(e) => {
      let newValue = e.target.value;
      console.log('newValue', newValue);
      onChange(key, newValue);
    }}
  >
    {formProps.accountList.map((account, index) => {
      return (
        <MenuItem key={'menu-item-account-' + index} value={account[key]}>
          <Checkbox color="primary" checked={data[key].includes(account[key])} />
          {account[key]}({account.name})
        </MenuItem>
      );
    })}
  </Select>
</FormControl>; */
}

const renderFormByKey = (data, key, type, contentType, formProps, onChange, onCallback, location) => {
  // type detail or create
  switch (key) {
    case 'accnt_id': {
      if (type === 'create') {
        if (contentType === 'service') {
          return (
            <FormControl style={{ width: 420 }}>
              <Select
                labelWidth={0}
                value={data[key]}
                onChange={(e) => {
                  let newValue = e.target.value;
                  onChange('accnt_id', newValue);
                }}
              >
                {formProps.accountIdList.map((item, index) => {
                  return (
                    <MenuItem key={'menu-item-account-id-' + index} value={item.accnt_id}>
                      {item.accnt_id}({item.name})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        } else {
          return (
            <TextField
              style={{ width: 420 }}
              inputProps={{ maxLength: 10 }}
              value={data[key]}
              onChange={(e) => {
                onChange(key, e.target.value);
              }}
              placeholder={'최대 10 글자'}
            />
          );
        }
      } else {
        return <span>{data[key]}</span>;
      }
    }
    case 'svc_grp_id': {
      if (type === 'create') {
        if (contentType === 'service' || contentType === 'kas') {
          return (
            <FormControl style={{ width: 420 }}>
              <Select
                labelWidth={0}
                value={data[key]}
                onChange={(e) => {
                  let newValue = e.target.value;
                  onChange('svc_grp_id', newValue);
                }}
              >
                {formProps.serviceGroupList.map((item, index) => {
                  return (
                    <MenuItem key={'menu-item-service-group-' + index} value={item.svc_grp_id}>
                      {item.svc_grp_id}({item.name})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        } else {
          return (
            <TextField
              style={{ width: 420 }}
              inputProps={{ maxLength: 10 }}
              value={data[key]}
              onChange={(e) => {
                onChange(key, e.target.value);
              }}
              placeholder={'최대 10 글자'}
            />
          );
        }
      } else {
        return <span>{data[key]}</span>;
      }
    }
    case 'name': {
      return (
        <TextField
          style={{ width: 420 }}
          inputProps={{ maxLength: 50 }}
          value={data[key]}
          onChange={(e) => {
            onChange(key, e.target.value);
          }}
          placeholder={'최대 50 글자'}
        />
      );
    }
    case 'description': {
      return (
        <TextareaAutosize
          maxLength={200}
          style={{ width: 420, padding: 10, marginTop: 10, borderColor: 'rgba(0,0,0,0.23)' }}
          value={data[key]}
          onChange={(e) => {
            onChange(key, e.target.value);
          }}
          placeholder={'최대 200 글자'}
        />
      );
    }
    case 'api_key_value': {
      if (type === 'create') {
        return <span>자동 생성</span>;
      } else {
        return (
          <>
            <span>{data[key]}</span>
            <IconButton
              color="secondary"
              disabled={location.state.pageAuthType === 'rw' ? false : true}
              onClick={() => {
                onCallback('service-group-refresh');
              }}
            >
              <RefreshIcon />
            </IconButton>
          </>
        );
      }
    }
    case 'pending_api_key_value': {
      if (type === 'create') {
        return <span>자동 생성</span>;
      } else {
        return (
          <>
            <span>{data[key]}</span>
            {data[key] && (
              <IconButton
                component="div"
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                color="secondary"
                onClick={() => {
                  onCallback('service-group-remove-pending');
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        );
      }
    }
    case 'address': {
      if (type === 'create') {
        return <span>자동생성</span>;
      } else {
        return <KlipAddress address={data[key]}></KlipAddress>;
      }
    }
    case 'closeDate':
    case 'openDate': {
      return (
        <TextField
          id="datetime-local"
          type="datetime-local"
          value={DateUtil.dbDateToYYYYMMDDTHHmm(data[key])}
          onChange={(e) => {
            onChange(key, DateUtil.dateToDbDate(e.target.value));
          }}
        />
      );
    }
    case 'regDate': {
      return <span>{DateUtil.dbDateToYYYYMMDD(data[key])}</span>;
    }
    case 'memberGroupId': {
      if (type === 'create') {
        if (contentType === 'service') {
          return (
            <FormControl style={{ width: 420 }}>
              <Select
                labelWidth={0}
                value={data[key]}
                onChange={(e) => {
                  let newValue = e.target.value;
                  onChange('memberGroupId', newValue);
                }}
              >
                {formProps.memberGroupList.map((item, index) => {
                  console.log('memberGroupList item', item);
                  return (
                    <MenuItem key={'menu-item-memberGroupId-' + index} value={item.mbr_grp_id}>
                      {item.mbr_grp_id}({item.name})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        }
      } else {
        return <span>{data[key]}</span>;
      }
    }
    case 'currentBalance':
    case 'total_amount':
    case 'fixed_amount': {
      return <span>{KlaytnUtil.pebToKlay(data[key])}</span>;
    }
    case 'reserve_dt':
    case 'expire_dt':
    case 'reg_dt': {
      return <span>{DateUtil.dbDateToYYYYMMDD(data[key])}</span>;
    }
    case 'link_check': {
      return <span>{data[key] === 1 ? 'Y' : 'N'}</span>;
    }
    case 'svc_desc':
    case 'bulk_memo': {
      return <p style={{ whiteSpace: 'pre-line', lineHeight: '23px' }}>{data[key]}</p>;
    }
    case 'email': {
      if (type === 'create') {
        return (
          <TextField
            style={{ width: 420 }}
            inputProps={{ maxLength: 50 }}
            value={data[key]}
            onChange={(e) => {
              onChange(key, e.target.value);
            }}
            placeholder={'이메일을 입력해주세요.'}
          />
        );
      } else {
        return <span>{data[key]}</span>;
      }
    }
    case 'company': {
      return (
        <TextField
          style={{ width: 420 }}
          inputProps={{ maxLength: 20 }}
          value={data[key]}
          onChange={(e) => {
            onChange(key, e.target.value);
          }}
          placeholder={'회사명을 입력해주세요.'}
        />
      );
    }
    default: {
      return <span>{data[key]}</span>;
    }
  }
};

export const Form = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { full, title, data, type, onChange, onCallback, contentType, formProps } = props;

  let rootClassName = 'form-item';
  if (contentType) {
    rootClassName += ' ' + contentType;
  }
  if (full) {
    rootClassName += ' ' + 'full';
  }
  return (
    <article className={rootClassName}>
      <header>
        <h1>{title}</h1>
      </header>
      <ul>
        {Object.keys(data).map((key) => {
          return (
            <li key={'form-list-item-' + key} className="row">
              <header>
                <span>{renderHeaderText(key)}</span>
              </header>
              <div className="value">
                {renderFormByKey(data, key, type, contentType, formProps, onChange, onCallback, location)}
              </div>
            </li>
          );
        })}
      </ul>
    </article>
  );
};
