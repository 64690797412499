const ObjectToParams = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return '?' + str.join('&');
};

const ResultCheck = (res) => {
  if (res && res.status === 200 && res.data && res.data.result) {
    return res.data;
  }
};
const SetStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};

const GetStorage = (key) => {
  window.localStorage.getItem(key);
};

const Utils = {
  ObjectToParams: ObjectToParams,
  GetStorage: GetStorage,
  SetStorage: SetStorage,
  ResultCheck: ResultCheck,
};

export default Utils;
