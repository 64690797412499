import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import readXlsxFile from 'read-excel-file';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InfoIcon from '@material-ui/icons/Info';
import _, { isNull, reject } from 'lodash';
import { Error, Loading, Paging, TableHeader } from '../../../components';
import { usePagination } from '../../../hooks';
import { AuthContext, UserContext, DialogContext } from '../../../contexts';
import moment from 'moment-timezone';
import Modal from '@material-ui/core/Modal';
import { DateUtil, ErrorUtil, AdminUtil } from '../../../utils';
import BigNumber from 'bignumber.js';
import API from '../../../api';
import { useSnackbar } from 'notistack';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { Link, useHistory, useLocation } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import Amplify, { Storage, Auth } from 'aws-amplify';
import XLSX from 'xlsx';

const newListObject = {
  memberId: '',
  amount: 0,
};

export const RewardTransferBuilkPage = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [list, setList] = useState([
    {
      memberId: '',
      amount: 0,
    },
  ]);

  const [requiredValues, setRequiredValues] = useState({
    serviceNumber: null,
    memo: '',
  });

  const [optionValues, setOptionValues] = useState({
    amount: null,
    reserveTime: '',
    expireTime: '',
    checkLink: true,
  });
  const [linkCountInfo, setLinkCountInfo] = useState({
    linkedCount: 0,
    unLinkedCount: 0,
  });
  const { userInfo } = useContext(UserContext);
  const { auth, authArray, serviceList } = useContext(AuthContext);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(1, 1);
  const [showList, setShowList] = useState([]);
  const [linkList, setLinkList] = useState(null);
  const [unlinkList, setUnlinkList] = useState(null);
  const [linkLoading, setLinkLoading] = useState(false);
  const [modal, setModal] = useState({
    open: false,
  });
  const [isRequested, setIsRequested] = useState(false);
  const { updateDialog } = useContext(DialogContext);

  const { enqueueSnackbar } = useSnackbar();

  // console.log('render bulk page', page);
  // console.log('render bulk list', list);
  // console.log('render bulk showList', showList);
  // console.log('render bulk unlinkList', unlinkList);

  // console.log('render bulk sample test');

  useEffect(() => {
    reuqestNewShowList();
  }, [list, page]);

  useEffect(() => {
    // console.log('pagePerRow', pagePerRow);
    setPage(1);
    reuqestNewShowList();
  }, [pagePerRow]);

  useEffect(() => {
    const unixReserveTime = moment(optionValues.reserveTime).tz('Asia/Seoul').valueOf();
    const unixExpireTime = moment(optionValues.expireTime).tz('Asia/Seoul').valueOf();

    // console.log('check unixReserveTime', unixReserveTime);
    // console.log('check unixExpireTime', unixExpireTime);
    if (unixReserveTime > unixExpireTime) {
      enqueueSnackbar('시작일이 만료일 보다 늦게 설정되어 있습니다.', { variant: 'warning' });
    }
  }, [optionValues.reserveTime, optionValues.expireTime]);

  useEffect(() => {
    // console.log('useEffect linkList', linkList);
    let newUnlinkList = [];
    if (linkList && Array.isArray(linkList)) {
      // console.log('can link check');
      const lowerMemberIdList = list.map((item) => {
        console.log('item', item);
        return String(item.memberId).toLowerCase();
      });

      for (let i in lowerMemberIdList) {
        let targetMemberId = lowerMemberIdList[i];
        const lowerLinkMemberIdList = linkList.map((item) => {
          return String(item).toLowerCase();
        });
        if (_.includes(lowerLinkMemberIdList, targetMemberId)) {
        } else {
          newUnlinkList.push(targetMemberId);
        }
      }
      setUnlinkList(newUnlinkList);
    } else {
      setUnlinkList(null);
    }
  }, [linkList]);

  useEffect(() => {
    if (list && Array.isArray(list)) {
      let newUnlinkList = [];
      if (linkList && Array.isArray(linkList)) {
        // console.log('can link check');

        const lowerMemberIdList = list.map((item) => {
          return String(item.memberId).toLowerCase();
        });

        for (let i in lowerMemberIdList) {
          let targetMemberId = lowerMemberIdList[i];
          const lowerLinkMemberIdList = linkList.map((item) => {
            return String(item).toLowerCase();
          });
          if (_.includes(lowerLinkMemberIdList, targetMemberId)) {
          } else {
            newUnlinkList.push(targetMemberId);
          }
        }
        setUnlinkList(newUnlinkList);
      } else {
        setUnlinkList(null);
      }
    }
  }, [list]);

  const reuqestNewShowList = () => {
    // console.log('reuqestNewShowList', page);

    let startIndex = (page - 1) * pagePerRow;
    let lastIndex = startIndex + pagePerRow;
    if (lastIndex > list.length) {
      lastIndex = list.length;
    }

    let newShowList = [];
    for (let i = startIndex; i < lastIndex; i++) {
      // console.log('i', i);
      newShowList.push(list[i]);
    }
    setShowList(newShowList);
    setTotalPage(Math.ceil(list.length / pagePerRow));
  };

  const onChangeRow = (value, index, key) => {
    // console.log('value', value);
    // console.log('index', index);
    // console.log('key', key);

    let targetIndex = (page - 1) * pagePerRow + index;
    let newList = _.cloneDeep(list);
    newList[targetIndex][key] = value;
    setList(newList);
  };

  const onChangeRequiredValue = (value, key) => {
    setRequiredValues({
      ...requiredValues,
      [key]: value,
    });
  };

  const onChangeOptionValue = (value, key) => {
    setOptionValues({
      ...optionValues,
      [key]: value,
    });
    if (key === 'checkLink') {
      setLinkList(null);
    }
  };

  const removeRow = (index) => {
    const newList = _.cloneDeep(list);
    const removedRow = newList.splice(index, 1);
    // console.log('removedRow', removedRow);
    setList(newList);
  };

  const addRow = () => {
    const newList = _.cloneDeep(list);
    newList.push(newListObject);
    setList(newList);
    setLinkList(null);
  };

  const requestBulkCheck = () => {
    // console.log('requestBulkCheck requiredValues', requiredValues);
    // console.log('requestBulkCheck optionValues', optionValues);

    //validation check
    let isValidation = true;
    let message = '';
    // required
    if (!requiredValues.serviceNumber || !requiredValues.memo) {
      enqueueSnackbar('필수 정보를 확인해주세요.', { variant: 'error' });
      return;
    }

    //1) memberId
    if (list.length === 0) {
      enqueueSnackbar('요청 리스트가 없습니다.', { variant: 'error' });

      return;
    }
    for (let i in list) {
      if (!list[i].memberId) {
        isValidation = false;
        message = '멤버 아이디가 비어있는 요청이 있습니다.';
        break;
      }

      if (!list[i].amount && !optionValues.amount) {
        isValidation = false;
        message = '수량 값이 비어있는 요청이 있습니다.';
      }

      if (!isAllAmount()) {
        if (parseFloat(list[i].amount) === 0) {
          isValidation = false;
          message = '수량 값이 0인 요청이 있습니다.';
          break;
        }
      }
    }

    if (optionValues.checkLink) {
      if (!linkList) {
        let linkCheckButton = document.getElementById('link-check-button');
        linkCheckButton.focus();
        enqueueSnackbar('링크 확인 후 시도해주세요.', { variant: 'warning' });
        return;
      }
    }

    // memberId 중복체크 대소문자 구문없이

    // console.log('isValidation', isValidation);
    // console.log('list', list);

    let duplicateMemberIds = list
      .map((e) => {
        return e['memberId'];
      })
      .map((e, i, final) => {
        // console.log('i', i);
        let isDuplicated = false;
        for (let j in final) {
          if (Number(j) !== Number(i)) {
            let targetMemberId = final[j];
            if (String(e).toLowerCase() === String(targetMemberId).toLowerCase()) {
              isDuplicated = true;
            }
          }
        }
        if (isDuplicated) {
          return i;
        } else {
          return false;
        }
      })
      .filter((obj) => {
        return list[obj];
      })
      .map((e) => list[e]['memberId']);
    // console.log('duplicateMemberIds', duplicateMemberIds);
    if (duplicateMemberIds.length > 0) {
      let memberIdTexts = duplicateMemberIds.join();
      enqueueSnackbar(`memberId 중 ${memberIdTexts}가 중복되었습니다.`, {
        variant: 'error',
      });
      return;
    }

    if (isValidation) {
      setLinkCountInfo(checkLinkCount());
      setModal({
        open: true,
      });
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const requestBulk = () => {
    // console.log('userInfo', userInfo);

    let body = {
      serviceNumber: parseInt(requiredValues.serviceNumber),
      memo: requiredValues.memo,
      totalAmount: getTotalAmount(), // bignumber
      totalCount: list.length.toString(),
      adminId: userInfo.idToken.payload['cognito:username'],
      reserveTime: DateUtil.dateToDbDate(optionValues.reserveTime),
      expireTime: DateUtil.dateToDbDate(optionValues.expireTime),
      checkLink: optionValues.checkLink,
      fixedAmount: optionValues.amount ? new BigNumber(optionValues.amount).toString(10) : '0',
      list: list,
    };
    // console.log('requestBulk body', body);
    if (body.checkLink && unlinkList.length > 0) {
      onCloseModal();
      enqueueSnackbar('미링크 요청 건수가 존재합니다. 전송 불가 멤버아이디를 확인 후 재시도해주세요.', {
        variant: 'error',
      });
    } else {
      setIsRequested(true);
      API.Bulk.Post(body).then(
        (res) => {
          // console.log('res', res);
          const result = API.Utils.ResultCheck(res);
          if (result) {
            let targetItem = AdminUtil.searchByUrl(auth, '/bulk/detail/');
            let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);
            // console.log('targetItem', targetItem);
            // console.log('linkAuthType', linkAuthType);
            if (linkAuthType) {
              history.push({
                pathname: '/bulk/detail/' + result.bulkTransferSequence,
                state: { pageAuthType: linkAuthType },
              });
            }
          }
        },
        (err) => {
          // console.log('err', err);
          setIsRequested(false);
          enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
        },
      );
    }
  };

  const requestLinkCheck = () => {
    // console.log('requestLinkCheck');
    if (list.length > 0) {
      for (let i in list) {
        if (!list[i].memberId) {
          enqueueSnackbar('memberId 값을 확인해주세요.', { variant: 'error' });
          return;
        }
      }
      let body = {
        list: list,
      };
      setLinkLoading(true);
      API.Bulk.LinkCheck(body).then(
        (res) => {
          // console.log('res', res);
          const result = API.Utils.ResultCheck(res);
          // console.log('result', result);
          setLinkList(result.list);
          setLinkLoading(false);
        },
        (err) => {
          setLinkLoading(false);
          // console.log('err', err);
          enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
        },
      );
    } else {
      enqueueSnackbar('멤버 아이디를 입력해주세요.', { variant: 'error' });
    }
  };

  const isLinked = (memberId) => {
    // console.log('isLinked memberId', memberId);
    // console.log('linkList', linkList);

    if (Array.isArray(linkList) && linkList.length > 0) {
      let targetMemberId = String(memberId).toLowerCase();
      //대소문자 구분없이 비교하기 위해 includes가 아닌 for loop로 validation
      let isIncludes = false;
      for (let j in linkList) {
        let linkMemeberId = String(linkList[j]).toLowerCase();
        // console.log('linkMemeberId', linkMemeberId);
        // console.log('targetMemberId', targetMemberId);
        if (targetMemberId === linkMemeberId) {
          isIncludes = true;
          break;
        }
      }

      if (isIncludes) {
        return <LinkIcon color="primary"></LinkIcon>;
      } else {
        return <LinkOffIcon color="secondary"></LinkOffIcon>;
      }
    } else if (Array.isArray(linkList) && linkList.length === 0) {
      return <LinkOffIcon color="secondary"></LinkOffIcon>;
    } else {
      return '링크 확인 필요';
    }
  };

  const onCloseModal = () => {
    setModal({
      ...modal,
      open: false,
    });
  };

  const isAllAmount = () => {
    if (!optionValues.amount) {
      return false;
    }
    if (parseFloat(optionValues.amount) === 0) {
      return false;
    }
    return true;
  };

  const checkLinkCount = () => {
    if (optionValues.checkLink && Array.isArray(linkList) && linkList.length > 0) {
      let linkedCount = 0;
      let unLinkedCount = 0;
      const lowerMemberIdList = list.map((item) => {
        return String(item.memberId).toLowerCase();
      });

      for (let i in lowerMemberIdList) {
        let targetMemberId = lowerMemberIdList[i];
        const lowerLinkMemberIdList = linkList.map((item) => {
          return String(item).toLowerCase();
        });
        if (_.includes(lowerLinkMemberIdList, targetMemberId)) {
          linkedCount++;
        } else {
          unLinkedCount++;
        }
      }
      return {
        linkedCount: linkedCount,
        unLinkedCount: unLinkedCount,
      };
    } else if (optionValues.checkLink && Array.isArray(linkList) && linkList.length === 0) {
      return {
        linkedCount: 0,
        unLinkedCount: list.length,
      };
    } else {
      return {
        linkedCount: '정보 없음',
        unLinkedCount: '정보 없음',
      };
    }
  };

  const getTotalAmount = () => {
    if (isAllAmount()) {
      let returnValue = new BigNumber(optionValues.amount).multipliedBy(list.length);
      return returnValue.toString(10);
    } else {
      let returnValue = new BigNumber(0);
      for (let i in list) {
        returnValue = returnValue.plus(list[i].amount);
      }
      return returnValue.toString(10);
    }
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  };

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>벌크 전송</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            <li>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  Storage.get('bulk_sample.xlsx', { download: true }).then(
                    (res) => {
                      // console.log('res', res);
                      enqueueSnackbar('다운로드 성공', { variant: 'success' });
                      downloadBlob(res.Body, 'bulk_sample.xlsx');
                    },
                    (err) => {
                      // console.log('err', err);
                      enqueueSnackbar('다운로드 실패', { variant: 'error' });
                    },
                  );
                }}
              >
                <GetAppIcon style={{ marginRight: 5, fontSize: 18 }}></GetAppIcon>SAMPLE EXCEL
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  let fileInput = document.getElementById('excel-file');
                  fileInput.click();
                }}
              >
                <PublishIcon style={{ marginRight: 5, fontSize: 18 }}></PublishIcon>
                Excel Upload
              </Button>
              <input
                style={{ display: 'none' }}
                type="file"
                id="excel-file"
                onChange={(e) => {
                  e.persist();
                  // console.log('e', e.target.files);
                  let input = e.target;
                  let reader = new FileReader();

                  try {
                    setList([
                      {
                        memberId: '',
                        amount: 0,
                      },
                    ]);
                    setLinkList(null);

                    reader.onload = function () {
                      let data = reader.result;
                      let workBook = XLSX.read(data, { type: 'binary' });
                      workBook.SheetNames.forEach(function (sheetName) {
                        // console.log('SheetName: ', sheetName);
                        let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                        // console.log('rows', rows);
                        //빈값 검토
                        let nullIndex = null;
                        for (let i in rows) {
                          let targetRow = rows[i];
                          let targetMemberId = targetRow.memberId;
                          let targetAmount = targetRow.amount;
                          // console.log('targetMemberId', typeof targetMemberId);
                          // console.log('typeof targetAmount', typeof targetAmount);

                          if (!targetMemberId) {
                            // console.log('targetMemberId', i);
                            nullIndex = i;
                            break;
                          } else if (!targetAmount) {
                            // console.log('targetAmount', i);
                            nullIndex = i;
                            break;
                          }
                        }
                        // console.log('nullIndex', nullIndex);

                        if (nullIndex) {
                          enqueueSnackbar(`엑셀의 ${Number(nullIndex) + 2}행에 빈값이 존재합니다.`, {
                            variant: 'error',
                          });
                        }

                        //중복 검토
                        let duplicateMemberIds = rows
                          .map((e) => e['memberId'])
                          .map((e, i, final) => {
                            let isDuplicated = false;
                            for (let j in final) {
                              if (Number(j) !== Number(i)) {
                                let targetMemberId = final[j];
                                if (String(e).toLowerCase() === String(targetMemberId).toLowerCase()) {
                                  isDuplicated = true;
                                }
                              }
                            }
                            if (isDuplicated) {
                              return i;
                            } else {
                              return false;
                            }
                          })
                          .filter((obj) => rows[obj])
                          .map((e) => rows[e]['memberId']);
                        // console.log('duplicateMemberIds', duplicateMemberIds);
                        if (duplicateMemberIds.length > 0) {
                          let memberIdTexts = duplicateMemberIds.join();
                          enqueueSnackbar(`엑셀의 memberId 중 ${memberIdTexts}가 중복되었습니다.`, {
                            variant: 'error',
                          });
                        }

                        if (duplicateMemberIds.length === 0 && !nullIndex) {
                          setList(rows);
                          enqueueSnackbar(`엑셀 업로드 성공`, {
                            variant: 'success',
                          });
                        }

                        e.target.value = null;
                      });
                    };
                    if (input.files.length > 0) {
                      reader.readAsBinaryString(input.files[0]);
                    }
                  } catch (err) {
                    // console.log('err', err);
                  }

                  return;
                }}
              ></input>
            </li>

            <li>
              <Button
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                color="primary"
                variant="contained"
                onClick={requestBulkCheck}
              >
                전송
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <article className="setting__box">
        <article className="setting-list">
          <header>
            <h1>필수 설정</h1>
          </header>
          <section>
            <ul>
              <li>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id={'bulk-select-service-number-label'}>{'서비스 번호'}</InputLabel>
                  <Select
                    labelId={'bulk-select-service-number-label'}
                    value={optionValues.serviceNumber}
                    onChange={(e) => {
                      onChangeRequiredValue(e.target.value, 'serviceNumber');
                    }}
                    label={'서비스 번호'}
                  >
                    {serviceList.map((menu, menu_index) => {
                      return (
                        <MenuItem key={'bulk-select-service-number-menu-' + menu_index} value={menu.value}>
                          {menu.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {<FormHelperText>Required</FormHelperText>}
                </FormControl>

                {/* <FormControl fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    type="number"
                    value={optionValues.serviceNumber}
                    label={'서비스 번호'}
                    variant="outlined"
                    inputProps={{
                      min: 0,
                    }}
                    onChange={(e) => {
                      onChangeRequiredValue(e.target.value, 'serviceNumber');
                    }}
                  ></TextField>
                  <FormHelperText>Required</FormHelperText>
                </FormControl> */}
              </li>
              <li className="memo">
                <FormControl fullWidth variant="outlined">
                  <TextareaAutosize
                    maxLength={200}
                    style={{ padding: 10, borderRadius: 5, borderColor: 'rgba(0,0,0,0.23)' }}
                    value={optionValues.memo}
                    onChange={(e) => {
                      onChangeRequiredValue(e.target.value, 'memo');
                    }}
                    placeholder={'메모 - 최대 200 글자'}
                  />
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </li>
            </ul>
          </section>
        </article>
        <article className="setting-list">
          <header>
            <h1>선택 설정</h1>
          </header>
          <section>
            <ul>
              <li>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    type="number"
                    value={optionValues.amount}
                    label={'일괄 수량(klay)'}
                    variant="outlined"
                    inputProps={{
                      min: 0,
                    }}
                    onChange={(e) => {
                      onChangeOptionValue(e.target.value, 'amount');
                    }}
                  ></TextField>
                  <FormHelperText style={{ margin: 0 }}>
                    <Tooltip
                      title={
                        <>
                          <p style={{ fontSize: 13, lineHeight: '20px' }}>값이 있을 경우 : 일괄적으로 amount 적용</p>
                          <p style={{ fontSize: 13, lineHeight: '20px' }}>값이 없을 경우 : 아이디별 amount 적용</p>
                        </>
                      }
                      arrow
                    >
                      <div className="helper-text">
                        <span>설명</span>
                        <InfoIcon fontSize="inherit"></InfoIcon>
                      </div>
                    </Tooltip>
                  </FormHelperText>
                </FormControl>
              </li>
              <li>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    type="datetime-local"
                    value={optionValues.reserveTime}
                    variant="outlined"
                    label={'시작일'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      onChangeOptionValue(e.target.value, 'reserveTime');
                    }}
                  ></TextField>
                  <FormHelperText style={{ margin: 0 }}>
                    <Tooltip
                      title={
                        <>
                          <p style={{ fontSize: 13, lineHeight: '20px' }}>설정 안할 경우 : 즉시 전송</p>
                        </>
                      }
                      arrow
                    >
                      <div className="helper-text">
                        <span>설명</span>
                        <InfoIcon fontSize="inherit"></InfoIcon>
                      </div>
                    </Tooltip>
                  </FormHelperText>
                </FormControl>
              </li>
              <li>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    type="datetime-local"
                    value={optionValues.expireTime}
                    variant="outlined"
                    label={'만료일'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      onChangeOptionValue(e.target.value, 'expireTime');
                    }}
                  ></TextField>
                  <FormHelperText style={{ margin: 0 }}>
                    <Tooltip
                      title={
                        <>
                          <p style={{ fontSize: 13, lineHeight: '20px' }}>설정 안할 경우 : 무기한</p>
                        </>
                      }
                      arrow
                    >
                      <div className="helper-text">
                        <span>설명</span>
                        <InfoIcon fontSize="inherit"></InfoIcon>
                      </div>
                    </Tooltip>
                  </FormHelperText>
                </FormControl>
              </li>
              <li>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id={'option-check-link-select-label'}>{'링크 확인'}</InputLabel>
                  <Select
                    labelId={'option-check-link-select-label'}
                    value={optionValues.checkLink}
                    onChange={(e) => {
                      onChangeOptionValue(e.target.value, 'checkLink');
                    }}
                    label={'링크 확인'}
                  >
                    <MenuItem key={'option-check-link-menu-Y'} value={true}>
                      Y
                    </MenuItem>
                    <MenuItem key={'option-check-link-menu-N'} value={false}>
                      N
                    </MenuItem>
                  </Select>
                  <FormHelperText style={{ margin: 0 }}>
                    <Tooltip
                      title={
                        <>
                          <p style={{ fontSize: 13, lineHeight: '20px' }}>
                            Y : 전송 시점에 링크 연결된 회원에게만 전송
                          </p>
                          <p style={{ fontSize: 13, lineHeight: '20px' }}>
                            N : 만료 기간 내에 링크 연결된 회원에게 전송
                          </p>
                        </>
                      }
                      arrow
                    >
                      <div className="helper-text">
                        <span>설명</span>
                        <InfoIcon fontSize="inherit"></InfoIcon>
                      </div>
                    </Tooltip>
                  </FormHelperText>
                </FormControl>
              </li>
            </ul>
          </section>
        </article>
      </article>

      <TableHeader
        totalCount={list.length}
        pagePerRow={pagePerRow}
        onChange={setPagePerRow}
        customButton={
          <div style={{ display: 'flex' }}>
            {optionValues.checkLink && (
              <Button
                style={{ marginRight: 10, whiteSpace: 'nowrap' }}
                color="primary"
                variant="contained"
                onClick={requestLinkCheck}
                disabled={linkLoading}
                id={'link-check-button'}
              >
                {linkLoading ? 'loading' : '링크 확인'}
              </Button>
            )}

            <Button
              style={{ marginRight: 10, whiteSpace: 'nowrap' }}
              color="primary"
              variant="contained"
              onClick={addRow}
            >
              행 추가
            </Button>
          </div>
        }
      ></TableHeader>
      {unlinkList && Array.isArray(unlinkList) && unlinkList.length > 0 && (
        <article className="unlink-list">
          <header>
            <h1>전송 불가 멤버 아이디 목록({unlinkList.length})</h1>
          </header>
          <ul>
            {unlinkList.map((item, index) => {
              return <li>{item}</li>;
            })}
          </ul>
        </article>
      )}

      <article className="bulk-list">
        <TableContainer component={'div'} style={{ background: '#ffffff' }}>
          <Table>
            <TableHead>
              <TableRow style={{ height: 60 }}>
                <TableCell align="center">멤버 아이디</TableCell>
                <TableCell align="center">수량(Klay)</TableCell>
                {optionValues.checkLink && (
                  <TableCell style={{ width: 100 }} align="center">
                    링크 여부
                  </TableCell>
                )}
                <TableCell style={{ width: 100 }} align="center">
                  기능
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showList &&
                showList.map((item, index) => {
                  return (
                    <TableRow key={'service-row-' + index} style={{ height: 60 }}>
                      <TableCell align="center">
                        <TextField
                          fullWidth
                          type="text"
                          value={item.memberId}
                          variant="outlined"
                          onChange={(e) => {
                            onChangeRow(e.target.value, index, 'memberId');
                          }}
                        ></TextField>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          fullWidth
                          type="number"
                          disabled={isAllAmount() ? true : false}
                          value={item.amount}
                          id={'show-list-amount-' + index}
                          variant="outlined"
                          inputProps={{
                            min: 0,
                          }}
                          onChange={(e) => {
                            onChangeRow(e.target.value, index, 'amount');
                          }}
                          label={isAllAmount() ? '일괄적용' : ''}
                        ></TextField>
                      </TableCell>
                      {optionValues.checkLink && (
                        <TableCell style={{ width: 100 }} align="center">
                          {isLinked(item.memberId)}
                        </TableCell>
                      )}
                      <TableCell align="center">
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            removeRow(index);
                          }}
                        >
                          삭제
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </article>
      <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>

      <Modal open={modal.open} onClose={onCloseModal}>
        <div className="modal-container">
          <header>
            <h1>벌크 전송 확인</h1>
          </header>
          <article className="modal-body">
            <section className="content__box">
              <ul>
                <li>
                  <header>서비스 번호</header>
                  <span className="data">{requiredValues.serviceNumber}</span>
                </li>
                <li>
                  <header>메모</header>
                  <p className="data">{requiredValues.memo}</p>
                </li>
                <li>
                  <header>수량 일괄 적용 여부</header>
                  <span className="data">{isAllAmount() ? 'Y' : 'N'}</span>
                </li>
                {isAllAmount() && (
                  <li>
                    <header>일괄 수량</header>
                    <span className="data">{optionValues.amount} (Klay)</span>
                  </li>
                )}
                <li>
                  <header>총 요청 건수</header>
                  <span className="data">{list.length}</span>
                </li>
                <li>
                  <header>총 요청 수량</header>
                  <span className="data">{getTotalAmount()} (Klay)</span>
                </li>
                <li>
                  <header>링크 확인 여부</header>
                  <span className="data">{optionValues.checkLink ? 'Y' : 'N'}</span>
                </li>
                {optionValues.checkLink && (
                  <>
                    <li>
                      <header>링크 요청 건수 </header>
                      <span className="data">{linkCountInfo.linkedCount}</span>
                    </li>
                    <li>
                      <header>
                        <em>*</em>미링크 요청 건수
                      </header>
                      <span className="data">{linkCountInfo.unLinkedCount}</span>
                      <span className="desc">
                        (미링크 요청 건수는 클레이 전송을 처리할 수 없는 요청 건수를 의미합니다.)
                      </span>
                    </li>
                  </>
                )}
                <li>
                  <header>시작일</header>
                  <span className="data">
                    {optionValues.reserveTime ? DateUtil.dateToDbDate(optionValues.reserveTime) : '없음'}
                  </span>
                </li>
                <li>
                  <header>만료일</header>
                  <span className="data">
                    {optionValues.expireTime ? DateUtil.dateToDbDate(optionValues.expireTime) : '없음'}
                  </span>
                </li>
              </ul>
            </section>
            <section className="button__box">
              <Button color="default" variant="contained" onClick={onCloseModal} style={{ marginRight: 10 }}>
                취소
              </Button>
              <Button color="primary" variant="contained" onClick={requestBulk} disabled={isRequested}>
                전송
              </Button>
            </section>
          </article>
        </div>
      </Modal>
    </article>
  );
};
