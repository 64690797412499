import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import QRCode from 'react-qr-code';

export const LoginPage = (props) => {
  const history = useHistory();
  const [id, setId] = useState(''); //joy@coredot.io
  const [pw, setPw] = useState(''); //admin12!@
  const [newPw, setNewPw] = useState('');
  const [newCheckPw, setNewCheckPw] = useState('');
  const [needInitPassword, setNeedInitPassword] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [needOTP, setNeedOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const [needOTPSetup, setNeedOTPSetup] = useState(false);
  const [code, setCode] = useState('');

  useEffect(() => {
    Auth.currentSession().then(
      (res) => {
        console.log('currentSession res', res);
      },
      (err) => {
        console.log('currentSession err', err);
      },
    );
  }, []);

  useEffect(() => {
    console.log('userInfo', userInfo);
    if (userInfo) {
      console.log('userInfo challengeName', userInfo.challengeName);
      const challengeName = userInfo.challengeName;
      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        setNeedInitPassword(true);
      } else if (challengeName === 'SOFTWARE_TOKEN_MFA') {
        setNeedOTP(true);
      } else if (userInfo.preferredMFA === 'NOMFA') {
        setNeedOTPSetup(true);
        Auth.setupTOTP(userInfo).then(
          (code) => {
            console.log('code', code);
            setCode(code);
          },
          (err) => {
            console.log('err', err);
          },
        );
      } else {
      }
    }
  }, [userInfo]);

  const requestLogin = (e) => {
    console.log('requestLogin', id);
    console.log('requestLogin', pw);

    Auth.signIn(id, pw).then(
      (res) => {
        console.log('signIn res', res);
        setUserInfo(res);
      },
      (err) => {
        console.log('err', err.code);
        alert(err.message);
      },
    );
  };

  const requestOtpLogin = (e) => {
    console.log('userInfo', userInfo);
    console.log('otp', otp);
    Auth.confirmSignIn(
      userInfo, // Return object from Auth.signIn()
      otp, // Confirmation code
      'SOFTWARE_TOKEN_MFA', // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then(
      (res) => {
        console.log('requestOtpLogin res', res);
        history.push('/');
      },
      (err) => {
        console.log('requestOtpLogin err', err);
        alert(err.message);
      },
    );
  };

  const requestOtpSetup = (e) => {
    Auth.verifyTotpToken(userInfo, otp)
      .then((res) => {
        // don't forget to set TOTP as the preferred MFA method
        console.log('verifyTotpToken res ', res);

        Auth.setPreferredMFA(userInfo, 'TOTP')
          .then((res) => {
            console.log('setPreferredMFA res', res);
            setNeedOTPSetup(false);
            setNeedOTP(true);
            setOtp('');
            history.push('/');
          })
          .catch((err) => {
            console.log('setPreferredMFA err', err);
          });
      })
      .catch((err) => {
        // Token is not verified
        console.log('verifyTotpToken err ', err);
        alert(err.message);
        if (err.code === 'EnableSoftwareTokenMFAException') {
          setNeedOTPSetup(false);
          setOtp('');
        }
      });
  };

  const requestNewPassword = () => {
    if (newPw === newCheckPw) {
      Auth.completeNewPassword(
        userInfo, // the Cognito User Object
        newPw, // the new password
      )
        .then((res) => {
          // at this time the user is logged in if no MFA required
          console.log('requestNewPassword', res);
          alert('재설정한 비밀번호로 다시 로그인해주세요.');
          setPw('');
          setUserInfo(res);
          setNeedInitPassword(false);
        })
        .catch((err) => {
          console.log('new passwrod pw_err', err);
          alert(err.message);
        });
    } else {
      alert('비밀번호가 일치하지 않습니다.');
    }
  };

  console.log('userInfo', userInfo);

  if (needInitPassword) {
    return (
      <article className="login-page">
        <section className="input__box">
          <TextField
            id="user-new-pw"
            label="NEW PASSWORD"
            value={newPw}
            onChange={(e) => {
              setNewPw(e.target.value);
            }}
            type="password"
          ></TextField>
          <TextField
            id="user-new-check-pw"
            label="CHECK PASSWORD"
            value={newCheckPw}
            onChange={(e) => {
              setNewCheckPw(e.target.value);
            }}
            type="password"
          ></TextField>
        </section>
        <section className="button__box">
          <Button onClick={requestNewPassword} variant="contained" color="primary">
            초기 비밀번호 설정
          </Button>
        </section>
      </article>
    );
  }
  if (needOTPSetup) {
    //      const str = "otpauth://totp/AWSCognito:"+ username + "?secret=" + code + "&issuer=" + issuer;

    return (
      <article className="login-page">
        <section className="qrcode__box">
          <QRCode
            size={160}
            value={'otpauth://totp/AWSCognito:' + userInfo.username + '?secret=' + code + '&issuer='}
          ></QRCode>
          <span>Google OTP에서 QR Code Scan 후 OTP값을 입력하세요.</span>
        </section>
        <section className="input__box">
          <TextField
            id="user-otp-value"
            label="Google OTP"
            value={otp}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          ></TextField>
        </section>
        <section className="button__box">
          <Button onClick={requestOtpSetup} variant="contained" color="primary">
            OTP 설정
          </Button>
        </section>
      </article>
    );
  }
  if (needOTP) {
    return (
      <article className="login-page">
        <section className="input__box">
          <TextField
            id="user-otp-value"
            label="Google OTP"
            value={otp}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          ></TextField>
        </section>
        <section className="button__box">
          <Button onClick={requestOtpLogin} variant="contained" color="primary">
            OTP 로그인
          </Button>
        </section>
      </article>
    );
  }
  return (
    <article className="login-page">
      <section className="input__box">
        <TextField
          id="user-id"
          label="ID(email)"
          value={id}
          onChange={(e) => {
            setId(e.target.value);
          }}
        ></TextField>
        <TextField
          id="user-pw"
          label="PASSWORD"
          type="password"
          value={pw}
          onChange={(e) => {
            setPw(e.target.value);
          }}
        ></TextField>
      </section>
      <section className="button__box">
        <Button onClick={requestLogin} variant="contained" color="primary">
          로그인
        </Button>
      </section>
      <section className="help__box">
        <span
          onClick={() => {
            history.push('/find_password');
          }}
        >
          비밀번호를 잊으셨나요?
        </span>
      </section>
    </article>
  );
};
