import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

export const FindPasswordPage = (props) => {
  const history = useHistory();
  const [id, setId] = useState(''); //joy@coredot.io
  const [pw, setPw] = useState(''); //admin12!@
  const [checkPw, setCheckPw] = useState(''); //admin12!@
  const [code, setCode] = useState('');
  const [needCode, setNeedoCode] = useState(false);
  useEffect(() => {}, []);

  const requestForgotPassword = () => {
    console.log('requestForgotPassword');

    Auth.forgotPassword(id)
      .then((res) => {
        console.log('res', res);
        setNeedoCode(true);
      })
      .catch((err) => {
        console.log('err', err);
        alert(err);
      });
  };

  const requestForgotPasswordSubmit = () => {
    if (pw !== checkPw) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    Auth.forgotPasswordSubmit(id, code, pw)
      .then((res) => {
        console.log('res', res);
        alert('비밀번호가 성공적으로 변경되었습니다. 다시 로그인해주세요.');
        history.push('/login');
      })
      .catch((err) => {
        console.log('err', err);
        alert(err.message);
      });
  };

  if (needCode) {
    return (
      <article className="login-page">
        <section className="input__box">
          <TextField
            disabled={true}
            id="user-id"
            label="ID(email)"
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
          ></TextField>
          <TextField
            id="user-code"
            label="code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          ></TextField>
          <TextField
            id="user-pw"
            label="password"
            value={pw}
            onChange={(e) => {
              setPw(e.target.value);
            }}
            type="password"
          ></TextField>
          <TextField
            id="user-pw-check"
            label="password check"
            value={checkPw}
            onChange={(e) => {
              setCheckPw(e.target.value);
            }}
            type="password"
          ></TextField>
        </section>
        <section className="button__box">
          <Button onClick={requestForgotPasswordSubmit} variant="contained" color="primary">
            비밀번호 변경
          </Button>
        </section>
        <section className="help__box">
          <span
            style={{ marginRight: 10 }}
            onClick={() => {
              setNeedoCode(false);
            }}
          >
            코드 재전송
          </span>
          <span
            onClick={() => {
              history.push('/login');
            }}
          >
            로그인하기
          </span>
        </section>
      </article>
    );
  }
  return (
    <article className="login-page">
      <section className="guide-text__box">
        <ul>
          <li>1. Email 입력 후, 코드 전송 클릭</li>
          <li>2. Email에 전송 된 코드와 새로운 비밀번호를 재설정</li>
        </ul>
      </section>
      <section className="input__box">
        <TextField
          id="user-id"
          label="ID(email)"
          value={id}
          onChange={(e) => {
            setId(e.target.value);
          }}
        ></TextField>
      </section>
      <section className="button__box">
        <Button onClick={requestForgotPassword} variant="contained" color="primary">
          코드 전송
        </Button>
      </section>
      <section className="help__box">
        <span
          onClick={() => {
            history.push('/login');
          }}
        >
          로그인하기
        </span>
      </section>
    </article>
  );
};
