import React from 'react';
import { DialogProvider, UserProvider, SnackBarProvider, AuthProvider, MemberGroupProvider } from './providers';
import { SnackbarProvider, useSnackbar } from 'notistack';

const Provider = (props) => {
  const { children } = props;

  return (
    // Global
    <DialogProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <UserProvider>
          <AuthProvider>
            <MemberGroupProvider>{children}</MemberGroupProvider>
          </AuthProvider>
        </UserProvider>
      </SnackbarProvider>
    </DialogProvider>
  );
};

export default Provider;
