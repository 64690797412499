import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export const ErrorLog = (props) => {
  const { type, code, message } = props;
  if (!message) {
    return null;
  }
  return (
    <article className="error-log">
      <Tooltip
        title={
          <div className="log-detail">
            <li>
              <header>type</header>
              <span>{type}</span>
            </li>
            <li>
              <header>code</header>
              <span>{code}</span>
            </li>
            <li>
              <header>message</header>
              <p>{message}</p>
            </li>
          </div>
        }
        arrow
      >
        <span className="message">{message}</span>
      </Tooltip>
    </article>
  );
};
