import moment from 'moment';

const dbDateToJsDate = (date) => {
  //db date is setup by Asia/Seoul

  if (date) {
    let subString = date.replace('T', ' ').substring(0, 19);
    let YYYY = subString.substring(0, 4);
    let MM = subString.substring(5, 7);
    let DD = subString.substring(8, 10);
    let HH = subString.substring(11, 13);
    let mm = subString.substring(14, 16);
    let ss = subString.substring(17, 19);
    let returnDate = new Date(0);
    returnDate.setFullYear(YYYY);
    returnDate.setMonth(MM - 1);
    returnDate.setDate(DD);
    returnDate.setHours(HH);
    returnDate.setMinutes(mm);
    returnDate.setSeconds(ss);

    return returnDate;
  } else {
    return null;
  }
};

const dateToDbDate = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return null;
  }
};

const dbDateToYYYYMMDD = (date) => {
  if (date) {
    return date.slice(0, 19).replace('T', ' ');
  } else {
    return null;
  }
};

const dbDateToYYYYMMDDTHHmm = (date) => {
  if (date) {
    let returnValue = date.slice(0, 16);
    returnValue = returnValue.replace(' ', 'T');
    return returnValue;
  } else {
    return null;
  }
};

const dateToYYYYMMDDTHHmm = (date) => {
  if (date) {
    console.log('date', date);
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  } else {
    return null;
  }
};

const dateToYYYYMMDD = (date) => {
  if (date) {
    console.log('date', date);
    return moment(date).format('YYYY-MM-DD');
  } else {
    return null;
  }
};

export const DateUtil = {
  dbDateToJsDate: dbDateToJsDate,
  dateToDbDate: dateToDbDate,
  dbDateToYYYYMMDD: dbDateToYYYYMMDD,
  dbDateToYYYYMMDDTHHmm: dbDateToYYYYMMDDTHHmm,
  dateToYYYYMMDDTHHmm: dateToYYYYMMDDTHHmm,
  dateToYYYYMMDD: dateToYYYYMMDD,
};
