import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';

const buildHexAddress = (value) => {
  if (value) {
    let returnText = '';

    returnText = value.substr(0, 5);
    returnText += '...';
    returnText += value.substr(value.length - 3, value.length);

    return value;
  } else {
    return '';
  }
};

export const KlipAddress = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { address } = props;
  if (!address) {
    return null;
  }
  return (
    <article
      className="klip-address"
      onClick={(e) => {
        e.preventDefault();
        const el = document.createElement('textarea');
        el.value = address;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        enqueueSnackbar('복사되었습니다.', { variant: 'success' });
      }}
    >
      <div className="content__box">
        <span>{buildHexAddress(address)}</span>
        <FileCopyIcon style={{ fontSize: 16, marginLeft: 5, color: '#ffffff' }}></FileCopyIcon>
      </div>
    </article>
  );
};
