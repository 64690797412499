import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export const Loading = () => {
  return (
    <article className="loading">
      <LinearProgress />
    </article>
  );
};
