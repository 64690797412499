import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'service-group/';

const Get = (object) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const Post = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.post(url, body);
};

const Put = (serviceGroupId, body) => {
  const url = BASE_URL + PATH + serviceGroupId;
  return AxiosInstance.put(url, body);
};

const Delete = (serviceGroupId) => {
  const url = BASE_URL + PATH + serviceGroupId;
  return AxiosInstance.delete(url);
};
const GetDetail = (serviceGroupId) => {
  const url = BASE_URL + PATH + serviceGroupId;
  return AxiosInstance.get(url);
};

const ApiKeyRefresh = (serviceGroupId, body) => {
  const url = BASE_URL + PATH + serviceGroupId + '/apikey-refresh';
  return AxiosInstance.put(url, body);
};

const DeletePendingApiKey = (serviceGroupId) => {
  const url = BASE_URL + PATH + serviceGroupId + '/pending-apikey';
  return AxiosInstance.delete(url);
};

const ServiceGroup = {
  Get: Get,
  GetDetail: GetDetail,
  Post: Post,
  Put: Put,
  Delete: Delete,
  ApiKeyRefresh: ApiKeyRefresh,
  DeletePendingApiKey: DeletePendingApiKey,
};

export default ServiceGroup;
