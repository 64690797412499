import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { LoginLayout, MainLayout } from './layouts';
import {
  LoginPage,
  FindPasswordPage,
  MainPage,
  KasListPage,
  KasDetailPage,
  KasCreatePage,
  KasSendPage,
  ServiceGroupListPage,
  ServiceGroupCreatePage,
  ServiceGroupDetailPage,
  ServiceListPage,
  ServiceCreatePage,
  ServiceDetailPage,
  TransferListPage,
  RewardListPage,
  LinkListPage,
  RewardTransferBuilkPage,
  BulkListPage,
  BulkDetailPage,
  AdminUserListPage,
  AdminUserCreatePage,
  AdminUserDetailPage,
  NotFoundPage,
  NotAuthPage,
  ActionLogPage,
  MaintenancePage,
  FixPage,
  KasTransferListPage,
  BiStatisticsUserPage,
  BiStatisticsTransferPage,
  BiStatisticsLinkPage,
  AccountingDetailPage,
  AccountManagementPage,
  AccountingHandwritingPage,
  DealListPage,
} from './pages';

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <LoginLayout path="/login" Component={LoginPage}></LoginLayout>
        <LoginLayout path="/find_password" Component={FindPasswordPage}></LoginLayout>
        <LoginLayout path="/maintenance" Component={FixPage}></LoginLayout>
        <MainLayout exact path="/" Component={MainPage}></MainLayout>
        <MainLayout path="/kas/list" Component={KasListPage}></MainLayout>
        <MainLayout path="/kas/detail/:accountId" Component={KasDetailPage}></MainLayout>
        <MainLayout path="/kas/create" Component={KasCreatePage}></MainLayout>
        <MainLayout path="/kas/send" Component={KasSendPage}></MainLayout>
        <MainLayout path="/kas/transfer_list" Component={KasTransferListPage}></MainLayout>
        <MainLayout path="/service-group/list" Component={ServiceGroupListPage}></MainLayout>
        <MainLayout path="/service-group/create" Component={ServiceGroupCreatePage}></MainLayout>
        <MainLayout path="/service-group/detail/:serviceGroupId" Component={ServiceGroupDetailPage}></MainLayout>
        <MainLayout path="/service/list" Component={ServiceListPage}></MainLayout>
        <MainLayout path="/service/create" Component={ServiceCreatePage}></MainLayout>
        <MainLayout path="/service/detail/:serviceNumber" Component={ServiceDetailPage}></MainLayout>
        <MainLayout path="/transfer/list" Component={TransferListPage}></MainLayout>
        <MainLayout path="/reward/list" Component={RewardListPage}></MainLayout>
        <MainLayout path="/link/list" Component={LinkListPage}></MainLayout>
        <MainLayout path="/reward/transfer/bulk" Component={RewardTransferBuilkPage}></MainLayout>
        <MainLayout path="/bulk/list" Component={BulkListPage}></MainLayout>
        <MainLayout path="/bulk/detail/:bulkSequence" Component={BulkDetailPage}></MainLayout>
        <MainLayout path="/admin-user/list" Component={AdminUserListPage}></MainLayout>
        <MainLayout path="/admin-user/create" Component={AdminUserCreatePage}></MainLayout>
        <MainLayout path="/admin-user/detail/:adminUserId" Component={AdminUserDetailPage}></MainLayout>
        <MainLayout path="/system/action_log" Component={ActionLogPage}></MainLayout>
        <MainLayout path="/system/maintenance" Component={MaintenancePage}></MainLayout>
        <MainLayout path="/bi/statistics/user" Component={BiStatisticsUserPage}></MainLayout>
        <MainLayout path="/bi/statistics/transfer" Component={BiStatisticsTransferPage}></MainLayout>
        <MainLayout path="/bi/statistics/link" Component={BiStatisticsLinkPage}></MainLayout>
        <MainLayout path="/accounting/transfer" Component={AccountingDetailPage}></MainLayout>
        <MainLayout path="/accounting/management" Component={AccountManagementPage}></MainLayout>
        <MainLayout path="/accounting/handwriting" Component={AccountingHandwritingPage}></MainLayout>
        <MainLayout path="/deal/list" Component={DealListPage}></MainLayout>
        <MainLayout path="/not_auth" Component={NotAuthPage}></MainLayout>
        <MainLayout Component={NotFoundPage}></MainLayout>
      </Switch>
    </BrowserRouter>
  );
};
