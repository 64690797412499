import React, { useEffect, useState } from 'react';
import API from '../../api';
import { Form, Loading } from '../../components';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../../utils';

export const ServiceDetailPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const serviceNumber = params.serviceNumber;

  useEffect(() => {
    console.log('effect');
    API.Service.GetDetail(serviceNumber).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        setData(result.value);
      },
      (err) => {
        console.log('err', err);
        setData(true);
      },
    );
  }, []);

  const onChange = (name, value) => {
    let newData = _.cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const requestSave = () => {
    let body = {
      name: data.name,
      description: data.description,
      openDate: data.openDate,
      closeDate: data.closeDate,
    };
    console.log('body', body);
    API.Service.Put(serviceNumber, body).then(
      (res) => {
        console.log('res', res);
        const result = API.Utils.ResultCheck(res);
        if (result) {
          enqueueSnackbar('저장 성공', { variant: 'success' });
        }
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const requestDelete = () => {
    console.log('requestDelete');
  };

  if (!data) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>서비스 상세</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            {/* <li onClick={requestDelete}>
              <Button variant="contained" color="secondary">
                삭제
              </Button>
            </li> */}
            <li>
              <Button
                onClick={requestSave}
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                variant="contained"
                color="primary"
              >
                저장
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <Form title="기본 정보" data={data} type="detail" contentType="service" onChange={onChange}></Form>
    </article>
  );
};
