import React, { useEffect, useState, useContext } from 'react';
import API from '../../api';
import { Form, Loading } from '../../components';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DialogContext } from '../../contexts';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../../utils';

export const ServiceGroupDetailPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [data, setData] = useState(null);
  const [originData, setOriginData] = useState(null);
  const [formProps, setFormProps] = useState(null);
  const [error, setError] = useState(false);
  const serviceGroupId = params.serviceGroupId;
  const { updateDialog } = useContext(DialogContext);

  useEffect(() => {
    console.log('effect');
    API.ServiceGroup.GetDetail(serviceGroupId).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        setData(result.value);
        setOriginData(result.value);
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  }, []);

  const onChange = (name, value) => {
    let newData = _.cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const requestSave = () => {
    let body = {
      name: data.name,
    };

    API.ServiceGroup.Put(serviceGroupId, body).then(
      (res) => {
        console.log('res', res);
        const result = API.Utils.ResultCheck(res);
        if (result) {
          enqueueSnackbar('저장 성공', { variant: 'success' });
        }
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  const onCallback = (type) => {
    console.log('onCallback', type);

    if (type === 'service-group-refresh') {
      updateDialog({
        type: 'caution',
        open: true,
        title: 'API Key 갱신',
        subTitle: '갱신 후에는 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
        onConfirm: () => {
          API.ServiceGroup.ApiKeyRefresh(serviceGroupId, {}).then(
            (res) => {
              console.log('ApiKeyRefresh res', res);
              const result = API.Utils.ResultCheck(res);
              if (result) {
                setData(result.value);
              }
              updateDialog({
                type: 'info',
                open: false,
                title: '',
                subTitle: '',
              });
            },
            (err) => {
              console.log('err', err);
              updateDialog({
                type: 'info',
                open: false,
                title: '',
                subTitle: '',
              });
            },
          );
        },
      });
    }
    if (type === 'service-group-remove-pending') {
      updateDialog({
        type: 'caution',
        open: true,
        title: 'API Key(Deprecated) 삭제',
        subTitle: '삭제 후에는 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
        onConfirm: () => {
          API.ServiceGroup.DeletePendingApiKey(serviceGroupId).then(
            (res) => {
              console.log('res', res);
              const result = API.Utils.ResultCheck(res);
              if (result) {
                setData(result.value);
              }
              updateDialog({
                type: 'info',
                open: false,
                title: '',
                subTitle: '',
              });
            },
            (err) => {
              console.log('err', err);
              updateDialog({
                type: 'info',
                open: false,
                title: '',
                subTitle: '',
              });
            },
          );
        },
      });
    }
  };

  if (!data) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }
  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>서비스 그룹 상세</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            {/* <li onClick={requestDelete}>
              <Button variant="contained" color="secondary">
                삭제
              </Button>
            </li> */}
            <li>
              <Button
                onClick={requestSave}
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                variant="contained"
                color="primary"
              >
                저장
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <Form
        title="기본 정보"
        data={data}
        type="detail"
        contentType="service-group"
        onChange={onChange}
        onCallback={onCallback}
        formProps={formProps}
      ></Form>
    </article>
  );
};
