import React, { useEffect, useState, useContext } from 'react';
import API from '../../api';
import { Error, Loading } from '../../components';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ErrorUtil } from '../../utils';
import { KlaytnUtil } from '../../utils';
import { AuthContext } from '../../contexts';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Modal from '@material-ui/core/Modal';

export const KasSendPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [senderList, setSenderList] = useState(null);
  const [receiverList, setReceiverList] = useState(null);
  const [sender, setSender] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [amount, setAmount] = useState(0);
  const [memo, setMemo] = useState('');
  const [modal, setModal] = useState({
    open: false,
  });
  const [isRequested, setIsRequested] = useState(false);
  const [isDirect, setIsDirect] = useState(false);
  const [directAddress, setDirectAddress] = useState('');
  const { serviceGroupList } = useContext(AuthContext);
  console.log('serviceGroupList', serviceGroupList);

  useEffect(() => {
    setLoading(true);
    updateList();
  }, []);

  useEffect(() => {
    if (isDirect) {
      setReceiver(null);
    } else {
      setDirectAddress('');
    }
  }, [isDirect]);

  const updateList = () => {
    (async function () {
      try {
        const senderList = await API.KAS.Get({
          balance: 1,
          svc_grp_id: serviceGroupList[0].value,
        }).then(
          (res) => {
            console.log('res', res);
            const result = API.Utils.ResultCheck(res);
            console.log('result', result);
            return result.list;
          },
          (err) => {
            console.log('err', err);
          },
        );
        console.log('senderList', senderList);

        const receiverList = await API.KAS.Get({
          balance: 1,
        }).then(
          (res) => {
            console.log('res', res);
            const result = API.Utils.ResultCheck(res);
            console.log('result', result);
            return result.list;
          },
          (err) => {
            console.log('err', err);
          },
        );
        console.log('senderList', senderList);
        console.log('receiverList', receiverList);

        setSenderList(senderList);
        setReceiverList(receiverList);
        setLoading(false);
      } catch (err) {
        console.log('err', err);
        setError(true);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      }
    })();
  };

  const requestSend = () => {
    if (!sender) {
      enqueueSnackbar('발신 계정이 없습니다.', { variant: 'error' });
      return;
    }

    if (isDirect) {
      if (!directAddress) {
        enqueueSnackbar('수신 주소가 없습니다.', { variant: 'error' });
        return;
      }
    } else {
      if (!receiver) {
        enqueueSnackbar('수신 계정이 없습니다.', { variant: 'error' });
        return;
      }
    }

    if (!amount) {
      enqueueSnackbar('수량을 입력해주세요.', { variant: 'error' });
      return;
    }
    let body = null;
    if (isDirect) {
      body = {
        from: sender.accnt_id,
        from_address: sender.address,
        to: 'unknown',
        to_address: directAddress,
        amount: amount,
        memo: memo,
      };
    } else {
      body = {
        from: sender.accnt_id,
        from_address: sender.address,
        to: receiver.accnt_id,
        to_address: receiver.address,
        amount: amount,
        memo: memo,
      };
    }

    console.log('body', body);

    setIsRequested(true);
    API.KAS.SendKlay(body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        enqueueSnackbar('전송 성공', { variant: 'success' });
        setIsRequested(false);
        setModal({
          ...modal,
          open: false,
        });
        updateList();
      },
      (err) => {
        console.log('err', err);
        setIsRequested(false);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
    console.log('requestSend', body);
  };

  const onCloseModal = () => {
    setModal({
      ...modal,
      open: false,
    });
  };

  if (error) {
    return (
      <article className="content-page klaytn">
        <Error></Error>
      </article>
    );
  }
  if (loading || !senderList) {
    return (
      <article className="content-page klaytn">
        <Loading></Loading>
      </article>
    );
  }
  return (
    <article className="content-page klaytn">
      <header>
        <section>
          <div className="title">
            <h1>Klay 전송</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            <li>
              <Button
                onClick={() => {
                  setModal({
                    ...modal,
                    open: true,
                  });
                }}
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                variant="contained"
                color="primary"
              >
                전송
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <article className="kas-send__container">
        <section className="send-info__box">
          <header>전송 요약</header>
          <div className="info">
            <div className="account">
              <header>발신 계정</header>
              <div className="value">
                {sender ? (
                  <>
                    <span className="sender">{sender.name}</span>
                    <div>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          setSender(null);
                          setReceiver(null);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </>
                ) : (
                  <span className="sender">발신 계정을 선택하세요.</span>
                )}
              </div>
            </div>

            <div className="account">
              <header>
                {isDirect ? '수신 주소' : '수신 계정'}
                {sender &&
                  (isDirect ? (
                    <div
                      className="direct__icon"
                      onClick={() => {
                        setIsDirect(false);
                      }}
                    >
                      <span>직접 입력 취소</span>
                    </div>
                  ) : (
                    <div
                      className="direct__icon"
                      onClick={() => {
                        setIsDirect(true);
                      }}
                    >
                      <span>직접 입력</span>
                    </div>
                  ))}
              </header>
              <div className="value">
                {sender &&
                  // 0xAdc7DB3104B011D5A1A9BAFc1D209B34Af5Ee607 -> joy klip address
                  (isDirect ? (
                    <TextField
                      fullWidth
                      type="text"
                      value={directAddress}
                      inputProps={{
                        min: 0,
                      }}
                      className="address-input"
                      onChange={(e) => {
                        setDirectAddress(e.target.value);
                      }}
                    ></TextField>
                  ) : receiver ? (
                    <>
                      <span className="receiver">{receiver.name}</span>
                      <div>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setReceiver(null);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </>
                  ) : (
                    <span className="receiver">수신 계정을 선택하세요.</span>
                  ))}
              </div>
            </div>
            <div className="klay">
              <FormControl fullWidth variant="outlined">
                <TextField
                  fullWidth
                  type="number"
                  value={amount}
                  label={'수량(klay)'}
                  variant="outlined"
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                ></TextField>
              </FormControl>
            </div>
            <div className="memo">
              <FormControl fullWidth>
                <TextareaAutosize
                  maxLength={100}
                  style={{ padding: 10, borderRadius: 5, borderColor: 'rgba(0,0,0,0.23)' }}
                  value={memo}
                  onChange={(e) => {
                    setMemo(e.target.value);
                  }}
                  placeholder={'메모 - 최대 100 글자'}
                />
              </FormControl>
            </div>
          </div>
        </section>

        <section className="account-list__box" style={{ marginBottom: 40 }}>
          <header>발신 계정 선택</header>
          <ul>
            <li className="header">
              <div>이름</div>
              <div>계정 아이디</div>
              <div>서비스 그룹 아이디</div>
              <div className="address">주소</div>
              <div className="klay">잔액</div>
            </li>
            {senderList.map((item, index) => {
              let itemClassName = null;

              if (sender && item.accnt_id === sender.accnt_id) {
                itemClassName = 'sender';
              }

              return (
                <li
                  className={itemClassName}
                  key={'kas-send-list-' + index}
                  onClick={() => {
                    if (sender && sender.accnt_id === item.accnt_id) {
                      setSender(null);
                      setReceiver(null);
                      return;
                    }
                    setSender(item);
                    // if (receiver && receiver.accnt_id === item.accnt_id) {
                    //   setReceiver(null);
                    //   return;
                    // }

                    // if (sender) {
                    //   if (isDirect) {
                    //     enqueueSnackbar('직접 입력 상태입니다.', { variant: 'warning' });
                    //     return;
                    //   }
                    //   if (sender.accnt_id === item.accnt_id) {
                    //     enqueueSnackbar('수신 계정과 송신 계정이 같습니다.', { variant: 'warning' });
                    //   } else {
                    //     setReceiver(item);
                    //   }
                    // } else {
                    //   setSender(item);
                    // }
                  }}
                >
                  <div>{item.name}</div>
                  <div>{item.accnt_id}</div>
                  <div>{item.svc_grp_id}</div>
                  <div className="address">{item.address}</div>
                  <div className="klay">{KlaytnUtil.pebToKlay(item.currentBalance)}</div>
                </li>
              );
            })}
          </ul>
        </section>

        <section className="account-list__box">
          <header>수신 계정 선택</header>
          <ul>
            <li className="header">
              <div>이름</div>
              <div>계정 아이디</div>
              <div>서비스 그룹 아이디</div>
              <div className="address">주소</div>
              <div className="klay">잔액</div>
            </li>
            {receiverList.map((item, index) => {
              let itemClassName = null;

              if (sender && item.accnt_id === sender.accnt_id) {
                return null;
              }
              if (receiver && item.accnt_id === receiver.accnt_id) {
                itemClassName = 'receiver';
              }

              return (
                <li
                  className={itemClassName}
                  key={'kas-send-list-' + index}
                  onClick={() => {
                    if (!sender) {
                      enqueueSnackbar('발신 계정을 선택해주세요.', { variant: 'warning' });
                      return;
                    }
                    if (isDirect) {
                      enqueueSnackbar('직접 입력 상태입니다.', { variant: 'warning' });
                      return;
                    }
                    if (receiver && receiver.accnt_id === item.accnt_id) {
                      setReceiver(null);
                      return;
                    }
                    setReceiver(item);
                    // if (sender) {
                    //   if (isDirect) {
                    //     enqueueSnackbar('직접 입력 상태입니다.', { variant: 'warning' });
                    //     return;
                    //   }
                    //   if (sender.accnt_id === item.accnt_id) {
                    //     enqueueSnackbar('수신 계정과 송신 계정이 같습니다.', { variant: 'warning' });
                    //   } else {
                    //     setReceiver(item);
                    //   }
                    // } else {
                    //   setSender(item);
                    // }
                  }}
                >
                  <div>{item.name}</div>
                  <div>{item.accnt_id}</div>
                  <div>{item.svc_grp_id}</div>
                  <div className="address">{item.address}</div>
                  <div className="klay">{KlaytnUtil.pebToKlay(item.currentBalance)}</div>
                </li>
              );
            })}
          </ul>
        </section>
      </article>

      <Modal open={modal.open} onClose={onCloseModal}>
        <div className="modal-container">
          <header>
            <h1>Klay 전송 확인</h1>
          </header>
          <article className="modal-body">
            <section className="content__box">
              <ul>
                <li>
                  <header>발신 계정</header>
                  <span className="data">{sender && sender.name}</span>
                </li>
                {isDirect ? (
                  <li>
                    <header>수신 주소</header>
                    <span className="data">{directAddress}</span>
                  </li>
                ) : (
                  <li>
                    <header>수신 계정</header>
                    <span className="data">{receiver && receiver.name}</span>
                  </li>
                )}

                <li>
                  <header>수량(klay)</header>
                  <span className="data">{amount}</span>
                </li>
                <li>
                  <header>메모</header>
                  <p className="data">{memo}</p>
                </li>
              </ul>
            </section>
            <section className="button__box">
              <Button color="default" variant="contained" onClick={onCloseModal} style={{ marginRight: 10 }}>
                취소
              </Button>
              <Button color="primary" variant="contained" onClick={requestSend} disabled={isRequested}>
                전송
              </Button>
            </section>
          </article>
        </div>
      </Modal>
    </article>
  );
};
