import React from 'react';
import { Router } from './Router';
import './styles/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Amplify from 'aws-amplify';
import { Variables } from './utils/';
import Provider from './Provider';
import { DialogLayer, SnackBarLayer } from './layers';

Amplify.configure(Variables.amplifyConfig);

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#03a1c8',
    },
    secondary: {
      main: '#d83554',
    },
  },
});

function App() {
  console.log('env', process.env);
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Provider>
          <DialogLayer></DialogLayer>
          <Router></Router>
        </Provider>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
