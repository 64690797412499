import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DateUtil, AdminUtil } from '../utils';
import { AuthContext } from '../contexts';
import { useSnackbar } from 'notistack';

const renderHeaderText = (value) => {
  switch (value) {
    //kas
    case 'accnt_id': {
      return 'Kalytn 계정 아이디';
    }
    case 'name': {
      return '이름';
    }
    case 'address': {
      return 'Klaytn 계정 주소';
    }
    //service-group
    case 'svc_grp_id': {
      return '서비스 그룹 아이디';
    }
    case 'api_key_id': {
      return 'API Key 아이디';
    }
    case 'api_key_value': {
      return 'API Key 값';
    }
    case 'pending_api_key_id': {
      return 'API Key 아이디(Deprecated)';
    }
    case 'pending_api_key_value': {
      return 'API Key 값(Deprecated)';
    }
    //service
    case 'serviceNumber': {
      return '서비스 번호';
    }
    case 'memberGroupId': {
      return '멤버 그룹 아이디';
    }
    case 'description': {
      return '설명';
    }
    case 'openDate': {
      return '시작일';
    }
    case 'closeDate': {
      return '종료일';
    }
    case 'regDate': {
      return '등록일';
    }
    default: {
      return 'text';
    }
  }
};

export const Card = (props) => {
  const history = useHistory();
  const { auth, authArray } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { data, type, path } = props;
  console.log('data', data);

  let rootClassName = 'card-item';
  if (type) {
    rootClassName += ' ' + type;
  }
  return (
    <article
      className={rootClassName}
      onClick={() => {
        if (path) {
          console.log('auth auth', auth);

          console.log('auth path', path);
          let targetItem = AdminUtil.searchByUrl(auth, path);
          let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);

          console.log('targetItem', targetItem);
          console.log('linkAuthType', linkAuthType);
          if (linkAuthType) {
            history.push({
              pathname: path,
              state: { pageAuthType: linkAuthType },
            });
          } else {
            enqueueSnackbar('디테일 권한이 없습니다.', { variant: 'warning' });
          }
        }
      }}
    >
      {/* <header>{type}</header> */}
      <ul>
        {Object.keys(data).map((key) => {
          return (
            <li className="row">
              <header>
                <span>{renderHeaderText(key)}</span>
              </header>
              <div className="value">
                <span>
                  {key === 'openDate' || key === 'closeDate' || key === 'regDate'
                    ? DateUtil.dbDateToYYYYMMDD(data[key])
                    : data[key]}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </article>
  );
};
