import React, { useEffect, useState, useContext } from 'react';
import queryString from 'query-string';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash } from '../../components';
import { useFilter, usePagination } from '../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from '../../utils';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { AdminUtil, ErrorUtil } from '../../utils';
import { DialogContext, AuthContext } from '../../contexts';
import GetAppIcon from '@material-ui/icons/GetApp';
import XLSX from 'xlsx';

export const AccountingDetailPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const { updateDialog } = useContext(DialogContext);
  const { serviceGroupList } = useContext(AuthContext);
  const [accountAllList, setAccountAllList] = useState(null);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [filter, onChangeFilter, setFilter] = useFilter([
    {
      required: true,
      id: 'service-group-id',
      component: 'Select',
      value: serviceGroupList && serviceGroupList.length > 0 ? serviceGroupList[0].value : '',
      menus: [],
      label: '서비스 그룹 아이디',
      key: 'serviceGroupId',
    },
    {
      required: true,
      id: 'account_id',
      component: 'Select',
      value: '',
      menus: [],
      label: '계좌 선택',
      key: 'account_id',
    },
    {
      required: true,
      id: 'date_type',
      component: 'Select',
      value: 'day',
      menus: [
        {
          text: '일',
          value: 'day',
        },
        {
          text: '월',
          value: 'month',
        },
        {
          text: '연도',
          value: 'year',
        },
      ],
      label: '날짜 구분',
      key: 'accounting_dateType',
    },
    {
      required: true,
      id: 'date_picker',
      component: 'Date-Picker',
      value: yesterday,
      key: 'dateValue',
      dateFormat: 'yyyy-MM-dd',
      label: '기간 선택(일)',
      showMonthYearPicker: false,
      showYearPicker: false,
      disabled: false,
    },
  ]);

  useEffect(() => {
    try {
      API.KAS.Get({
        svc_grp_id: serviceGroupList[0].value,
      }).then(
        (res) => {
          console.log('res', res);
          const result = API.Utils.ResultCheck(res);
          console.log('result', result);
          setAccountAllList(result.list);
          setLoading(false);
        },
        (err) => {
          console.log('err', err);
          setError(true);
        },
      );
    } catch (err) {
      enqueueSnackbar('페이지 전환 후, 다시 시도해주십시오.', { variant: 'error' });
      setLoading(false);
      setError(true);
    }
  }, []);

  useEffect(() => {
    console.log('[USE Effect ]accountAllList', accountAllList);
    if (accountAllList && accountAllList.length > 0) {
      //초기값 셋팅 후 accountAllList는 변하지 않기 때문에, accountAllList의 effect는 한번만 이루어짐 = 초기값 셋팅을 의미하는 useEffect
      try {
        updateAccountList(serviceGroupList[0].value, true);
      } catch (err) {
        setError(true);
      }
    }
  }, [accountAllList]);

  useEffect(() => {
    console.log('filter[0].value', filter[0].value);
    if (filter[0].value && accountAllList) {
      let targetServiceGroup = filter[0].value;
      updateAccountList(targetServiceGroup);
    }
  }, [filter[0].value]);

  const updateAccountList = (targetServiceGroup, searchFlag) => {
    console.log('updateAccountList', targetServiceGroup);
    console.log('accountAllList', accountAllList);
    let selectedAccountList = [];

    for (let i in accountAllList) {
      let targetAccount = accountAllList[i];
      if (_.includes(targetServiceGroup, targetAccount.svc_grp_id)) {
        selectedAccountList.push(targetAccount);
      }
    }
    console.log('selectedAccountList', selectedAccountList);
    if (selectedAccountList.length === 0) {
      let newFilter = _.cloneDeep(filter);
      newFilter[1].menus = [];
      newFilter[1].value = '';
      setFilter(newFilter);
      return;
    }
    // create menus
    let accountFilterMenus = [];
    let initMenuValue = '';

    for (let i in selectedAccountList) {
      let menuItem = {};
      menuItem.value = selectedAccountList[i].accnt_id;
      menuItem.text = selectedAccountList[i].name + '(' + selectedAccountList[i].accnt_id + ')';
      accountFilterMenus.push(menuItem);
    }
    initMenuValue = accountFilterMenus[0].value;

    let newFilter = _.cloneDeep(filter);
    newFilter[1].menus = accountFilterMenus;
    newFilter[1].value = initMenuValue;
    setFilter(newFilter);

    if (searchFlag) {
      onSearch(newFilter);
    }
  };

  const onSearch = (newFilter) => {
    console.log('onSearch');
    let filterParams = FilterUtil.dataToParams(filter);
    if (newFilter) {
      filterParams = FilterUtil.dataToParams(newFilter);
    }
    console.log('filterParams', filterParams);
    let dateType = filterParams.accounting_dateType;

    let params = {
      accountId: filterParams.account_id,
      dateType: dateType,
      year: filterParams.dateValue.getFullYear(),
      month: filterParams.dateValue.getMonth() + 1,
      day: filterParams.dateValue.getDate(),
    };

    console.log('params', params);
    setDataLoading(true);
    API.Accounting.Get(params).then(
      (res) => {
        console.log('res', res);
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result) {
          setList(result.list);
        }
        setDataLoading(false);
      },
      (err) => {
        console.log('err', err);
        setDataLoading(false);
      },
    );
  };

  const getAccontNameById = (id) => {
    let resultName = '';
    for (let i in accountAllList) {
      let targetAccount = accountAllList[i];
      if (targetAccount.accnt_id === id) {
        resultName = targetAccount.name;
        break;
      }
    }
    return resultName;
  };

  const requestExcelDownload = () => {
    let dataList = _.cloneDeep(list);
    console.log('dataList', dataList);

    var wb = XLSX.utils.book_new();

    for (let i in dataList) {
      let targetData = dataList[i];
      console.log('targetData', targetData);
      let keyName = targetData.accnt_id;
      var ws = XLSX.utils.json_to_sheet([{ type: '출금 내역' }], { skipHeader: true });

      let withdrawalList = targetData.stats.withdrawal;
      let withdrawalArray = [];

      withdrawalArray.push({
        key: '이름',
        value: '가격',
      });
      for (let i in withdrawalList) {
        let target = withdrawalList[i];
        let amount = {
          key: target.svc_name || '외부',
          value: target.amount_sum,
        };
        withdrawalArray.push(amount);
        let fee = {
          key: target.svc_name ? target.svc_name + '(수수료)' : '외부(수수료)',
          value: target.fee_sum,
        };
        withdrawalArray.push(fee);
      }
      console.log('withdrawalArray', withdrawalArray);

      XLSX.utils.sheet_add_json(ws, withdrawalArray, { skipHeader: true, origin: -1 });

      let depositList = targetData.stats.deposit;
      let depositArray = [];

      XLSX.utils.sheet_add_json(
        ws,
        [
          {
            type: '입금 내역',
          },
        ],
        { skipHeader: true, origin: -1 },
      );

      depositArray.push({
        key: '이름',
        value: '가격',
      });
      for (let i in depositList) {
        let target = depositList[i];
        let amount = {
          key: target.svc_name || '외부',
          value: target.amount_sum,
        };
        depositArray.push(amount);
        let fee = {
          key: target.svc_name ? target.svc_name + '(수수료)' : '외부(수수료)',
          value: target.fee_sum,
        };
        depositArray.push(fee);
      }
      console.log('depositArray', depositArray);

      XLSX.utils.sheet_add_json(ws, depositArray, { skipHeader: true, origin: -1 });

      XLSX.utils.sheet_add_json(
        ws,
        [
          {
            type: '잔액 정보',
          },
        ],
        { skipHeader: true, origin: -1 },
      );
      XLSX.utils.sheet_add_json(
        ws,
        [
          {
            key: '기초 잔액',
            value: targetData.info.openingBalance,
          },
          {
            key: '기말 잔액',
            value: targetData.info.endingBalance,
          },
        ],
        { skipHeader: true, origin: -1 },
      );

      XLSX.utils.book_append_sheet(wb, ws, keyName);
    }
    XLSX.writeFile(wb, 'shift_accounting.xlsx');
  };

  console.log('[RENDER]', accountAllList);
  if (loading) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>회계/정산</h1>
          </div>
        </section>
        <ul className="option-list">
          <li>
            <Button disabled={!list || dataLoading} color="primary" variant="contained" onClick={requestExcelDownload}>
              <GetAppIcon style={{ marginRight: 5, fontSize: 18 }}></GetAppIcon>EXPORT EXCEL
            </Button>
          </li>
        </ul>
      </header>
      <section>
        <Filter data={filter} updateData={onChangeFilter} onSearch={() => onSearch()} loading={dataLoading}></Filter>
      </section>
      {dataLoading && <Loading></Loading>}
      {!dataLoading && list && (
        <section className="account__container">
          {list.map((item, index) => {
            console.log('item', item);
            const withdrawal = item.stats.withdrawal;
            const deposit = item.stats.deposit;
            const info = item.info;

            return (
              <article className="account__item">
                <header>{getAccontNameById(item.accnt_id)}</header>
                <div className="account__table">
                  <header>
                    <div>출금</div>
                    <div>입금</div>
                  </header>
                  <div className="body">
                    <div className="withdrawal">
                      <ul>
                        {withdrawal.map((withdrawalItem, withdrawalIndex) => {
                          return (
                            <>
                              <li>
                                <span className="header">{withdrawalItem.svc_name || '외부'}</span>
                                <span>{KlaytnUtil.pebToKlay(withdrawalItem.amount_sum)}</span>
                              </li>
                              <li>
                                <span className="header">
                                  {withdrawalItem.svc_name ? withdrawalItem.svc_name + ' 수수료' : '외부 수수료'}
                                </span>
                                <span>{KlaytnUtil.pebToKlay(withdrawalItem.fee_sum)}</span>
                              </li>
                            </>
                          );
                        })}
                        {withdrawal.length === 0 && (
                          <li className="empty">
                            <span>거래 내역 없음</span>
                          </li>
                        )}
                      </ul>
                      <div className="balance">
                        <span className="header">기말 잔액</span>
                        <span>{KlaytnUtil.pebToKlay(info.endingBalance)}</span>
                      </div>
                      <ul className="summary">
                        <header>요약</header>
                        <li>
                          <sapn className="header">출금 총액</sapn>
                          <span>{KlaytnUtil.pebToKlay(info.withdrawalSum)}</span>
                        </li>
                        <li>
                          <sapn className="header">기말 잔액</sapn>
                          <span>{KlaytnUtil.pebToKlay(info.endingBalance)}</span>
                        </li>
                        <li className="total">
                          <span className="header">합계</span>
                          <span className="total">{KlaytnUtil.pebToKlay(info.withdrawTotal)}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="deposit">
                      <div className="balance">
                        <span className="header">기초 잔액</span>
                        <span>{KlaytnUtil.pebToKlay(info.openingBalance)}</span>
                      </div>
                      <ul>
                        {deposit.map((depositItem, depositIndex) => {
                          return (
                            <>
                              <li>
                                <span className="header">{depositItem.svc_name || '외부'}</span>
                                <div>{KlaytnUtil.pebToKlay(depositItem.amount_sum)}</div>
                              </li>
                              <li>
                                <span className="header">
                                  {depositItem.svc_name ? depositItem.svc_name + ' 수수료' : '외부 수수료'}
                                </span>
                                <span>{KlaytnUtil.pebToKlay(depositItem.fee_sum)}</span>
                              </li>
                            </>
                          );
                        })}
                        {deposit.length === 0 && (
                          <li className="empty">
                            <span>거래 내역 없음</span>
                          </li>
                        )}
                      </ul>
                      <ul className="summary">
                        <header>요약</header>
                        <li>
                          <sapn className="header">기초 잔액</sapn>
                          <span>{KlaytnUtil.pebToKlay(info.openingBalance)}</span>
                        </li>
                        <li>
                          <sapn className="header">입금 총액</sapn>
                          <span>{KlaytnUtil.pebToKlay(info.depositSum)}</span>
                        </li>
                        <li className="total">
                          <span className="header">합계</span>
                          <span className="total">{KlaytnUtil.pebToKlay(info.depositTotal)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </article>
            );
          })}
        </section>
      )}
    </article>
  );
};
