import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'kas/';

const Get = (object) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const Post = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.post(url, body);
};

const Put = (accountId, body) => {
  const url = BASE_URL + PATH + accountId;
  return AxiosInstance.put(url, body);
};

const Delete = (accountId) => {
  const url = BASE_URL + PATH + accountId;
  return AxiosInstance.delete(url);
};
const GetDetail = (accountId) => {
  const url = BASE_URL + PATH + accountId;
  return AxiosInstance.get(url);
};

const SendKlay = (body) => {
  const url = BASE_URL + PATH + 'send';
  return AxiosInstance.post(url, body);
};

const TransferList = (object) => {
  const url = BASE_URL + PATH + 'transfer_list';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const KAS = {
  Get: Get,
  GetDetail: GetDetail,
  Post: Post,
  Put: Put,
  Delete: Delete,
  SendKlay: SendKlay,
  TransferList: TransferList,
};

export default KAS;
