import React, { useState, useEffect } from 'react';
import { AuthContext } from '../contexts';

export const AuthProvider = (props) => {
  const { children } = props;
  const [auth, setAuth] = useState(null);
  const [authArray, setAuthArray] = useState([]);
  const [navigationList, setNavigationList] = useState([]);
  const [selectedToolId, setSelectedToolId] = useState(null);
  const [serviceGroupList, setServiceGroupList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  console.log('auth', auth);
  console.log('authArray', authArray);
  console.log('serviceGroupList', serviceGroupList);
  console.log('serviceList', serviceList);

  const updateAuth = (value) => {
    setAuth(value);
  };

  const updateSelectedToolId = (value) => {
    setSelectedToolId(value);
  };

  const updateAuthArray = (value) => {
    setAuthArray(value);
  };

  const updateServiceGroupList = (value) => {
    setServiceGroupList(value);
  };

  const updateServiceList = (value) => {
    setServiceList(value);
  };

  useEffect(() => {
    console.log('auth effect', auth);
    // build navigation list
    if (auth) {
    } else {
      setNavigationList([]);
      setSelectedToolId([]);
    }
  }, [auth]);

  useEffect(() => {
    // build navigation list
    console.log('effect selectedToolId', selectedToolId);
    if (auth && auth.children && auth.children.length > 0) {
      let selectedGroup = [];

      for (let toolItem of auth.children) {
        if (toolItem.part_seq === selectedToolId) {
          console.log('effect toolItem', toolItem);
          if (toolItem.children && toolItem.children.length > 0) {
            selectedGroup = toolItem.children;
          }
        }
      }
      console.log('effect selectedGroup', selectedGroup);
      setNavigationList(selectedGroup);
    }
  }, [selectedToolId]);

  return (
    <AuthContext.Provider
      value={{
        auth: auth,
        updateAuth: updateAuth,
        selectedToolId: selectedToolId,
        updateSelectedToolId: updateSelectedToolId,
        navigationList: navigationList,
        authArray: authArray,
        updateAuthArray: updateAuthArray,
        serviceGroupList: serviceGroupList,
        updateServiceGroupList: updateServiceGroupList,
        serviceList: serviceList,
        updateServiceList: updateServiceList,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
