import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'system/';

const GetActionLogList = (object) => {
  const url = BASE_URL + PATH + 'action_log';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const System = {
  GetActionLogList: GetActionLogList,
};

export default System;
