const transferType = (value) => {
  switch (value) {
    case 'rwd': {
      return '리워드';
    }
    case 'pay': {
      return '지불';
    }
    default: {
      return '';
    }
  }
};

export const EnumUtils = {
  transferType: transferType,
};
