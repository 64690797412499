import React, { useEffect } from 'react';
import API from '../api';
import { Auth } from 'aws-amplify';
import moment from 'moment-timezone';

export const NotFoundPage = (props) => {
  return (
    <article className="main-page">
      <span>NotFound</span>
    </article>
  );
};
