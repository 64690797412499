// export const BASE_URL = 'https://qmwmi2k708.execute-api.ap-northeast-2.amazonaws.com/Prod/admin/';
const BASE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://znok9bdp05.execute-api.ap-northeast-2.amazonaws.com/Prod/admin/'
    : 'https://jpl6hijz4j.execute-api.ap-northeast-2.amazonaws.com/Prod/admin/';

const BACKOFFICE_BASE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://znok9bdp05.execute-api.ap-northeast-2.amazonaws.com/Prod/'
    : 'https://jpl6hijz4j.execute-api.ap-northeast-2.amazonaws.com/Prod/';

const USER_BASE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://fj0k0qm1o7.execute-api.ap-northeast-2.amazonaws.com/Prod/'
    : 'https://9oqjglflm7.execute-api.ap-northeast-2.amazonaws.com/Prod/';

const BACKEND_BASE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://uki2jdfvsc.execute-api.ap-northeast-2.amazonaws.com/Prod/'
    : 'https://56736f7def.execute-api.ap-northeast-2.amazonaws.com/Prod/';

export { BASE_URL, BACKOFFICE_BASE_URL, USER_BASE_URL, BACKEND_BASE_URL };
