import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'link/';

const GetList = (object) => {
  const url = BASE_URL + PATH + 'list';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const Delete = (object) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.delete(url + Utils.ObjectToParams(object));
};

const Link = {
  GetList: GetList,
  Delete: Delete,
};

export default Link;
