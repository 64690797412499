import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'admin-user/';

const GetList = (object) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const GetDetail = (adminUserId) => {
  const url = BASE_URL + PATH + adminUserId;
  return AxiosInstance.get(url);
};

const Post = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.post(url, body);
};

const Put = (adminUserId, body) => {
  const url = BASE_URL + PATH + adminUserId;
  return AxiosInstance.put(url, body);
};

const getDefaultBackofficePart = () => {
  const url = BASE_URL + PATH + 'default-backoffice-part';
  return AxiosInstance.get(url);
};

const Delete = (adminUserId) => {
  const url = BASE_URL + PATH + adminUserId;
  return AxiosInstance.delete(url);
};

const AdminUser = {
  GetList: GetList,
  GetDetail: GetDetail,
  Post: Post,
  Delete: Delete,
  Put: Put,
  getDefaultBackofficePart: getDefaultBackofficePart,
};

export default AdminUser;
