import React, { useEffect, useState, useContext } from 'react';
import queryString from 'query-string';
import API from '../../api';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Error, Loading, Card, Filter, Paging, TableHeader, KlaytnHash } from '../../components';
import { useFilter, usePagination } from '../../hooks';
import { DateUtil, FilterUtil, KlaytnUtil, EnumUtils } from '../../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import { useSnackbar } from 'notistack';
import { ErrorUtil, AdminUtil } from '../../utils';
import { AuthContext } from '../../contexts';
import moment from 'moment-timezone';

export const BulkListPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState(null);
  const [error, setError] = useState(false);
  const [page, totalPage, onPageChange, setPage, setTotalPage] = usePagination(1, 0);
  const [totalCount, setTotalCount] = useState(null);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [listLoading, setListLoading] = useState(false);
  const { auth, authArray, serviceGroupList, serviceList } = useContext(AuthContext);

  console.log('totalPage', totalPage);
  console.log('totalCount', totalCount);

  let now = moment(new Date()).format('YYYY-MM-DDTHH:mm');
  let firstDay = new Date();
  firstDay.setDate(1);
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay = moment(firstDay).format('YYYY-MM-DDTHH:mm');
  let BULK_FILTER = [
    {
      id: 'start-date-time',
      component: 'TextField',
      type: 'datetime-local',
      value: firstDay,
      label: '시작일',
      inputProps: {},
      InputLabelProps: {
        shrink: true,
      },
      key: 'startDate',
    },
    {
      id: 'end-date-time',
      component: 'TextField',
      type: 'datetime-local',
      value: now,
      label: '종료일',
      inputProps: {},
      InputLabelProps: {
        shrink: true,
      },
      key: 'endDate',
    },
    {
      id: 'option-type',
      component: 'Select',
      value: 'service',
      menus: [
        {
          text: '서비스',
          value: 'service',
        },
        {
          text: '이메일',
          value: 'email',
        },
      ],
      label: '옵션 타입',
      key: 'optionType',
    },
    {
      required: true,
      disabled: false,
      id: 'option-service-value',
      component: 'Select',
      value: serviceList && serviceList.length > 0 ? serviceList[0].value : '',
      label: '서비스 번호',
      menus: [],
      key: 'optionValue',
    },
    {
      required: true,
      disabled: true,
      id: 'option-email-value',
      component: 'TextField',
      value: '',
      label: '이메일',
      type: 'text',
      key: 'optionValue',
    },
  ];

  const [filter, onChangeFilter, setFilter] = useFilter(BULK_FILTER);

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    if (list) {
      requestNewList((page - 1) * pagePerRow);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (list) {
      requestNewList(0);
    }
  }, [pagePerRow]);

  const requestNewList = (pageOffset) => {
    let filterParams = FilterUtil.dataToParams(filter);
    console.log('requestNewList filterParams', filterParams);

    let params = {
      ...filterParams,
      pageOffset: pageOffset,
      pageSize: pagePerRow,
    };

    console.log('params', params);
    setListLoading(true);

    API.Bulk.GetList(params).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('Reward GetList', result);
        if (result) {
          setList(result.list);
          setTotalCount(result.totalCount);
          setTotalPage(Math.ceil(result.totalCount / pagePerRow));
        }
        setListLoading(false);
      },
      (err) => {
        console.log('err', err);
        setListLoading(false);

        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };
  const onSearch = () => {
    setPage(1);
    requestNewList(0);
  };

  if (error) {
    return (
      <article className="content-page">
        <Error></Error>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>벌크 목록</h1>
          </div>
        </section>
      </header>
      <section>
        <Filter data={filter} updateData={onChangeFilter} onSearch={onSearch} loading={listLoading}></Filter>
      </section>
      {list && (
        <>
          <TableHeader totalCount={totalCount} pagePerRow={pagePerRow} onChange={setPagePerRow}></TableHeader>
          <TableContainer component={'div'} style={{ background: '#ffffff' }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: 60 }}>
                  <TableCell align="center">벌크 시퀀스</TableCell>
                  <TableCell align="center">메모</TableCell>
                  <TableCell align="center">서비스 번호</TableCell>
                  <TableCell align="center">서비스 이름</TableCell>
                  <TableCell align="center">이메일</TableCell>
                  <TableCell align="center">이름</TableCell>
                  <TableCell align="center">회사(소속)</TableCell>
                  <TableCell align="center">총 요청</TableCell>
                  <TableCell align="center">성공 요청</TableCell>
                  <TableCell align="center">총 수량(Klay)</TableCell>
                  <TableCell align="center">고정 수량(Klay)</TableCell>
                  <TableCell align="center">링크 확인</TableCell>
                  <TableCell align="center">등록일</TableCell>
                  <TableCell align="center">예약일</TableCell>
                  <TableCell align="center">만료일</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((item, index) => {
                    console.log('item', item);
                    return (
                      <TableRow
                        className="data-link-row"
                        key={'service-row-' + index}
                        style={{ height: 60 }}
                        onClick={() => {
                          let targetItem = AdminUtil.searchByUrl(auth, '/bulk/detail/');
                          let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);
                          console.log('targetItem', targetItem);
                          console.log('linkAuthType', linkAuthType);
                          if (linkAuthType) {
                            history.push({
                              pathname: '/bulk/detail/' + item.bulk_seq,
                              state: { pageAuthType: linkAuthType },
                            });
                          }
                        }}
                      >
                        <TableCell align="center">{item.bulk_seq}</TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <p style={{ fontSize: 13, lineHeight: '20px', whiteSpace: 'pre-line' }}>{item.memo}</p>
                            }
                            arrow
                          >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <span
                                style={{
                                  maxWidth: 160,
                                  overflow: 'hidden',
                                  display: 'block',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item.memo}
                              </span>
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">{item.svc_num}</TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={<span style={{ fontSize: 13, lineHeight: '20px' }}>{item.svc_desc}</span>}
                            arrow
                          >
                            <span>{item.svc_name}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">{item.email}</TableCell>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">{item.company}</TableCell>
                        <TableCell align="center">{item.total_count}</TableCell>
                        <TableCell align="center">{item.success_count}</TableCell>
                        <TableCell align="center">{KlaytnUtil.pebToKlay(item.total_amount)}</TableCell>
                        <TableCell align="center">{KlaytnUtil.pebToKlay(item.fixed_amount)}</TableCell>
                        <TableCell align="center">{item.link_check === 1 ? 'Y' : 'N'}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.reg_dt)}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.reserve_dt)}</TableCell>
                        <TableCell align="center">{DateUtil.dbDateToYYYYMMDD(item.expire_dt)}</TableCell>
                      </TableRow>
                    );
                  })}
                {list && list.length === 0 && (
                  <TableRow key={'service-row-empty'}>
                    <TableCell>데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
        </>
      )}
    </article>
  );
};
