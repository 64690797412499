import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'bulk/';

const GetDetail = (bulkSequence, object) => {
  const url = BASE_URL + PATH + bulkSequence;
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const GetList = (object) => {
  const url = BASE_URL + PATH + 'list';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const LinkCheck = (body) => {
  const url = BASE_URL + PATH + 'link-check';
  return AxiosInstance.post(url, body);
};
const Post = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.post(url, body);
};

const Bulk = {
  GetDetail: GetDetail,
  GetList: GetList,
  Post: Post,
  LinkCheck: LinkCheck,
};

export default Bulk;
