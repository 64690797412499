import React, { useEffect, useState, useContext } from 'react';
import API from '../../api';
import { Form, Loading } from '../../components';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../../contexts';
import { ErrorUtil, AdminUtil } from '../../utils';

export const ServiceCreatePage = (props) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [data, setData] = useState({
    name: '',
    memberGroupId: '',
    svc_grp_id: '',
    accnt_id: '',
    description: '',
  });
  const [formProps, setFormProps] = useState(null);
  const { auth, authArray, serviceGroupList, serviceList, updateServiceList } = useContext(AuthContext);

  useEffect(() => {
    (async function () {
      try {
        const memberGroupList = await API.MemberGroup.Get().then(
          (res) => {
            console.log('res', res);
            const result = API.Utils.ResultCheck(res);
            return result;
          },
          (err) => {
            console.log('err', err);
          },
        );

        let serviceGroupIds = [];
        for (let item of serviceGroupList) {
          console.log('item', item);
          serviceGroupIds.push(item.value);
        }
        console.log('params', serviceGroupIds);
        let params = {
          serviceGroupIds: serviceGroupIds,
        };
        const serviceGroupListResult = await API.ServiceGroup.Get(params).then(
          (res) => {
            console.log('res', res);
            const result = API.Utils.ResultCheck(res);
            return result;
          },
          (err) => {
            console.log('err', err);
          },
        );
        let newFormProps = {
          memberGroupList: memberGroupList.list,
          serviceGroupList: serviceGroupListResult.list,
          accountIdList: [],
        };
        setFormProps(newFormProps);
      } catch (err) {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      }
    })();
  }, []);

  useEffect(() => {
    console.log('svc_grp_id change');
    if (data.svc_grp_id) {
      API.KAS.Get({
        svc_grp_id: data.svc_grp_id,
      }).then(
        (res) => {
          const result = API.Utils.ResultCheck(res);
          console.log(' KAS res', result);
          if (result) {
            let newFormProps = _.cloneDeep(formProps);
            newFormProps.accountIdList = result.list;
            setFormProps(newFormProps);
            let newData = _.cloneDeep(data);
            newData.accnt_id = '';
            setData(newData);
          }
        },
        (err) => {
          console.log(' KAS err', err);
        },
      );
    }
  }, [data.svc_grp_id]);

  const onChange = (name, value) => {
    let newData = _.cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const requestCreate = () => {
    console.log('data', data);
    let body = {
      description: data.description,
      memberGroupId: data.memberGroupId,
      name: data.name,
      serviceGroupId: data.svc_grp_id,
      accountId: data.accnt_id,
    };
    console.log('body', body);

    API.Service.Post(body).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result) {
          enqueueSnackbar('생성 성공', { variant: 'success' });
          // auth provider에 service를 추가해줘야함.
          let newServiceList = _.cloneDeep(serviceList);
          console.log('newServiceList', newServiceList);
          newServiceList.push({
            value: result.serviceNumber,
            text: data.name + '(' + result.serviceNumber + ')',
          });
          updateServiceList(newServiceList);

          //link router
          let targetItem = AdminUtil.searchByUrl(auth, '/service/detail');
          let linkAuthType = AdminUtil.checkAuthType(targetItem, authArray);

          console.log('targetItem', targetItem);
          console.log('linkAuthType', linkAuthType);
          if (linkAuthType) {
            history.push({
              pathname: '/service/detail/' + result.serviceNumber,
              state: { pageAuthType: linkAuthType },
            });
          }

          // history.push('/service/detail/' + result.serviceNumber);
        }
      },
      (err) => {
        console.log('err', err);
        enqueueSnackbar(ErrorUtil.SnackBar(err), { variant: 'error' });
      },
    );
  };

  if (!formProps) {
    return (
      <article className="content-page">
        <Loading></Loading>
      </article>
    );
  }

  return (
    <article className="content-page">
      <header>
        <section>
          <div className="title">
            <h1>서비스 생성</h1>
          </div>
        </section>
        <section>
          <ul className="option-list">
            <li>
              <Button
                onClick={requestCreate}
                disabled={location.state.pageAuthType === 'rw' ? false : true}
                variant="contained"
                color="primary"
              >
                생성
              </Button>
            </li>
          </ul>
        </section>
      </header>
      <Form
        title="기본 정보"
        data={data}
        type="create"
        contentType="service"
        onChange={onChange}
        formProps={formProps}
      ></Form>
    </article>
  );
};
