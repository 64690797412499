import React, { useContext, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CachedIcon from '@material-ui/icons/Cached';
import { AuthContext, MemberGroupContext } from '../contexts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment-timezone';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import _ from 'lodash';

export const Filter = (props) => {
  const { data, updateData, onSearch, loading } = props;
  console.log('data', data);
  const { serviceGroupList, serviceList } = useContext(AuthContext);
  const { memberGroup } = useContext(MemberGroupContext);
  console.log('serviceGroupList', serviceGroupList);
  console.log('serviceList', serviceList);
  const monthPicker = useRef(null);
  const [dateRangeOpen, setDateRangeOpen] = useState(false);

  if (data && data.length === 0) {
    return null;
  }

  return (
    <article className="filter">
      <header>
        <h1>검색 필터</h1>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={onSearch} disabled={loading}>
            {loading ? <CachedIcon></CachedIcon> : '검색'}
          </Button>
        </section>
      </header>
      <ul className="filter__list">
        {data &&
          data.map((item, index) => {
            const { required, component, disabled } = item;
            if (disabled) {
              return null;
            }
            if (component === 'TextField') {
              return (
                <li key={'filter-item-' + index}>
                  <TextField
                    type={item.type}
                    fullWidth
                    variant="outlined"
                    label={item.label}
                    value={item.value}
                    onChange={(e) => {
                      updateData(e, index);
                    }}
                    inputProps={item.inputProps}
                    InputLabelProps={item.InputLabelProps}
                    helperText={required ? 'Required' : ''}
                  />
                </li>
              );
            }

            if (component === 'Select') {
              let menus = item.menus || [];
              if (item.id === 'service-group-id' || item.id === 'option-service_group-value') {
                menus = serviceGroupList;
              } else if (item.id === 'option-service-value' || item.id === 'serviceNumber') {
                menus = serviceList;
              } else if (item.id === 'none-serviceNumber') {
                let allServiceList = _.cloneDeep(serviceList);

                allServiceList.unshift({
                  value: 'none',
                  text: '선택안함',
                });
                menus = allServiceList;
              }
              if (item.id === 'member-group-id') {
                menus = memberGroup.menus;
              }
              return (
                <li key={'filter-item-' + index}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id={'filter-select-label-' + index}>{item.label}</InputLabel>
                    <Select
                      labelId={'filter-select-label-' + index}
                      value={item.value}
                      onChange={(e) => {
                        updateData(e, index);
                      }}
                      label={item.label}
                    >
                      {menus.map((menu, menu_index) => {
                        // console.log('menu', menu);
                        return (
                          <MenuItem key={'filter-item-' + index + '-select-menu-' + menu_index} value={menu.value}>
                            {menu.text}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {required && <FormHelperText>Required</FormHelperText>}
                  </FormControl>
                </li>
              );
            }

            if (component === 'Date-Picker') {
              const { dateFormat, showMonthYearPicker, showYearPicker, label } = item;
              return (
                <li key={'filter-item-' + index}>
                  <div className="date-picker__container">
                    <div className="label-text">{label}</div>
                    <div className="date-picker__box">
                      <DatePicker
                        selected={item.value}
                        onChange={(date) => {
                          console.log('onChange date', date);
                          const event = {
                            target: {
                              value: date,
                            },
                          };
                          updateData(event, index);
                        }}
                        showMonthYearPicker={showMonthYearPicker}
                        showYearPicker={showYearPicker}
                        dateFormat={dateFormat}
                      />
                    </div>
                    <div className="helper-text">{required && <FormHelperText>Required</FormHelperText>}</div>
                  </div>
                </li>
              );
            }

            if (component === 'Date-Range-Picker') {
              const { value, label } = item;
              let start = moment(value.startDate).format('YYYY-MM-DD');
              let end = moment(value.endDate).format('YYYY-MM-DD');

              const handleSelect = (index, range) => {
                console.log('handleSelect index', index);
                console.log('handleSelect range', range);
                const event = {
                  target: {
                    value: range.selection,
                  },
                };
                updateData(event, index);
              };

              const handleClickAway = () => {
                console.log('handleClickAway');
                setDateRangeOpen(false);
              };

              return (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <li key={'filter-item-' + index}>
                    <div className="date-range__container">
                      <div className="label-text">{label}</div>
                      <div
                        className="date-range-value__box"
                        onClick={() => {
                          console.log('work');
                          if (dateRangeOpen) {
                            setDateRangeOpen(false);
                          } else {
                            setDateRangeOpen(true);
                          }
                        }}
                      >
                        <span>{start}</span>
                        <em>~</em>
                        <span>{end}</span>
                        <span></span>
                      </div>
                      {dateRangeOpen && (
                        <div className="date-range__box">
                          <DateRange
                            ranges={[value]}
                            onChange={(range) => handleSelect(index, range)}
                            showDateDisplay={false}
                            onRangeFocusChange={(focusArray) => {
                              console.log('focusArray', focusArray);
                              if (focusArray[0] === 0 && focusArray[1] === 0) {
                                setDateRangeOpen(false);
                              }
                            }}
                          />
                        </div>
                      )}

                      <div className="helper-text">{required && <FormHelperText>Required</FormHelperText>}</div>
                    </div>
                  </li>
                </ClickAwayListener>
              );
            }
          })}
      </ul>
    </article>
  );
};
